import styled from 'styled-components';
import {
  Grid,
} from '@mui/material';
const ViewGrid = styled(Grid)`
  padding-bottom: 10px;
  strong {
    min-width: 160px;
    display: inline-block;
    vertical-align: middle;
  }
`;
const ViewPreview = styled.div`
  position: relative;
  .view-icon {
    opacity: 0;
    transition: 0.5s;
    text-align: center;
    &::before {
      content: "";
      background: #000000;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      opacity: 0.4;
      position: absolute;
    }
  }
  .MuiSvgIcon-root {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    color: #ffffff;
  }
  &:hover {
    .view-icon {
      opacity: 1;
    }
  }
  
`;
const StyledLoader: any = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position:fixed;
  z-index:99999;
  top:0;
  left:0;
  bottom:0;
  right:0;
  background-color: rgba(16, 16, 16, 0.5);
  transition: 1s 0.4s;
`;
const StyledLoaderView: any = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position:fixed;
  top:0;
  left:0;
  bottom:0;
  right:0;
`;

export {
  ViewGrid, ViewPreview, StyledLoader, StyledLoaderView,
};
