import React from 'react';
import { Outlet } from 'react-router-dom';
import { Grid } from '@mui/material';
import Header from './header';
import SideBar from './sidebar';
// import { StyledHomePageWrapper } from '../modules/Dashboard/Dashboard.styled';

const PrivateAppLayout = () => (

  <Grid container>
    <Grid item lg={12} xs={12} md={12} sm={12}>
      <Header />
    </Grid>
    <Grid item lg={0.5} md={1} sm={1} xs={1}>
      <SideBar />
    </Grid>
    <Grid
      item
      lg={11.5}
      md={11}
      sm={11}
      xs={11}
      sx={{
        position: 'relative',
        top: '78px',
        padding: '24px 24px 24px 30px',
        background: '#f7f7f7',
        minHeight: 'calc(100vh - 80px)',
        height: 'auto',
      }}
      className="customOuterContainer"
    >
      <Outlet />
    </Grid>
  </Grid>
);

export default PrivateAppLayout;
