import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SelectedTab {
  selectedTab: any[];
  firstCategoryId: number | null;
}

const initialState: SelectedTab = {
  selectedTab: [],
  firstCategoryId: null,
};

const selectedTabSlice = createSlice({
  name: 'selectedTab',
  initialState,
  reducers: {
    setSelectedTab: (state, action: PayloadAction<any[]>) => {
      // eslint-disable-next-line no-param-reassign
      state.selectedTab = action.payload;
    },
    setFirstCategoryId: (state, action: PayloadAction<number>) => {
      // eslint-disable-next-line no-param-reassign
      state.firstCategoryId = action.payload;
    },
  },
});

export const { setSelectedTab, setFirstCategoryId } = selectedTabSlice.actions;
export default selectedTabSlice.reducer;
