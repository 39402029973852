import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getCurrentYear } from '../../../utils/utils';

export type Listkey = null | {
  list?: any;
  selectedYear?: number | string;
  joiningYear?: number | string;
};

const initialState: Listkey = {
  list: null,
  selectedYear: getCurrentYear(),
  joiningYear: null,
};

const appraisalCycleSlice = createSlice({
  name: 'appraisalData',
  initialState,
  reducers: {
    storeAppraisalCycleData: (state, action: PayloadAction<Listkey>) => {
      /* eslint-disable no-param-reassign */
      state.list = action.payload;
    },
    storeAppraisalCycleSelected: (
      state,
      action: PayloadAction<number | string>,
    ) => {
      /* eslint-disable no-param-reassign */
      state.selectedYear = action.payload || new Date().getFullYear();
    },
    storeEmployeeJoiningYear: (
      state,
      action: PayloadAction<number | string>,
    ) => {
      /* eslint-disable no-param-reassign */
      state.joiningYear = action.payload;
    },
  },
});

export const {
  storeAppraisalCycleData,
  storeAppraisalCycleSelected,
  storeEmployeeJoiningYear,
} = appraisalCycleSlice.actions;

export default appraisalCycleSlice.reducer;
