import { styled } from '@mui/system';
import Grid from '@mui/material/Grid';
import { AccordionSummary } from '@mui/material';

const CategoryHeader = styled(AccordionSummary)(() => ({
  marginTop: 40,
  paddingLeft: 25,
  fontWeight: 'bold',
  backgroundColor: 'rgba(0, 0, 0, 0.25)',
  paddingTop: 10,
  paddingBottom: 10,
}));

const SubCategoryGrid = styled(AccordionSummary)(() => ({
  marginTop: 7,
  padding: '5px 20px 5px 55px',
  fontWeight: 'extrabold',
  backgroundColor: 'rgba(0, 0, 0, 0.17)',
}));

const ArtifactNameGrid = styled(AccordionSummary)(() => ({
  fontSize: '14px',
  marginTop: 7,
  padding: '15px 20px 5px 70px',
  maxWidth: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.1)',
  outlineColor: 'red',
}));

const ArtifactDetailGrid = styled(Grid)(() => ({
  marginTop: 7,
  padding: '5px 5px 5px 0',
  maxWidth: '100%',
  fontSize: '14px',
  marginLeft: 85,
  display: 'flex',
}));

const ArtifactAttachmentGrid = styled(Grid)(() => ({
  marginTop: 7,
  display: 'flex',
  alignItems: 'center',
  maxWidth: '100%',
  fontSize: '14px',
  marginLeft: 85,
}));

export {
  CategoryHeader, SubCategoryGrid, ArtifactNameGrid, ArtifactDetailGrid, ArtifactAttachmentGrid,
};
