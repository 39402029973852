import * as React from 'react';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ISubProgressBarsProps } from './types';

const LinearProgressWithLabel = (props: LinearProgressProps | ISubProgressBarsProps) => {
  const {
    styleObject = {},
    value,
  } = props;
  const {
    superDisplay,
    superAlignItems,
    subMr,
    subWidth,
    subMinWidth,
    color,
  } = styleObject;

  return (
    <Box sx={{ display: superDisplay || 'flex', alignItems: superAlignItems || 'center' }}>
      <Box sx={{ mr: subMr || 1, width: subWidth || '100%' }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: subMinWidth || 33 }}>
        <Typography variant="body2" color={color || 'text.secondary'}>
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

export default LinearProgressWithLabel;
