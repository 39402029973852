import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { StyledRank, StyledRankContainer } from './RankingStyled.styled';
interface Rank {
  currentBURank: number;
  currentCompanyRank: number;
}

const Rankings: React.FC<Rank> = ({ currentBURank, currentCompanyRank }) => (
  <StyledRank>
    <StyledRankContainer className="rank-list">
      <List sx={{ width: '100%', maxWidth: 360 }}>
        <ListItem>
          <ListItemAvatar>
            <Avatar src="/rankIcon1.svg" />
          </ListItemAvatar>
          <ListItemText
            sx={{ width: 65, '& .MuiListItemText-secondary': { textAlign: 'center' }, '& .MuiListItemText-primary': { marginLeft: 0.5 } }}
            primary="BU Rank"
            secondary={currentBURank}
          />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar src="/rankIcon2.svg" />
          </ListItemAvatar>
          <ListItemText
            sx={{ '& .MuiListItemText-secondary': { textAlign: 'center' }, '& .MuiListItemText-primary': { textAlign: 'center' } }}
            primary="Overall Rank"
            secondary={currentCompanyRank}
          />
        </ListItem>
      </List>
    </StyledRankContainer>
  </StyledRank>
);

export default Rankings;
