import React from 'react';
import MUITooltip from '@mui/material/Tooltip';
import { ITooltipProps } from './types';

const Tooltip = (props:ITooltipProps) => {
  const {
    title = '',
    children,
    customClasses = {},
  } = props;
  return (
    <MUITooltip
      title={title}
      classes={{ ...customClasses }}
      {...props}
    >
      {children}
    </MUITooltip>
  );
};

Tooltip.defaultProps = {
  children: 'New tooltip',
  title: 'ToolTip',
  arrow: true,
  placement: 'left',
  open: true,
};

export default Tooltip;
