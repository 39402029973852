import React, { HTMLProps, ReactNode } from 'react';

interface TypographyProps extends HTMLProps<HTMLDivElement> {
  variant: 'h1' | 'h2' | 'h3' | 'h4' | 'p1' | 'p2' | 'body1' | 'body2';
  children: ReactNode;
  gutterBottom?: any;
  sx?:any;
}

const Typography: React.FC<TypographyProps> = (
  {
    variant, children, gutterBottom, ...rest
  },
) => {
  const variantStyles = {
    h1: {
      fontStyle: 'Lato',
      fontWeight: 700,
      fontSize: '32px',
      lineHeight: '27px',
      color: '#06152B',
    },
    h2: {
      fontStyle: 'Lato',
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '27px',
      color: '#000000',
    },
    h3: {
      fontStyle: 'Lato',
      fontWeight: 700,
      fontSize: '22px',
      lineHeight: '27px',
      color: '#06152B',
    },
    h4: {
      fontFamily: 'Lato',
      fontWeight: 700,
      fontSize: '22px',
      color: '#06152B',

    },
    p1: {
      fontStyle: 'Inter',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '19.36px',
      color: '#000000',
    },
    p2: {
      fontStyle: 'Inter',
      fontWeight: 500,
      fontSize: '10px',
      lineHeight: '12.1px',
      color: '#7B7B7B',
    },
    body1: {
      fontWeight: 500,
      fontFamily: 'Inter',
      fontSize: '16px',
      color: '#000000',
    },
    body2: {
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: '10px',
      color: '#7B7B7B',
    },
  };

  const style = {
    ...variantStyles[variant],
    ...rest.style,
  };
  return (
    <div style={style} {...rest}>
      {children}
    </div>
  );
};

export default Typography;
