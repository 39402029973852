/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
/* eslint-disable no-plusplus */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Tab,
  Grid,
  Typography,
  Alert,
  Tabs,
  Tooltip,
  // TabScrollButton,
} from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import CircularProgress from '@mui/material/CircularProgress';
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined';
import { useNavigate, useParams } from 'react-router-dom';
import { CommHandler } from '../../../utils';
import { config } from '../../../config';
// import { CategoryDetails } from '../type';
import { formatArtifact, filterResList } from './helper';
import ViewArtifactList from './ViewArtifactList';
import { ArtifactWrapper, StyledArtifactIcon } from '../Artifact.styled';
import isEmpty, {
 getAuthHeader, isNil, mapSeverityToAlertColor, getCurrentYear,
} from '../../../utils/utils';
import ProfileHeader from '../../BUArtifact/components/ProfileHeader';
import { status } from '../../../libs/Constant';
import { ToastProps } from '../../../components/Toast/types';
import Toast from '../../../components/Toast/Toast';
import { fetchCategoryData } from '../../Profile/categoryWithLength';
import { setSelectedStatus } from '../../Profile/selectedStatusSlice';
import { setSelectedSubcategory } from '../../Profile/selectedSubcategorySlice';
import { setSelectedTab, setFirstCategoryId } from '../../Profile/selectedTabSlice';

const ArtifactList = () => {
  const { userId } = useParams();
  const tab = useSelector((state: any) => state?.selectedTab?.selectedTab);
  const statusRoute = useSelector((state: any) => state?.selectedStatus?.selectedStatus);
  const loggedInUserId = localStorage.getItem('user_id');
  const loggedUser = useSelector((state: any) => state.user?.employee);
  const hasEditAccess = isNil(userId) ? true : (userId === loggedInUserId);
  const [tabValue, setTabValue] = useState<string>(tab || '');
  const [categoryDetails, setCategoryDetails] = useState<any>([]);
  const [categoryDetailsWithLength, setCategoryDetailsWithLength] = useState<any>([]);
  const [updateArtifactList, setUpdateArtifactList] = useState<any>({});
  const [selectStatus, setSelectStatus] = React.useState<string[]>([]);
  const [listData, setlistData] = React.useState<any>([]);
  const [getStatus, setGetStatus] = useState('');
  const [reviewers, SetReviewers] = React.useState<any>([]);
  const [selectSubCategory, setSelectSubCategory] = React.useState<string[]>([]);
  const selectedSubCategory = useSelector((state: any) => state?.selectedSubcategory?.selectedSubcategory);
  const currentYear = (
    useSelector((state: any) => state.appraisalCycle.selectedYear) || getCurrentYear()
  )?.toString();
  const [subCategoryDetails, setSubCategoryDetails] = React.useState<any>({});
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const categoryDatass = useSelector((state: any) => state.categoryData);

  const [toastmsg, setToastMsg] = useState<ToastProps>({
    open: false,
    autoHideDuration: 9000,
    message: '',
    vertical: 'top',
    horizontal: 'center',
    severity: '',
  });
  const navigate = useNavigate();
  const categoryDetailsWithCount = useSelector((state) => state);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    if (selectedSubCategory) {
      setSelectSubCategory(selectedSubCategory);
    }
  }, [selectedSubCategory]);

  const handleGetStatus = (checkStatus) => {
    setGetStatus(checkStatus);
  };

  const handleReviewersUpdate = (reviewer) => {
    SetReviewers(reviewer);
  };

  useEffect(() => {
    setSelectStatus([]);
    setGetStatus('');
    SetReviewers([]);
    setLoading(true);
    async function fetchArtifactData() {
      try {
        const groupingSuperUser = loggedUser?.isSuperAdmin ? 'grouping/superadmin' : 'grouping';
        const response: any = await Promise.all([
          CommHandler.request({
            url: `${config.adminApiURL}/artifact/category`,
            method: 'GET',
            params: { status: 1 },
            headers: getAuthHeader,
          }),
          CommHandler.request({
            url: `${config.apiBaseUrl}/artifact/${groupingSuperUser}?loggedInUserId=${loggedInUserId}&userId=${hasEditAccess ? loggedInUserId : userId}`,
            method: 'GET',
            params: {
              year: currentYear,
            },
            headers: getAuthHeader,
          }),
        ]);
        const data = await response;
        setLoading(false);
        return data;
      } catch (err) {
        setLoading(false);
        setToastMsg({
          open: true, message: 'Something went wroing', vertical: 'top', horizontal: 'center', severity: 'error',
        });
        return false;
      }
    }
    fetchArtifactData().then(async (data) => {
      if (!isEmpty(data)) {
        dispatch(fetchCategoryData([]));
        setCategoryDetailsWithLength([]);
        setCategoryDetails(data?.[0]?.data);
        const firstCategoryId = data?.[0]?.data?.[0]?.id;
        dispatch(setFirstCategoryId(firstCategoryId));
        setTabValue(tab || data?.[0]?.data?.[0]?.id);
        if (data?.[1]?.data?.length) {
          const { resultObj: artifact, subCategoryFilter }: any = await formatArtifact(data[1]?.data, data[0]?.data);
          setSubCategoryDetails(subCategoryFilter);

          const newList = data?.[0]?.data?.reduce((accu:any, item:any) => {
                        const filterData = artifact[item.id];
                         let totalSubcategoryLength = 0;
                 const categoryData = filterData.map((items: any) => {
              const filterCategory = data[1]?.data.filter(
              (key: any) => key.name === items.name && key.artifact_category_id === items.artifact_category_id,
              );
              if (filterCategory.length) {
              return {
                subCategoryName: items.name,
                subCategoryData:
                            filterCategory.reduce(
                              (
                              pre: any,
                              newData: any,
                              ) => {
                                 if (newData && newData?.data) {
                                      totalSubcategoryLength += newData.data.length;
                                      }
                                return [
                              ...pre,
                              ...newData.data,
                                ];
                              },
                              [],
                            ),
              };
              }
              return { subCategoryName: items.name };
            });
  // eslint-disable-next-line no-param-reassign
  accu[item?.id] = {
    artifactCatId: item.id,
    categoryName: item.name,
    categoryData,
    totalSubcategoryLength,
  };
  return accu;
}, {});
  const tabsDataID = Object.keys(newList);

  const tablen = tabsDataID.map((id) => {
        const { categoryData, artifactCatId, categoryName } = newList[id];
        let calculateTotalLength = 0;
        const allCategoryData = filterResList(statusRoute?.length ? statusRoute : [], [], categoryData);
 const initialSubcategoryLength = allCategoryData?.reduce(
  (accumulator, currentValue) => {
    const len = currentValue?.subCategoryData?.length || 0;
    return accumulator + len;
  },
  0,
);

if (tab && tab === id && selectedSubCategory?.length) {
        const tabCatData = filterResList(statusRoute?.length ? statusRoute : [], selectedSubCategory, categoryData);

  calculateTotalLength = tabCatData?.reduce(
  (accumulator, currentValue) => {
    const len = currentValue?.subCategoryData?.length || 0;
    return accumulator + len;
  },
  0,
);
}

        return {
          id: artifactCatId,
            name: categoryName,
            totalSubcategoryLength: tab && tab === id && selectedSubCategory?.length ? calculateTotalLength : initialSubcategoryLength,
            initialSubcategoryLength,
        };
  });

  setCategoryDetailsWithLength(tablen);
  const dataTofilters = JSON.parse(JSON.stringify(Object.values(newList)));
      dispatch(fetchCategoryData(dataTofilters));
  if (statusRoute?.length > 0 || selectedSubCategory?.length > 0) {
    setSelectStatus(statusRoute);
    const dataToFilter = tab ? newList[tab] : newList[data?.[0]?.data?.[0]?.id];
    const filteredCategoryData = filterResList(statusRoute, selectedSubCategory, dataToFilter?.categoryData);
    setUpdateArtifactList(newList);
    setlistData({ categoryName: dataToFilter?.categoryName, categoryData: filteredCategoryData, artifactCatId: dataToFilter?.artifactCatId });
  } else {
    setUpdateArtifactList(newList);
    setlistData(tab ? newList[tab] : newList[data?.[0]?.data?.[0]?.id]);
  }
        }
      }
    });
  }, [loggedInUserId, userId, hasEditAccess, loggedUser?.isSuperAdmin, currentYear, getStatus, reviewers?.length]);
  useEffect(() => {
    if (categoryDetails && categoryDetails?.length) {
      setLoading(false);
    }
  }, [categoryDetails]);

  if (loading) {
    return (
      <div style={{
        position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
      }}
      >
        <CircularProgress disableShrink />
      </div>
    );
  }

  // handle tab change
  const handleTabChange = async (
    event: React.SyntheticEvent,
    newValue: any,
  ) => {
  dispatch(setSelectedSubcategory([]));
  setlistData(updateArtifactList[newValue]);
  setSelectSubCategory([]);
  setTabValue(newValue);
  dispatch(setSelectedTab(newValue));
  const newCategoryData = updateArtifactList[newValue];
  let totalSubcategoryLength = 0;
  newCategoryData?.categoryData?.forEach((item: any) => {
    totalSubcategoryLength += item.subCategoryData.length;
  });

    const filteredData = newCategoryData?.categoryData?.map((category: any) => {
      const filteredSubCategoryData = category?.subCategoryData?.filter((subCategory: any) => {
        const approvedLower = subCategory?.approved?.toLowerCase();
        const statusRouteLower = selectStatus?.map((item) => item?.toLowerCase());
        return statusRouteLower?.includes(approvedLower);
      });
      return { ...category, subCategoryData: selectStatus.length > 0 ? filteredSubCategoryData : category?.subCategoryData };
    }).filter((category) => category.subCategoryData.length > 0);
    setlistData({
      categoryName: newCategoryData?.categoryName,
      categoryData: filteredData,
      artifactCatId: newCategoryData?.artifactCatId,
    });
    let calculateTotalLength = 0;
     newCategoryData?.categoryData?.forEach((category: any) => {
    category?.subCategoryData?.forEach((subCategory: any) => {
      const statusRouteLower = selectStatus?.map((item) => item?.toLowerCase());
        if (statusRouteLower?.includes(subCategory?.approved?.toLowerCase())) {
          calculateTotalLength++;
        }
    });
});
  setCategoryDetailsWithLength((prev: any) => prev.map((item: any) => {
    if (item.id === newValue) {
      return { ...item, totalSubcategoryLength: selectStatus.length > 0 ? calculateTotalLength : totalSubcategoryLength };
    }
    return item;
  }));
};

  const handleDepartment = (checked: any, clickedDepartment: string) => {
    const ifSelectStatus = status.includes(clickedDepartment);
    const { categoryName, categoryData } = updateArtifactList[tabValue!];
    let updatedSelectStatus = [...selectStatus];
    let updatedSelectSubCategory = [...selectSubCategory];
    if (checked) {
        if (ifSelectStatus) {
            setSelectStatus([...selectStatus, clickedDepartment]);
            updatedSelectStatus = [...selectStatus, clickedDepartment];
            const filterArtifact: any[] = filterResList(
                [...selectStatus, clickedDepartment],
                selectSubCategory,
                categoryData,
            );
            setlistData({ categoryName, categoryData: filterArtifact });
        } else {
          updatedSelectSubCategory = [...selectSubCategory, clickedDepartment];
            setSelectSubCategory([...selectSubCategory, clickedDepartment]);
            const filterArtifact: any[] = filterResList(
                selectStatus,
                [...selectSubCategory, clickedDepartment],
                categoryData,
            );
            setlistData({ categoryName, categoryData: filterArtifact });
        }
    } else if (ifSelectStatus) {
        const filterStatus = selectStatus.filter(
            (element) => element !== clickedDepartment,
        );
        setSelectStatus(filterStatus);
        updatedSelectStatus = selectStatus.filter((element) => element !== clickedDepartment);
        const filterArtifact: any[] = filterResList(
            filterStatus,
            selectSubCategory,
            categoryData,
        );
        setlistData({ categoryName, categoryData: filterArtifact });
    } else {
        const filterSubCat = selectSubCategory.filter(
            (element) => element !== clickedDepartment,
        );
        setSelectSubCategory(filterSubCat);
        updatedSelectSubCategory = selectSubCategory.filter((element) => element !== clickedDepartment);
        const filterArtifact: any[] = filterResList(
            selectStatus,
            filterSubCat,
            categoryData,
        );
        setlistData({ categoryName, categoryData: filterArtifact });
    }
    const filteredData = filterResList(updatedSelectStatus, updatedSelectSubCategory, categoryData);
    let totalSubcategoryLength = 0;
    filteredData?.forEach((item: any) => {
    totalSubcategoryLength += item?.subCategoryData?.length ?? 0;
  });
setSelectStatus(updatedSelectStatus);
const result = (categoryDetailsWithCount as { categoryData: any })?.categoryData?.categoryDataWithCount?.map((itm: any) => {
  let calculateTotalLength = 0;
  if (updatedSelectStatus.length === 0) {
    if (itm?.categoryData) {
      itm?.categoryData?.forEach((category: any) => {
        if (category?.subCategoryData) {
          calculateTotalLength += category?.subCategoryData?.length ?? 0;
        }
      });
    }
  } else if (updatedSelectStatus.length > 0) {
    if (itm?.categoryData) {
      itm?.categoryData?.forEach((category: any) => {
        if (category?.subCategoryData) {
          category?.subCategoryData?.forEach((subcategory: any) => {
            const statusRouteLower = updatedSelectStatus?.map((item) => item?.toLowerCase());
            if (statusRouteLower.includes(subcategory?.approved?.toLowerCase())) {
              calculateTotalLength++;
            }
          });
        }
      });
    }
  }
  return {
    id: itm?.artifactCatId ?? '',
    name: itm?.categoryName ?? '',
    totalSubcategoryLength: calculateTotalLength ?? 0,
    initialSubcategoryLength: calculateTotalLength ?? 0,
  };
});

setCategoryDetailsWithLength(result);
setSelectSubCategory(updatedSelectSubCategory);
setlistData({
  categoryName,
  categoryData: filteredData,
});
setCategoryDetailsWithLength((prev: any) => prev.map((item: any) => {
if (item.id === tabValue) {
  return { ...item, totalSubcategoryLength };
}
return item;
}));
};

  const onDismiss = () => {
    setToastMsg({
      open: false, message: '', horizontal: 'center', vertical: 'top', severity: '',
    });
  };

  const handleStatusChange = (event) => {
    const {
        target: { value },
    } = event;
    const selectedValues = typeof value === 'string' ? value?.split(',') : value;
    setSelectStatus(selectedValues);
    dispatch(setSelectedStatus(selectedValues));
    selectedValues?.forEach((selectedValue) => {
        handleDepartment(true, selectedValue);
    });
    selectStatus?.forEach((prevSelectedValue) => {
        if (!selectedValues?.includes(prevSelectedValue)) {
            handleDepartment(false, prevSelectedValue);
        }
    });
};

const handleSubcategoryChange = (event) => {
  const {
      target: { value },
  } = event;
  const selectedValues = typeof value === 'string' ? value?.split(',') : value;
  setSelectSubCategory(selectedValues);
  dispatch(setSelectedSubcategory(selectedValues));

  selectedValues?.forEach((selectedValue) => {
      handleDepartment(true, selectedValue);
  });
  selectSubCategory?.forEach((prevSelectedValue) => {
      if (!selectedValues?.includes(prevSelectedValue)) {
          handleDepartment(false, prevSelectedValue);
      }
  });
};

  const handleOpenNewTab = () => {
    window.open('/artifacts/viewAll-artifact', '_blank');
  };

  return (
    <>
      {toastmsg && (
      <Toast
        open={toastmsg?.open}
        autoHideDuration={toastmsg?.autoHideDuration}
        message={toastmsg?.message}
        vertical={toastmsg?.vertical}
        horizontal={toastmsg?.horizontal}
        onClose={onDismiss}
      >
        <Alert onClose={onDismiss} severity={mapSeverityToAlertColor(toastmsg?.severity)} sx={{ width: '100%' }}>
          {toastmsg?.message}
          !
        </Alert>
      </Toast>
      )}
      <ProfileHeader categoryDetails={categoryDetails} />
      <ArtifactWrapper>
        <div className="artifact-btn-space">
          <div>
            <Typography variant="h4" gutterBottom>
              Artifacts
            </Typography>
          </div>
          <div>
            {hasEditAccess && (
            <Button
              style={{ marginTop: "9px" }}
              className="addArtifact-btn"
              variant="contained"
              onClick={() => navigate('/artifacts/add-artifacts', {
            state: {
              categoryDetails,
            },
          })}

            >

              Add Artifacts
              <div>
                <StyledArtifactIcon
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="plus-icon"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                </StyledArtifactIcon>
              </div>
            </Button>
              )}
            {' '}
            {categoryDatass.categoryDataWithCount.length > 0 ? (
              <Button
                style={{ marginTop: 9, marginLeft: 10, marginRight: 5 }}
                variant="contained"
                className="addArtifact-btn"
                onClick={handleOpenNewTab}
              >
                View Report
                <div>
                  <VisibilityOutlined style={{ marginLeft: '10px' }} />
                </div>
              </Button>
              ) : null}
            <FormControl sx={{ m: 1, width: 250 }}>
              <InputLabel id="demo-multiple-checkbox-label">SELECT SUBCATEGORY</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selectSubCategory}
                onChange={(e) => handleSubcategoryChange(e)}
                input={<OutlinedInput label="SELECT SUBCATEGORY" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.3 }}>
                    {selected.length > 0 && (
                    <Tooltip title={selected[0]} placement="top">
                      <Chip label={selected[0]?.length > 15 ? `${selected[0].slice(0, 15)}...` : selected[0]} />
                    </Tooltip>
                  )}
                    {selected.length > 1 && <Chip label={`+${selected.length - 1}`} />}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {subCategoryDetails[tabValue!]?.map((extractName:any) => (
                     extractName?.item && extractName?.item.length > 0 ? (
                      extractName?.item
                      ?.sort((a, b) => a?.name.localeCompare(b?.name))
                      ?.map((element: any) => (
                        <MenuItem key={element?.name} value={element?.name}>
                          <Checkbox checked={selectSubCategory?.indexOf(element?.name) > -1} />
                          <Tooltip title={element?.name} placement="top">
                            <ListItemText
                              primary={element?.name?.length > 15 ? `${element?.name?.slice(0, 15)}...` : element?.name}
                            />
                          </Tooltip>
                        </MenuItem>
                    ))
                  ) : (
                    <Box textAlign="center" p={2}>
                      No Subcategory Available
                    </Box>
                  )
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, width: 250 }}>
              <InputLabel id="demo-multiple-checkbox-label">SELECT STATUS</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selectStatus}
                onChange={(e) => handleStatusChange(e)}
                input={<OutlinedInput label="SELECT STATUS" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.3 }}>
                    {selected.slice(0, 2).map((value) => (
                      <Chip label={value} />
                    ))}
                    {selected.length > 2 && <Chip label={`+${selected.length - 2}`} />}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {status?.map((name:any) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={selectStatus?.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="employee-nav">
          <TabContext value={tabValue as string}>
            <Box
              style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                      }}
            >
              <Tabs
                value={tabValue as string}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
              >
                {categoryDetailsWithLength?.map((val: any) => (
                  <Tab
                    key={val.id}
                    className="live-rank-tab-container"
                    label={val.name.length > 15 ? `${val.name.substring(0, 15)}... (${val.id === tabValue
                      ? val.totalSubcategoryLength : val.initialSubcategoryLength})` : `${val.name} (${val.id === tabValue ? val.totalSubcategoryLength : val.initialSubcategoryLength})`}
                    title={val.name.length > 15 ? val.name : undefined}
                    value={val.id}
                  />
                ))}
              </Tabs>
            </Box>
            <TabPanel
              value={tabValue}
              className="override-tabpanel"
            >
              {listData?.["categoryData"]?.length > 0
               ? listData?.["categoryData"]?.sort((a: any, b: any) => a.subCategoryName.localeCompare(b.subCategoryName)).map((element: any) => (
                 <ViewArtifactList
                   subCategoryName={element.subCategoryName}
                   subCategoryData={element.subCategoryData}
                   hasEditAccess={hasEditAccess}
                   loggedInUserId={loggedInUserId}
                   categoryDetails={categoryDetails}
                   tabValue={tabValue}
                   userId={userId}
                   loggedUser={loggedUser}
                   onStatusChange={handleGetStatus}
                   onReviewerUpdate={handleReviewersUpdate}
                 />
                )) : (
                  <Grid>
                    <div className="artifact-main-table">
                      <div className="table-row-item-cell" style={{ margin: '0px' }}>
                        {['Status', 'Artifact Name', 'Approver', 'Approved/Rejected By', 'Points', 'Action'].map((heading: string) => (
                          <Box key={heading} className="artifact-table-head">
                            {heading}
                          </Box>
                         ))}
                      </div>
                      <div style={{
                        textAlign: 'center',
                        fontWeight: 400,
                        marginTop: '10px',
                        marginBottom: '10px',
                        }}
                      >
                        No data to display
                      </div>
                    </div>
                  </Grid>
              )}
            </TabPanel>
          </TabContext>
        </div>

      </ArtifactWrapper>

    </>
  );
};

export default ArtifactList;
