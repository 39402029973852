import React from 'react';
import { IImageViewerProps } from './type';

const ImageViewer = (props: IImageViewerProps) => {
  const {
    src,
    alt,
    width,
    height,
  } = props;
  return (
    <img src={src} alt={alt} width={width} height={height} />
  );
};

ImageViewer.defaultProps = {
  alt: 'Image',
  width: 500,
  height: 300,
};
export default ImageViewer;
