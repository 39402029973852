import React from 'react';

import {
  Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
} from '@mui/material';

const DialogBoxforArtifactApproval = ({
  showRejectMultipleDialog, setShowRejectMultipleDialog, selectedAction, handleMultipleArtifacts,
}) => (
  <Dialog open={showRejectMultipleDialog} onClose={() => setShowRejectMultipleDialog(false)}>
    <DialogTitle>Are you sure?</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Are you sure you want to
        {' '}
        {selectedAction === 'Reject Selected' ? 'reject' : 'approve'}
        {' '}
        all artifacts?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => setShowRejectMultipleDialog(false)}>No</Button>
      <Button onClick={() => handleMultipleArtifacts(selectedAction)} color="primary">
        Yes
      </Button>
    </DialogActions>
  </Dialog>
);

export default DialogBoxforArtifactApproval;
