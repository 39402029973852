import React from 'react';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';

import { IRadioButtonsProps } from './types';

const RadioButtons = (props: IRadioButtonsProps) => {
  const {
    status,
    onValueChange,
    radioButtonLabels,
    isRow,
  } = props;
  return (
    <RadioGroup
      row={isRow}
      aria-labelledby="demo-controlled-radio-buttons-group"
      name="controlled-radio-buttons-group"
      value={status}
      onChange={onValueChange}
    >
      {radioButtonLabels.map((radioLabel) => (
        <FormControlLabel
          value={radioLabel.value}
          control={<Radio />}
          label={radioLabel.label}
        />
      ))}
    </RadioGroup>
  );
};

export default RadioButtons;
