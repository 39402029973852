import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams } from 'react-router-dom';
import { Rankings } from '../../Dashboard/components/Ranking';
import { UserProfiles } from '../../Dashboard/components/UserProfile';
import {
  StyledCustomizedDivider,
  StyledCustomizedWrapper,
} from '../../Dashboard/Dashboard.styled';
import { StyledTotalContainer } from '../BUArtifact.styled';
import ProgressReport from './ProgressReport';
import { getAuthHeader, getCurrentYear } from '../../../utils/utils';
import { CommHandler } from '../../../utils';
import { config } from '../../../config';

interface ReactComponentProps {
  categoryDetails: any;
}

const ProfileHeader: React.FC<ReactComponentProps> = ({ categoryDetails }) => {
  const [rankData, setRankData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);
  const { userId: userProfileId } = useParams();
  const userId = getAuthHeader['x-user_id'];
  const currentYear = (
    useSelector((state: any) => state.appraisalCycle.selectedYear) || getCurrentYear()
  )?.toString();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await CommHandler.request({
          url: `${config.apiBaseUrl}/artifactPoints/user-rank`,
          method: 'GET',
          params: userProfileId
            ? { userId: userProfileId, year: currentYear }
            : { userId, year: currentYear },
        });
        if (response.data) {
          setRankData(response.data[0]);
        }
      } catch (error) {
        console.error('Error fetching rank:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [userId, userProfileId, currentYear]);

  return (
    <StyledCustomizedWrapper
      className="profileHeader"
      style={{
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // height: loading ? '100vh' : 'auto'
      }}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <UserProfiles />
          <StyledCustomizedDivider orientation="vertical" />
          <div className="progress-profile-bar-report">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                marginRight: '24px',
              }}
            >
              <div
                style={{
                  maxHeight: categoryDetails?.length > 6 ? '200px' : 'none',
                  overflowY: categoryDetails?.length > 6 ? 'auto' : 'hidden',
                }}
              >
                <ProgressReport categoryDetails={categoryDetails} />
              </div>
            </div>
            <div style={{ display: 'flex' }} className="totalPointsWrapper">
              <Rankings
                currentBURank={rankData?.currentBURank || 0}
                currentCompanyRank={rankData?.currentCompanyRank || 0}
              />
              <StyledTotalContainer className="total-ponits">
                <List
                  sx={{
                    background: '#FDF5EB',
                    textAlign: 'center',
                    padding: '12px',
                    marginRight: '10px',
                  }}
                >
                  <ListItemText>Total Points</ListItemText>
                  <p
                    style={{
                      color: '#EDA13B',
                      fontSize: '24px',
                      fontWeight: 600,
                      margin: 0,
                    }}
                  >
                    {rankData?.totalPoints || 0}
                  </p>
                </List>
              </StyledTotalContainer>
            </div>
          </div>
        </>
      )}
    </StyledCustomizedWrapper>
  );
};

export default ProfileHeader;
