// MessageAlert.styled.ts
import styled from 'styled-components';
import {
  DialogContent, DialogTitle, DialogActions, Button,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export const StyledDialogContent = styled(DialogContent)`
  display: flex;
  align-items: center;
  text-align: center;
`;

export const IconWrapper = styled.div`
  font-size: 48px;
  margin-right: 1rem;

//   &.successIcon {
//     color: green;
//   }

//   &.infoIcon {
//     color: blue;
//   }

//   &.warningIcon {
//     color: orange;
//   }
`;

export const SuccessIcon = styled(CheckCircleIcon)`
  // You can add additional styles here if needed
`;

export const StyledDialogTitle = styled(DialogTitle)`
  // Add any styles for the dialog title here
`;

export const StyledDialogActions = styled(DialogActions)`
  // Add any styles for the dialog actions here
`;

export const StyledButton = styled(Button)`
  // Add any styles for the button here
`;
