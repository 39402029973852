import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { IBreadcrumbProps } from './types';

const GenericBreadcrumb = (props: IBreadcrumbProps) => {
  const {
    customClasses = {},
    items = [],
  } = props;
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      classes={{ ...customClasses }}
      {...props}
    >
      {items.map(({ label, href }) => (
        href
          ? (
            <Link color="inherit" href={href}>
              {label}
            </Link>
          )
          : (
            <Typography color="textPrimary">
              {label}
            </Typography>
          )
      ))}
    </Breadcrumbs>
  );
};

GenericBreadcrumb.defaultProps = {
  items: [
    { label: 'Home', href: '/' },
    { label: 'Page 1', href: '/page1' },
    { label: 'Page 2', href: '/page2' },
    { label: 'Page 3', href: '/page3' },
  ],
  maxItem: 3,
  separator: '/',
  itemsAfterCollapse: 1,
  itemsBeforeCollapse: 1,
};

export default GenericBreadcrumb;
