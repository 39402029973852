import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material';
import { PersistGate } from 'redux-persist/integration/react';
import GlobalStyles from './GlobalStyles';
import App from './App';
import '@fontsource/roboto';
import { globalTheme as projectTheme } from './config';
import { store, persistedStore } from './store/store';
import { ArtifactsContextProvider } from './modules/Artifact/components/ArtifactContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <ThemeProvider theme={projectTheme}>
    <GlobalStyles />
    <Provider store={store}>
      <PersistGate persistor={persistedStore}>
        <ArtifactsContextProvider>
          <App />
        </ArtifactsContextProvider>
      </PersistGate>
    </Provider>
  </ThemeProvider>,
);
