import React from 'react';
import { Checkbox } from '@mui/material';
import { ICheckBoxProps } from './types';

const CheckBox = (props: ICheckBoxProps) => {
  const {
    defaultChecked,
    disabled,
    onValueChange,
    customClasses = {},
  } = props;

  return (
    <Checkbox
      disabled={disabled}
      checked={defaultChecked}
      onChange={onValueChange}
      classes={{ ...customClasses }}
      {...props}
    />
  );
};

CheckBox.defaultProps = {
  color: 'success',
  size: 'small',
  customClasses: {},
  disabled: false,
  defaultChecked: false,
};

export default CheckBox;
