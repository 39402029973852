import { SvgIcon } from '@mui/material';
import styled from 'styled-components';
import Dialog from '@mui/material/Dialog';
import FormControlLabel from '@mui/material/FormControlLabel';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

const ArtifactWrapper = styled.div`
    padding: 0px 29px;
    
    .artifact-btn-space {
        display: flex;
        justify-content: space-between;
        align-items: end;
        .filter-btn {
            margin-right: 20px;
            font-size: 16px;
            font-weight: 500;
            font-style: Inter;
            line-height: 19.36px;
            color: #2559C3;
            border: 1px solid #2559C3;
            padding: 16px 24px;
            .filter-down-icon{
                margin-bottom:-15px;
            }
        }
        .addArtifact-btn {
            font-size: 16px;
            font-weight: 500;
            font-style: Inter;
            line-height: 19.36px;
            color: #ffffff;
            padding: 16px 24px;
        }
    }
    .employee-nav {
        margin-top: 40px;
        .live-rank-tab-container {
            text-transform: capitalize;
            font-style: Inter;
            font-weight: 400;
            font-size: 14px;
            line-height: 16.94px;
            color: #848282;
        }
    }
    .artifact-main-table {
        margin-top: 20px;
        border: 1px solid #D3D3D3;
        background-color: #ffffff;
        border-radius: 12px;
        padding: 0px 0px 13px 0px;
        .certificate-heading {
            padding: 10px;
            font-family: Lato;
        }
        .override-tabpanel{
            padding: unset;
        }
        .table-row-item-cell {
            width: 100%;
            display: flex;
            align-items: center;
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 14px;
            background: rgba(37, 89, 195, 10%);
            padding: 7px 32px;
            justify-content: space-between;
            height: 48px;
            margin: 16px 0;
        }
        .table-row-item {
            display: flex;
            align-items: center;
            border-radius: 10px;
            border: 1px solid #D3D3D3;
            background: #FFF;
            margin:8px 8px;
            height: 63px;
            padding: 24px 19px;
            justify-content: space-between;
            gap: 25px;
            .list-artifact {
                padding-left: 0px;
                .status-pending {
                    border-radius: 5px;
                    padding: 0px 10px;
                    gap: 10px;
                    background-color: #FFEED8;
                    font-style: Lato;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 27px;
                    color: #EDA13B;
                }
                .status-approved {
                    border-radius: 5px;
                    padding: 0px 10px;
                    gap: 10px;
                    background-color: #DBFFD8;
                    font-style: Lato;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 27px;
                    color: #0EAA00;
                }
                .status-rejected {
                    border-radius: 5px;
                    padding: 0px 10px;
                    gap: 10px;
                    background-color: #FFCCCC;
                    font-style: Lato;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 27px;
                    color: #FF0000;
                }
                .listIem-text {
                    font-weight: 900;
                    font-size: 12px;
                    font-style: Inter;
                    padding:0px;
                    word-break: break-all;
                    span{
                        font-family: Inter;
                        font-size: 12px;
                        font-weight: 500;
                        color:#000000;
                    }
                }
            }
        }
    }
    .artifact-table-head{
        font-family: Lato;
        font-size: 12px;
        font-weight: 500;
        color: #06152B;
        width: 16.66%;
    }
    .list-artifact-box-wrap{
        width: 9%;
        margin-left:20px;
    }
    .table-row-profile-container{
        width: 8%;
    }

`;
const StyledTotalContainer = styled.div`
    &.total-ponits {
        display: flex;
        align-items: center;
        flex-grow: 1;
        backgroundColor:'#FDF5EB'
    }
    &.total-ponits li p {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-colour:black
        font-size: 18px;
        line-height: 22px;
        /* identical to box height */
        color: #1D1D1D;
    }
    &.total-ponits li span {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #7B7B7B;
    }
`;
const StyledArtifactIcon = styled(SvgIcon)`
    &.artifact-icon {
        margin-left: 10px;
        padding-right: 0px;
    }
    &.plus-icon {
        margin-left: 10px;
        padding: 0px;
    }
`;
const BootstrapDialog = styled(Dialog)`
  & .MuiDialogContent-root {
    padding: 0;
  }
  & .MuiDialogActions-root {
    padding: 0;
  }
  .dialog-content {
    background-color: #F4F7FC;
    width: 100%;
    height: 363px;
  }
  .MuiDialogContent-root {
    padding: 0; // No units needed for 0
  }
  .dialog-content-product {
    background-color: white;
    width: 100%;
  }
  .button-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 56%;
    margin: 20px auto 0px;
  }
  .button-image {
    width: 64px;
    height: 64px;
    margin-bottom: 10px;
  }
  .button-text {
    text-transform: capitalize;
    font-family: Lato;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
  }
  .css-tlc64q-MuiPaper-root-MuiDialog-paper {
    max-width: 800px !important;
  }

  .css-mbdu2s {
    max-width: 800px !important;
  }

  
`;
const buttonStyles = {
  width: '140px',
  height: '140px',
  borderRadius: '10px',
  border: '1px solid #E8E8E8',
  marginLeft: '13px',
  marginBottom: '13px',
  backgroundColor: 'white',
  color: 'black',
};

const StyledFormControlLabel = styled(FormControlLabel)`
    color: #848282;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 0px !important;
    padding: 0 26px;
    &.label-background {
        background: #EDF3FF;
        box-shadow: 0px 4px 4px 0px rgba(37, 89, 195, 0.20);
    }
`;
const StyledMenuItem = styled(MenuItem)`
    padding: 0px !important;
`;
const StyledMenu = styled(Menu)`
    .MuiPaper-root{
        width: 190px;
        top: 225px;
        ul{
            padding: 0px;
            li{
                label{
                    padding: 16px 12px;
                    span{
                        width: 63px;
                        white-space: normal;
                        overflow-wrap: break-word;
                        margin-left: -11px !important
                    }

                }
            }
        }
    }
`;

const StyledDropdownWrapper = styled.div`
    margin-right: 25px;
`;
const StyledLiveRankIcon = styled(SvgIcon)`
    &.live-icon {
        margin-top: 10px;

    }
`;
const StyledLiveRankButton = styled(Button)`
    left: 16px;
    .MuiButton-endIcon{
        margin-left: 30px;
    }
    &.dropdown-button-icon {
        background-color: #fff;
        color: #848282;
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: none;
        &:hover {
            background-color: #fff;
        }
    }
    &.dropdown-button-icon-open {
        background-color: #fff;
        color: #2559C3;
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: none;
        &:hover {
            background-color: #fff;
        }
    }
`;
export {
  ArtifactWrapper, StyledArtifactIcon, BootstrapDialog, buttonStyles, StyledLiveRankButton, StyledDropdownWrapper, StyledLiveRankIcon,
  StyledMenu, StyledMenuItem, StyledFormControlLabel, StyledTotalContainer,
};
