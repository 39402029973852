import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { CardProps } from './types';

const GenericCard = (props: CardProps) => {
  const {
    customClasses = {},
    title,
    children,
    sx,
    variant,
  } = props;

  return (
    <Card classes={{ ...customClasses }} sx={sx}>
      <CardContent>
        <Typography variant={variant}>
          {title}
        </Typography>
        {children}
      </CardContent>
    </Card>
  );
};

GenericCard.defaultProps = {
  title: 'Card',
  children: 'Description',
};

export default GenericCard;
