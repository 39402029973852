import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Kra } from './type';

const initialState:Kra = {
  responsibilities: [],
};

const KRASlice = createSlice({
  name: 'KRA',
  initialState,
  reducers: {
    fetchUserKRA: (state, action:PayloadAction<string []>) => {
      // eslint-disable-next-line no-param-reassign
      state.responsibilities = action.payload;
    },
  },
});

export const { fetchUserKRA } = KRASlice.actions;
export default KRASlice.reducer;
