import styled from 'styled-components';

const ProfileDetailWrapper = styled.div`
width:100%;

.email-text{
    word-wrap: break-word;
}

.details-paper-wrap{
    padding: 16px; 
    display: flex; 
    flex-direction: column; 
    flex-grow: 1; 
    border: 1px solid #E7E7E7; 
    box-shadow: none; 
    border-radius: 11px;
}
`;

export { ProfileDetailWrapper };
