// MessageAlert.tsx
/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import {
  Dialog,
  Alert,
  Divider,
  Card,
} from '@mui/material';
import {
  StyledDialogContent,
  IconWrapper,
  StyledDialogTitle,
  StyledDialogActions,
  StyledButton,
} from './MessageAlert.styled';

interface MessageAlertProps {
  open: boolean;
  onClose: () => void;
  header: string;
  message: string;
  alertType: 'success' | 'info' | 'warning';
}

const MessageAlert: React.FC<MessageAlertProps> = ({
  open,
  onClose,
  header,
  message,
  alertType,
}) => {
  const getIconComponent = (message: string) => {
    switch (alertType) {
      case 'success':
        return (
          <Alert severity="success">
            {message}
          </Alert>
        );
      case 'info':
        return (
          <Alert severity="info">
            {message}
          </Alert>
        );
      case 'warning':
        return (
          <Alert severity="warning">
            {message}
          </Alert>
        );
      default:
        return null;
    }
  };

  return (
    <Card variant="outlined">
      <Dialog open={open} onClose={onClose}>
        <StyledDialogTitle>{header}</StyledDialogTitle>
        <StyledDialogContent>
          <IconWrapper className={alertType}>
            {getIconComponent(message)}
          </IconWrapper>
        </StyledDialogContent>
        <Divider />
        <StyledDialogActions>
          <StyledButton onClick={onClose} color="primary">
            Close
          </StyledButton>
        </StyledDialogActions>
      </Dialog>
    </Card>
  );
};

export default MessageAlert;
