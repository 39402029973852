import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
    html {
        font: 62.5% / 1.15 sans-serif;
    }

    body {
        width: 100%;
        margin: 0 auto;
        font: 1.6rem/1.25 Arial, sans-serif;
        background-color: #f5f5f5!important;
        color: #4d4d4d;
        .MuiPaper-outlined:empty{
            border:none;
        }
        .tooltipStyle img,.alertBox svg{
            cursor:pointer;
        }
        .alertBox {
            display: flex;
            align-items: flex-start;
            font-weight: normal;
            
        }
        .alertBox .MuiAlert-message {
            width: calc(100% - 40px);
            ol, ul {
                padding-left: 15px;
                margin-top: 2px;
                li {
                    font-weight: normal;
                }
            }

        }
        .alertBox p {
            margin:0;
            font-weight: normal;
        }
        .noDataFound {
            padding:0 10px;
        }
        input:-webkit-autofill,
            input:-webkit-autofill:hover, 
            input:-webkit-autofill:focus, 
            input:-webkit-autofill:active{
                -webkit-box-shadow: 0 0 0 30px white inset !important;
        }
        @media(max-width:939px) {
            .edit-artifcate-page {
                .sc-cmaqmh {
                    padding:0 15px;
                }
                
                .edit_artifact-wrapper {
                    margin:0;
                }
            }
        }
        .headerContainer {
            @media(max-width:1199px) {
                .logo {
                    width:220px;
                    flex-basis: auto;
                    img {
                        max-width:100%;
                    }
                }
                .userDetails {
                    width:calc(67% - 220px);
                    flex-basis: auto;
                    margin-left:auto;
                    display: inline-flex;
                    max-width:none;
                }
                .userInfo ,.searchBar{
                    max-width:none;
                    flex-basis: auto;
                    width: 50%;
                    flex-wrap: nowrap;
                    
                }
                .userInfo {
                    .userName {
                        p {
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                            max-width: 200px;
                        }
                    }
                    .userPic {
                        margin-left:auto;
                    }
                }
            }
            @media(max-width:767px) {
                display: block;
                .logo {
                    width: 120px;
                    position: absolute;
                    top: 7px;
                    z-index:1;
                    left: 0px
                }
                .userDetails {
                    width:100%;
                    flex-wrap:wrap;
                   .userInfo,.searchBar {
                        width:100%;
                    }
                    .userInfo {
                        order:1;
                        .userName {
                            p {
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                                max-width: 200px;
                            }
                        }
                    }
                    .searchBar {
                          order:2;
                          justify-content: center;
                          & > div {
                            width:100%;
                          } 
                         .search-field {
                            width:100%;
                        }
                    }
                }
                
            }
        }
        @media (min-width: 1181px) and (max-width:1200px){
            .dashbaodProfile {
                & > div {
                    max-width:calc(50% - 16px);
                }
                hr {
                    display:none!important;
                }
            }
        }
        @media (min-width: 1200px) {
            header{
                .userDetails{
                    max-width:60% !important;
                    flex-basis:60% !important;
                }
            }
        }
        @media (min-width: 1200px) and (max-width:1480px){
            .logo {
                width:220px;
                flex-basis: auto;
                img {
                    max-width:100%;
                }
            }
            .userDetails {
                width:calc(100% - 220px);
                flex-basis: auto;
                margin-left:auto;
                display: inline-flex;
                max-width:none;
            }
            .searchBar {
                margin-right: 30px;
                margin-left: auto;
            }
            .userInfo ,.searchBar{
                max-width:none;
                flex-basis: auto;
                width:auto;
                
                
            }
            .userInfo {
                .userName {
                    p {
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        max-width: 200px;
                    }
                }
                .userPic {
                    margin-left:auto;
                }
            }
            .customOuterContainer {
                padding-left:55px;
                .profileHeader {
                    hr {display:none;}
                .user-profile-wrapper {
                    width:31%;
                    flex-grow: initial;
                    .user-profile-container {
                        flex-wrap:wrap;
                        padding-left:15px;
                        padding-right:0;
                        .user-profile-name {
                            font-size:20px;
                        }
                        .user-profile-container-content {
                            width:100%;
                        }
                        .user-profile-employee-id, .user-profile-email,.user-profile-designation,.user-profile-number{font-size:14px;}
                    }
                }
                .progress-profile-bar-report {
                    padding-right:0!important;
                    .avatar-wrap {
                        width: 100px;
                    }
                   
                    .borderLinearProgressWrapper {
                        width:150px;
                        flex-basis: unset;
                        flex-grow: unset;
                        
                    }
                }
                .totalPointsWrapper {
                    margin-left: auto;
                    .rank-list > ul {
                        padding:0;
                        li {
                            & > div {
                                width:100%;
                                & div {
                                    margin:0 auto;
                                }
                            }
                            padding:0 7px;
                            flex-wrap: wrap;
                            text-align: center;
                            justify-content: center;
                            span {
                                width:100%;
                            }
                        }
                    }
                }
            }
            }
            header{
                .userDetails{
                    max-width:60% !important;
                    flex-basis:60% !important;
                }
            }
        }
        @media (min-width: 1200px) and (max-width: 1500px){
            .customOuterContainer{
                .profileHeader{
                    justify-content: space-between;
                    .user-profile-wrapper{
                        width: 21%
                    }
                }
            } 
        }

        @media (min-width: 768px) and (max-width:939px){
            .customOuterContainer {
                .progress-profile-bar-report {
                    padding-left: 54px;
                }
                .employee-nav {
                    .override-tabpanel {
                        padding:0;
                        & > div{
                            width: 100%;
                            overflow-x: auto;
                            white-space: nowrap;
                           .artifact-main-table {
                            min-width: 800px;
                            .table-row-item {
                                min-height:auto;
                            }
                           }
                        }
                    }
                }
            }
            
        }
        @media (min-width: 940px){
            .customOuterContainer {
                .progress-profile-bar-report {
                    padding: 15px;
                }
            }
            
        }
        @media(max-width:767px) {
            overflow-x:hidden;
            .customOuterContainer {
                padding:40px 15px 24px 65px;
                & > div {
                    padding-left:0;
                    padding-right:0;
                }
                .user-profile-container {
                    display:block;
                    text-align:center;
                    padding: 10px 0;
                    .MuiAvatar-root{
                        margin:0 auto 15px;
                    }
                    .user-content {
                        display:block;
                        margin-bottom:10px;
                        padding:0 10px;
                    }
                    .user-profile-designation {
                        margin:5px 0;
                        padding:0 10px;
                    }
                    .user-profile-email {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 246px;
                    }
                }
                .progress-profile-bar-report {
                    flex-wrap: wrap;
                    padding: 0 10px;
                    .badge-strip-wrap {
                        height: 100%;
                        & + .badge-strip-wrap {
                            margin-top:7px;
                        }
                    }
                }
                .rank-list {
                    margin:10px 0;
                    ul {
                        padding:0 10px;
                        flex-wrap:wrap;
                        li {
                            padding:0;
                            & + li {
                                margin-top:10px;
                            }
                        }
                    }
                }
                .total-ponits {
                    ul {
                        width:100%;
                    }
                }
                .artifact-btn-space {
                    flex-wrap:wrap;
                    h4 {
                        font-size:2rem;
                    }
                    button {
                        padding: 6px 12px!important;
                        font-size: 14px!important;
                        &:last-child {
                            width: 80px;
                            height: 39px;
                           .MuiButton-endIcon {
                                margin-left:5px;
                            }
                        }
                    }
                }
                .employee-nav {
                    .override-tabpanel {
                        padding:0;
                        & > div{
                            width: 100%;
                            overflow-x: auto;
                            white-space: nowrap;
                           .artifact-main-table {
                            min-width: 700px;
                            .table-row-item {
                                min-height:auto;
                            }
                           }
                        }
                    }
                }
            }
        }
    }
    .dropdown-button-icon {
        border: 1px solid rgb(37, 89, 195) !important;
        background-color: rgb(255, 255, 255) !important;
        color: rgb(37, 89, 195) !important;
    }
    .sidebar-menu-link:nth-child(1) {
        padding: 5px 0px;
    }

    .sidebar-menu-link:nth-child(2) {
        padding-top: 5px;
    }

    .sidebar-menu-link:nth-child(3) {
        padding-top: 10px;
    }

    .sidebar-menu-link .menu-link {
        text-decoration: none;
        color: inherit;
      }
      
    .sidebar-menu-link .menu-link:hover {
        color: #2559C3;
      }

    .cs_cussess_dialog .MuiDialog-paper{ 
        padding:32px;   
        .MuiTypography-h6{
            font-size: 24px;
            font-weight: 700;  
            line-height:1;
            padding:0 0 10px 0;
        }
        [aria-label="close"]{
            right: 23px;
            top: 23px;
            color:black;
        }
        .cs_msm_title{ 
            font-weight:600;
            font-size:18px;
            color:#009E23;
            margin:1.5rem 0 0;
            letter-spacing:.35px;
        }
        .cs_msm_footer{
            text-align:right;
            padding-top:1.75rem;
            margin-top:2rem;
            &:before{
                content:'';
                position:absolute;
                height:1px;
                left:0;
                right:0;
                margin-top:-19px;
                background: #d1d1d1;
            }
            .MuiButton-containedPrimary{ 
                color: white;
                border-radius: 0; 
            }
        }
    }
    
    body .cs_cussess_dialog .mdl_inner_box{
        padding: 40px;
        text-align: center;
        display: block;
        border: 2px dotted rgba(46, 125, 50, 0.58);
        background:rgba(0, 158, 35, 0.05);
    }
    .mdl_inner_box .MuiAvatar-circular{
        -webkit-box-pack: center;
        justify-content: center;
        color: rgb(0, 158, 35);
        width: 85px;
        height: 85px;
        margin: 0px auto; 
        border: 6px solid currentColor;
        border-radius:50%;
        background:transparent;
        svg{
            display:none;
        }
        &:after{
            content: "";
            display: inline-flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            width: 46px;
            height: 18px;
            border-style: solid;
            border-color: currentcolor;
            border-image: initial;
            border-width: 6px 6px 0px 0px;
            transform: rotate(-222deg);
            position: relative;
            top: 0px;
        }
    }
    .css-17jm6f1-MuiButtonBase-root-MuiButton-root{
        padding:9px 16px;
    }
    .view-custom-menu div:nth-child(3){
        border: 1px solid rgb(211, 211, 211);
        box-shadow: none;
    }
    input:-internal-autofill-selected {
        background-color: transparent !important;
      }
    .primary-color {
        background-color: rgba(37, 89, 195, 1) !important;
    }
    .secondary-btn {
        color: rgba(37, 89, 195, 1) ;
        border-color: rgba(37, 89, 195, 1);
        &:disabled img {
            filter: grayscale(1);
            opacity: 0.5;
        }
    }
    .css-bhp9pd-MuiPaper-root-MuiCard-root {
        box-shadow: unset !important
    }
    #sidebar a.active {
        color: red;
    }
    #demo-simple-select-label {
        background: white ;
        padding: 2px;
    }

    .progress-bar-report {
   height: 100%;
    }

    .progress-bar-report .badge-trophy-progress-wrap {
        height: 100%;
       }
    .dashbaodProfile .scoring-dash {
        height: 100%;  
    }

    .dashbaodProfile .badge-strip-wrap {
        height: 100%;   
    }

    .progress-bar-report .badge-icon-title {
        width: 135px;
        font-family: Lato;
        font-size: 16px;
        font-weight: 500;
    }

    .progress-bar-report .badge-progress-bar {
        width: 193px;
        height: 15px;
        margin-right: 4px;
        border-radius: 12px;
        background-color: rgb(217, 217, 217);
    }

    
    .progress-bar-report .badge-strip-wrap {
        border-bottom: 1px solid rgb(231, 231, 231);
        display: flex;
        align-items: center;
        @media (max-width: 1600px) {
            .actual-points-text {
                font-size: 13px;
            }
        }
        @media (max-width: 1600px) {
            .MuiGrid-item {
                display: block;
                width: 100%;
            }
        }
        
    }

    .progress-bar-report .actual-points-text {
        color: rgb(123, 123, 123);
        font-family: Lato;
        font-size: 16px;
        font-weight: 700;
    }

    .progress-bar-report .percentage-text{
        width: 80px;
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        color: rgb(123, 123, 123);
    }
    .edit-artifcate-page {
        background-color: #fff;
        color: rgba(0, 0, 0, 0.87);
        -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        border-radius: 4px;
        overflow: hidden;
    }

    .edit_artifact-wrapper {
        margin: 0 40px 40px;
        border: 1px solid rgb(211, 211, 211);
    }

    .edit_artifact-wrapper .footer-buttons{
        display: flex;
        justify-content: end;
    }

    .edit_artifact-wrapper .form-label{
        color: black;
        margin-bottom: 5px;
    }

    .edit_artifact-wrapper .addArtifact-form-btn {
        color: #ffffff !important;
      }

    .edit_artifact-wrapper .secondary-btn {
        color: rgb(37, 89, 195) !important;
        border: 1px solid rgb(37, 89, 195) !important;
    }
    .edit_artifact-wrapper .blue-btn {
        background-color: rgb(37, 89, 195) !important;
        color: rgb(255, 255, 255) !important;
        border: 0!important;
        &:hover , &:focus {
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
        }
    }

    .ck-editor {
        .ck-content {
            height: 195px;
            }
    }
    .progress-profile-bar-report .badge-trophy-progress-wrap {
        margin-right: 0
    }
    .progress-profile-bar-report .badge-icon-title,
    .progress-profile-bar-report .actual-points-text,
    .progress-profile-bar-report  .percentage-text {
        font-family: Lato;
        font-size: 16px;
        font-weight: 600;
        line-height: 19px;
        letter-spacing: 0em;
    } 
    .progress-profile-bar-report {
        display: flex;
        .avatar-wrap {
            width: 115px
        }
    } 
    
    .progress-profile-bar-report .percentage-text {
        color: #7B7B7B;
    } 
    .progress-profile-bar-report .badge-progress-bar {
        width: 143px;
        height: 12px;
        
        border-radius: 12px;
        background-color: rgb(217, 217, 217);
    }
    .MuiTabs-indicator {
        background: #2559C3 !important;
    }
    #ReactSimpleImageViewer {
        background-color: #e7e5e5;
        .react-simple-image-viewer__slide {
            height: 90%;
            margin-top: 6%;
            padding-bottom: 4%;
        }
    }
    #project-select-label {
        position: absolute;
    }
    .preview-image-add {
        position: relative;
        .close-icon {
            color: rgb(222, 78, 53);
            cursor: pointer;
            position: absolute;
            top: -8px;
            right: -10px;
            border: 1px solid rgb(222, 78, 53);
            border-radius: 10px;
            font-size: 14px;
            background: #fff;
        }
        .view-icon {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            opacity: 0;
        }
        &:before {
            content: "";
            background: #000000;
            position: absolute;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: 0.5s;
        }
        &:hover {
            &::before {
                opacity: 0.5;
            }
            .view-icon {
                opacity: 1;
            }
        }

    }
    .custom-divider{
        @media (max-width: 1180px){
            display: none;
        }
    }
    .score-streak-wrap{
        @media (max-width: 1180px){
            flex-basis: 50% !important;
            max-width:100% !important;

            .score-streak-title{
                margin-top: 10px;
            }
        }
    }

    .score-chart-inner-wrap{
        width:100%;
        display: flex;
        justify-content: center;
    }
    .view-artifact-detail-field{
        display: flex;
    }
`;
export default GlobalStyles;
