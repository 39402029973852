import React, { useEffect, useRef, useState } from 'react';
import {
  Button, Alert, CircularProgress, Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { ProjectData } from './type';
import Toast from '../../../components/Toast/Toast';
import { ToastProps } from '../../../components/Toast/types';
import { validateFields } from './validate';
import { mapSeverityToAlertColor, getCurrentYear } from '../../../utils/utils';
import { config } from '../../../config';
import { CommHandler } from '../../../utils';
import { FormWrapper } from './form.styled';
import { MessageAlert } from '../../../components/MessageAlert';
import AddProductLevelForm from './addProductlevelForm';
import MenuAppraisalCycle from '../../Profile/components/MenuAppraisalCycle';

const AddProjectLevelArtifact = () => {
  const navigate = useNavigate();
  const { text: artifactName, id: selectedCatId, categoryCode: artifactCatCode } = useParams();
  const projectLevelDataInterface: ProjectData = {
    artifact_category_id: '',
    artifact_sub_category_id: '',
    artifact_item_id: '',
    name: '',
    file_path: [],
    user_id: '',
    approved_by: null,
    year: '',
    from_date: '',
    to_date: '',
    project_manager_id: null,
    description: '',
    is_draft: false,
    project_working_status: false,
    achieved_points: null,
    project_name: '',
    files: [],
    type: '',
    selectedlineItem: '',
    url: '',
    project_id: '',
    project_manager: [],
  };
  const [data, setData] = useState<ProjectData[]>([
    projectLevelDataInterface,
  ]);

  const [selectedYear, setSelectedYear] = useState<string | null>(getCurrentYear().toString());
  const handleYearSelect = (year) => {
    setSelectedYear(year);
  };
  const [submitEnabled, setSubmitEnabled] = useState<boolean>(true);
  const [validationErrors, setValidationErrors] = useState([{}]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showAlertMessage, setShowAlertMessage] = useState(false);
  const [alertType, setAlertType] = useState<'success' | 'info' | 'warning'>('success');
  const [alertHeader, setAlertHeader] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [lineItem, setlineItem] = useState<any[]>([]);
  // const [showAlert, setShowAlert] = useState(false);
  // const [addMoreHide, setAddMoreHide] = useState(true);
  const [hasLineItem, setHasLineItem] = useState(false);
  const [isSavingDraft, setIsSavingDraft] = useState(false);
  const isSavingDraftRef = useRef(isSavingDraft);
  const [diabledSumiit, setDiabledSumiit] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const naviagte = useNavigate();

  const [toastmsg, setToastMsg] = useState<ToastProps>({
    open: false,
    autoHideDuration: 9000,
    message: '',
    vertical: 'top',
    horizontal: 'center',
    severity: '',
  });
    // enable and dissable buttons when it have data
  const handleChange = (index: number, updatedData: any) => {
    setData((prevData) => {
      const newData = [...prevData];
      newData[index] = updatedData;
      return newData;
    });
  };

  const handleShowAlert = (
    header: string,
    message: string,
    type: 'success' | 'info' | 'warning',
  ) => {
    setAlertType(type);
    setAlertHeader(header);
    setAlertMessage(message);
    setShowAlertMessage(true);
  };

  // const handleForm = () => {
  //   if (data.length < 5) {
  //     setData((prevData) => [...prevData, projectLevelDataInterface]);
  //   } else {
  //     setAddMoreHide(false);
  //     // setShowAlert(true);
  //   }
  // };
  // Used to clear Toast message
  const onDismiss = () => {
    setToastMsg({
      open: false,
      message: '',
      horizontal: 'center',
      vertical: 'top',
      severity: '',
    });
  };

  const addArtifactFormData = async (artifactFormData: any) => {
    const updatedFormData = artifactFormData.map((formData: any) => ({
      ...formData,
      year: selectedYear,
      files: formData.files.map((file: any) => ({
        ...file,
        type1: file.type,
      })),
    }));
    CommHandler.request({
      url: `${config.apiBaseUrl}/artifact`,
      method: 'POST',
      data: updatedFormData,
    })
      .then((response: any) => {
        if (response.status === 200) {
          setSubmitEnabled(false);
          setIsLoading(false);
          handleShowAlert(
            'Add Artifacts',
            'Artifact  Added successfully!',
            'success',
          );
        } else {
          setToastMsg({
            open: true,
            message: response.message,
            vertical: 'top',
            horizontal: 'center',
            severity: 'error',
          });
          setSubmitEnabled(false);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setToastMsg({
          open: true,
          message: err.message,
          vertical: 'top',
          horizontal: 'center',
          severity: 'error',
        });
        setIsLoading(false);
      });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    // get login userID
    setIsLoading(true);
    const loggedInUserID = localStorage.getItem('user_id');
    const department = localStorage.getItem('department');

    const updatedFormData = data.map((item) => ({
      ...item,
      artifact_category_id: selectedCatId,
      department,
      user_id: loggedInUserID,
    }));
    const isFieldsValid = validateFields({
      artifactFormData: updatedFormData,
      artifactTypeName: artifactCatCode,
      hasLineItem,
    });
    setValidationErrors(isFieldsValid);
    if (isFieldsValid.every((obj) => Object.keys(obj).length === 0)) {
      data.forEach(async (el) => {
        // const formData = new FormData();
        const fileNames = el?.files?.map((file: any) => file.fileName) || [];
        if (fileNames?.length > 0) {
          try {
            await CommHandler.post(
              `${config.apiBaseUrl}/uploadFiles/moveFiles`,
              { files: fileNames },
            );
          } catch (error) {
            console.error('Error uploading file:', error);
            throw error;
          }
        }
      });
      await addArtifactFormData(updatedFormData);
    } else {
      setIsLoading(false);
      setToastMsg({
        open: true,
        message: 'Please fill all required fields',
        vertical: 'top',
        horizontal: 'center',
        severity: 'error',
      });
    }
  };

  useEffect(() => {
    isSavingDraftRef.current = isSavingDraft;
    return () => {
      // Cleanup function to reset isSavingDraft when the component is unmounted
      setIsSavingDraft(false);
      isSavingDraftRef.current = false;
    };
  }, [isSavingDraft]);

  const handleDraft = async () => {
    if (isSavingDraftRef.current) {
      return;
    }
    setIsSavingDraft(true);
    setDiabledSumiit(true);
    try {
      const saveAsDraft = true;
      const isFieldsValid = validateFields({
        artifactFormData: data,
        draftFlag: saveAsDraft,
      });
      setValidationErrors(isFieldsValid);
      if (isFieldsValid.every((obj) => Object.keys(obj).length === 0)) {
        const loggedInUserID = localStorage.getItem('user_id');
        const updatedFileData: any[] = await Promise.all(
          data.map(async (el) => {
            // const formData = new FormData();
            const fileNames = [];

            const reformattedData = el;
            if (el.files.length > 0) {
              el.files.forEach((file: any) => {
                fileNames.push(file.fileName);

                // formData.append('files', file)
              });
              try {
                // const res: any = await CommHandler.post(
                await CommHandler.post(
                  `${config.apiBaseUrl}/uploadFiles/moveFiles`,
                  { files: fileNames },
                  // formData,
                  // {
                  //   headers: {
                  //     'Content-Type': 'multipart/form-data',
                  //     ...getAuthHeader,
                  //   },
                  // },
                );
                // const filePath = res.fileDataArray.map((item: any) => ({
                //   filename: item.filename,
                //   // s3Url: item.s3Url,
                // }));
                // reformattedData.file_path = filePath.map((item: any) => item.filename);
              } catch (error) {
                console.error('Error uploading file:', error);
                throw error;
              }
            }
            return reformattedData;
          }),
        );
        const updatedDraftFormData = updatedFileData.map((item) => ({
          ...item,
          is_draft: true,
          user_id: loggedInUserID,
          artifact_category_id: selectedCatId,
        }));
        await addArtifactFormData(updatedDraftFormData);
      } else {
        setToastMsg({
          open: true,
          message: 'Please fill all required fields',
          vertical: 'top',
          horizontal: 'center',
          severity: 'error',
        });
      }
    } catch (e) {
      console.error('Error saving draft:', e);
      setDiabledSumiit(false);
    } finally {
      setIsSavingDraft(false);
    }
  };

  // const handleFormDelete = (index: any) => {
  //   const newData = [...data];
  //   newData.splice(index, 1);
  //   setData(newData);
  //   if (newData.length < 5) {
  //     setAddMoreHide(true);
  //     setShowAlert(false);
  //   }
  // };

  const handleCloseAlert = () => {
    if (alertType === 'success') {
      naviagte('/artifact-detail', { state: { tab: selectedCatId } });
    }
    // setShowAlert(false);
  };

  const onClose = () => {
    navigate(-1);
  };

  const handleCancelConfirmation = () => {
    setShowConfirmationDialog(false);
  };

  const handleConfirmCancelConfirmation = () => {
    setShowConfirmationDialog(false);
    onClose();
  };

  const handClickBack = () => {
    // Check if any of the form fields are filled
    const hasUnsavedChanges = data.some((formData) => Object.keys(formData).some(
      (key) => formData[key] !== projectLevelDataInterface[key]
          && formData[key] !== ''
          && formData[key] !== null
          && formData[key].length > 0, // Check for non-empty arrays
    ));

    if (hasUnsavedChanges) {
      setShowConfirmationDialog(true);
    } else {
      onClose();
    }
  };

  return (
    <>
      {toastmsg && (
      <Toast
        open={toastmsg.open}
        autoHideDuration={toastmsg.autoHideDuration}
        message={toastmsg.message}
        vertical={toastmsg.vertical}
        horizontal={toastmsg.horizontal}
        onClose={onDismiss}
      >
        <Alert
          onClose={onDismiss}
          severity={mapSeverityToAlertColor(toastmsg.severity)}
          sx={{ width: '100%' }}
        >
          {toastmsg.message}
          !
        </Alert>
      </Toast>
      )}

      <FormWrapper
        style={{
          background: 'white',
          border: '1px solid rgb(231, 231, 231)',
          borderRadius: '10px',
        }}
      >
        <div className="artifact-Level-section" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className="title">
            <div
              onClick={handClickBack}
              style={{ cursor: 'pointer' }}
              onKeyPress={(e: { key: string }) => e.key === 'Enter' && handClickBack()}
              role="button"
              tabIndex={0}
            >
              <img
                src="/leftArrow.png"
                alt="left-arrow"
                width="16px"
                height="16px"
                style={{
                  marginRight: '12px',
                  marginTop: '5px',
                }}
              />
            </div>
            {' '}
            Artifact Level :
            {' '}
            {artifactName || ''}
          </div>
          <MenuAppraisalCycle defaultSelectedYear={selectedYear} onYearSelect={handleYearSelect} />
          {/* <div className="level">
            {artifactName}
          </div> */}
        </div>
        <div className="level-section">
          {data.length
            ? data.map((item, index) => (
              <AddProductLevelForm
                onChange={(updatedData: any) => handleChange(index, updatedData)}
                data={item}
                index={index}
                setData={setData}
                                  // setAddMoreHide={setAddMoreHide}
                artifactType={artifactCatCode}
                setSubmitEnabled={setSubmitEnabled}
                artifactName={artifactName}
                selectedCatId={selectedCatId}
                validationErrors={validationErrors}
                setValidationErrors={setValidationErrors}
                                  // files={files}
                                  // formArray={data}
                lineItem={lineItem}
                setlineItem={setlineItem}
                                  // setFiles={setFiles}
                                  // handleFormDelete={handleFormDelete}
                                  // categoryDetails={categoryDetails}
                setHasLineItem={setHasLineItem}
              />
            ))
            : null}
          {/* <div className="add-product-level-artifact-btn">
            {showAlert && (
              <Alert severity="warning" onClose={() => setShowAlert(false)}>
                You can only add up to 5 forms.
              </Alert>
            )}
            {addMoreHide
              ? (
                <Button
                  variant="outlined"
                  onClick={handleForm}
                  className="secondary-btn"
                >
                  Add more +
                </Button>
              ) : null}
          </div> */}
          <div
            className="flex-row-container"
            style={{
              marginTop: '20px',
              display: 'flex',
              justifyContent: 'end',
            }}
          >
            <div className="form-btn-grp">
              <Button
                className="secondary-btn"
                disabled={isLoading || isSavingDraftRef.current}
                variant="outlined"
                onClick={() => handleDraft()}
                startIcon={(
                  <img
                    src="/draftImage.png"
                    alt="draft"
                    width="15px"
                    height="15px"
                  />
                                  )}
              >
                Save as draft
              </Button>
              {/* <Button className="secondary-btn" variant="outlined" onClick={() => handlePreview()} startIcon={<img src="/view.png" alt="view" width="15px" height="15px" />}>
                Preview
              </Button> */}
              {isLoading || diabledSumiit ? (
                <div className="">
                  <Button
                    className="addArtifact-form-btn"
                    variant="contained"
                    disabled={isLoading || diabledSumiit}
                    startIcon={
                                            isLoading && (
                                            <CircularProgress
                                              size={20}
                                              color="inherit"
                                            />
                                            )
                                        }
                  >
                    Submit
                  </Button>
                </div>
              ) : (
                <div>
                  <Button
                    className={`addArtifact-form-btn ${
                      !submitEnabled
                        ? 'primary-color'
                        : ''
                    }`}
                    variant="contained"
                    disabled={submitEnabled}
                    onClick={handleSubmit}
                    startIcon={(
                      <img
                        src="/submit1.png"
                        alt="submit button"
                        width="15px"
                        height="15px"
                      />
                                          )}
                  >
                    Submit
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
        <Dialog className="btn_style_prt" open={showConfirmationDialog}>
          <DialogTitle>Are you sure?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              You have unsaved changes. Are you sure you want to cancel?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelConfirmation}>No</Button>
            <Button onClick={handleConfirmCancelConfirmation}>Yes</Button>
          </DialogActions>
        </Dialog>
        <MessageAlert
          open={showAlertMessage}
          onClose={handleCloseAlert}
          header={alertHeader || 'Alert'}
          message={alertMessage || 'An error occurred.'}
          alertType={alertType}
        />
      </FormWrapper>
    </>
  );
};

export default AddProjectLevelArtifact;
