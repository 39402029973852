import React from 'react';
import { Avatar } from '@mui/material';
import { AvatarProps } from './type';

const CustomeAvatar = (props:AvatarProps) => {
  const {
    imageUrl, customesx, icon, altText,
  } = props;
  return (
    <Avatar src={imageUrl} alt={altText} sx={customesx}>
      {icon}
    </Avatar>
  );
};

CustomeAvatar.defaultProps = {
  src: '',
  sx: {},
};
export default CustomeAvatar;
