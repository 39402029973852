import { SvgIcon } from '@mui/material';
import styled from 'styled-components';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import FormControlLabel from '@mui/material/FormControlLabel';

const ListTableWrapper = styled.div`
  padding: 0;
  
  .list_table-btn-space {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .filter-btn {
      margin-right: 20px;
      font-size: 16px;
      font-weight: 500;
      font-style: Inter;
      line-height: 19.36px;
      color: #2559C3;
      border: 1px solid #2559C3;
      padding: 16px 24px;

      .filter-down-icon {
        margin-bottom: -15px;
      }
    }

    .addlist_table-btn {
      font-size: 16px;
      font-weight: 500;
      font-style: Inter;
      line-height: 19.36px;
      color: #ffffff;
      padding: 16px 24px;
    }
  }

  .employee-nav {
    margin-top: 40px;
    
    .live-rank-tab-container {
      text-transform: capitalize;
      font-style: Inter;
      font-weight: 400;
      font-size: 14px;
      line-height: 16.94px;
      color: #848282;
    }
  }

  .list_table-main-table {
    margin-top: 20px;
    // border: 1px solid #D3D3D3;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 12px 24px 13px;

    .certificate-heading {
      padding: 10px;
      font-family: Lato;
    }

    .override-tabpanel {
      padding: unset;
    }

    .table-row-item-cell {
      width: 100%;
      display: flex;
      align-items: center;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 14px;
      background: #E9EEF9;
      padding:  8px 16px; 
      justify-content: space-between;
      height: 48px;
      margin:  0;
      border-radius: 10px;
    }

    .table-row-item {
      display: flex;
      align-items: center;
      border-radius: 0;
      border: 1px solid #eee;
      background: #FFF;
      justify-content: flex-end;
      height:auto;
      padding: 0px 0; 
      gap: 25px;
      border-radius:8px;

      .list-list_table {
        padding-left:0px;
         
        .status-pending {
          border-radius: 5px;
          padding: 0px 10px;
          gap: 10px;
          background-color: #FFEED8;
          font-family: Lato;
          font-weight: 500;
          font-size: 12px;
          line-height: 27px;
          color: #EDA13B;
        }

        .status-approved {
          border-radius: 5px;
          padding: 0px 10px;
          gap: 10px;
          background-color: #DBFFD8;
          font-family: Lato;
          font-weight: 500;
          font-size: 12px;
          line-height: 27px;
          color: #0EAA00;
        }

        .status-rejected {
          border-radius: 5px;
          padding: 0px 10px;
          gap: 10px;
          background-color: #FFCCCC;
          font-family: Lato;
          font-weight: 500;
          font-size: 12px;
          line-height: 27px;
          color: #FF0000;
        }

        .listIem-text {
          font-weight: 900;
          font-size: 12px;
          font-family: Inter;
          padding: 0px;

          span {
            font-family: Inter;
            font-size: 12px;
            font-weight: 500;
            color: #000000;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  .list_table-table-head {
    font-family: Lato;
    font-size: 16px;
    font-weight: 500;
    color: #06152B;
    width: 25%;
    text-transform: uppercase; 
    text-align:left;
    flex:1 1 42%;
    font-size: 12px;
    font-weight: 500;
  }

  .list-list_table-box-wrap {
    flex:0 1px auto;
     text-align:center;
     width: 25%;
     
  }

  .table-row-profile-container {
    width: 8%;
  }

  .primary-icon {
    color: blue;
  }
  .secondary-icon {
    color: gray;
  }
  .success-icon {
    color: green;
  }
  .danger-icon {
    color: red;
  }

  .status-field-wrap {
    width: 25%;
    display: flex;
    justify-content: center;
  }

  .list-table-status-head{
    text-align: center;
  }

  .list-table-action-head{
    text-align: center;

  }
  .actions-field-wrap{
    width: 25%;
    display: flex;
    justify-content: center;
  }

`;

const StyledListTableIcon = styled(SvgIcon)`
  &.list_table-icon {
    margin-left: 10px;
    padding-right: 0px;
  }
  &.plus-icon {
    margin-left: 10px;
    padding: 0px;
  }
`;

const AntSwitch = styled(Switch)`
'&:active': {
  '& .MuiSwitch-thumb': {
    width: 15;
  }
  '& .MuiSwitch-switchBase.Mui-checked': {
    transform: 'translateX(9px)';
  }
}
'& .MuiSwitch-switchBase': {
  padding: 2;
  '&.Mui-checked': {
    transform: 'translateX(12px)';
    color: '#fff';
    '& + .MuiSwitch-track': {
      opacity: 1;
      backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff';
    }
  }
}
'& .MuiSwitch-thumb': {
  boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)';
  width: 12;
  height: 12;
  borderRadius: 6;
  transition: theme.transitions.create(['width']; {
    duration: 200;
  });
}
'& .MuiSwitch-track': {
  borderRadius: 16 / 2;
  opacity: 1;
  backgroundColor:
    theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)';
  boxSizing: 'border-box';
}
`;

const StyledDropdownWrapper = styled.div`
    margin-right: 25px;
    .dropdown-button-icon{
        border: 1px solid rgb(37, 89, 195) !important;
        background-color: rgb(255, 255, 255) !important;
        color: rgb(37, 89, 195) !important;

        &:hover {
            background-color: #fff;
        }
    }
    .dropdown-button-icon-open{
        border: 1px solid rgb(37, 89, 195) !important;
        background-color: rgb(255, 255, 255) !important;
        color: rgb(37, 89, 195) !important;

        &:hover {
            background-color: #fff;
        }
    }
`;

const StyledLiveRankButton = styled(Button)`
    left: 16px;
    .MuiButton-endIcon{
        margin-left: 30px;
    }
    .dropdown-button-icon {
        background-color: #fff;
        color: #848282;
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: none;
        padding: 16px, 24px, 16px, 24px;
        &:hover {
            background-color: #fff;
        }
    }
    .dropdown-button-icon-open {
        background-color: #fff;
        color: #2559C3;
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 16px, 24px, 16px, 24px;
        text-transform: none;
        &:hover {
            background-color: #fff;
        }
    }
`;

const StyledMenu = styled(Menu)`
    .MuiPaper-root{
        width: 250px;
        height: auto;
        top: 150px !important;
        ul{
            div{
                padding:10px;
             li{
                 width:100%;
                 &:nth-child(odd){
                    background-color: #EDF3FF;
                 }
                 &:nth-child(even){
                   background-color: #ffffff;
                 }

                div{
                    width:100%;
                    label{
                        padding: 16px 12px;
                        display: flex;
                        justify-content:space-between;
                        span{
                            width: 90px;
                            white-space: normal;
                            overflow-wrap: break-word;
                            font-size: 18px;
                        }
    
                    }
                }

              }
            }

        }
    }

`;

const StyledMenuItem = styled(MenuItem)`
    padding: 0px !important;
`;

const StyledLiveRankIcon = styled(SvgIcon)`
    &.live-icon {
        margin-top: 10px;

        path{
            fill:#2559C3;
        }
    }
`;
const StyledFormControlLabel = styled(FormControlLabel)`
    color: #848282;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 0px !important;
    &.label-background {
        background: #EDF3FF;
        box-shadow: 0px 4px 4px 0px rgba(37, 89, 195, 0.20);
    }
`;

export {
  ListTableWrapper, StyledListTableIcon, AntSwitch, StyledDropdownWrapper,
  StyledLiveRankButton, StyledMenu, StyledMenuItem, StyledLiveRankIcon, StyledFormControlLabel,
};
