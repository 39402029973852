/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react';
import {
  List as UIList,
  ListItem,
  ListItemText,
} from '@mui/material';
import { IListProps } from './types';

const List = (props: IListProps) => {
  const {
    listLabels,
    onSecondaryAction,
    bgcolor,
    maxWidth,
    width,
    customClasses = {},
  } = props;
  return (
    <UIList
      sx={{ width: width || '100%', maxWidth: maxWidth || 360, bgcolor: bgcolor || 'background.paper' }}
      classes={{ ...customClasses }}
    >
      {listLabels.map((listLabel) => (
        <ListItem
          key={listLabel.label}
          disableGutters
          secondaryAction={onSecondaryAction || false}
        >
          <ListItemText primary={`${listLabel.label}`} />
        </ListItem>
      ))}
    </UIList>
  );
};

List.defaultProps = {
  listLabels: [
    {
      label: 'Option A',
    },
    {
      label: 'Option B',
    },
    {
      label: 'Option C',
    },
  ],
  bgcolor: 'White',
  maxWidth: 200,
  width: '100%',
};

export default List;
