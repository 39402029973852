import React from 'react';
import { IconButton as MuiIconButton } from '@mui/material';
import { Favorite } from '@mui/icons-material';
import { IconButtonProps } from './types';

const IconButton = (props: IconButtonProps) => {
  const {
    customClasses = {},
    IconComponent,
    onClick,
    ...iconDefaultProps
  } = props;

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };
  return (
    <MuiIconButton
      classes={{ ...customClasses }}
      onClick={handleClick}
    >
      <IconComponent {...iconDefaultProps} />
    </MuiIconButton>
  );
};

IconButton.defaultProps = {
  IconComponent: Favorite,
  onClick: () => {},
  color: 'primary',
  fontSize: 'large',
};

export default IconButton;
