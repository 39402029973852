import styled from 'styled-components';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
const StyledPointsContainer = styled.div`
    width: 80px;
`;
const BorderLinearProgress = styled(LinearProgress)(() => ({
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#D9D9D9',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#2559C3',
  },
}));

const BadgeAndTrophiesWrapper = styled.div`
display: flex;
flex-grow: 1;
min-width: 100%;
@media (max-width:1124px): {
  flex-wrap: wrap;
}
 .badge-trophy-card-parent-wrap {
    display: flex;
    flex-grow: 1;
    min-width: 100%;
    @media (max-width:1124px): {
      flex-wrap: wrap;
    }
  }

 .badge-trophy-section-wrap {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 65%;
 }
 .badge-trophies-title{
    font-family: Lato;
    font-weight: 700;
    color: #06152B;
 }

 .your-achievement-box-wrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 6px 16px 16px;
    background: #e9eef9;
    border-radius: 18px;
    width: 98.6%;
 }

 .your-achievements-title{
    font-family: Lato;
    font-size: 12px;
    font-weight: 500;
 }

 .badge-icon-section-wrap{
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    justify-content: space-between;
 }

 .badge-icon-button-inner-wrap{
    display: flex;
    flex-direction: column;
    align-items: center;
 }

 .badge-trophy-progress-wrap{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 6px 16px 16px;
    gap: 26px;
 }

 .badge-strip-wrap{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
 }

 .avatar-wrap{
    display: flex;
 }

 .avatar-icon-badge{
    width: 24px;
    height: 24px;
    margin-right: 4px;
 }

 .badge-icon-title{
    width: 75px;
    font-family: Lato;
    font-size: 16px;
    font-weight: 500;
 }

 .badge-progress-bar{
    width: 143px;
    height: 15px;
    margin-right: 4px;
    border-radius: 12px;
    background-color: #D9D9D9;
 }

 .actual-points-text{
    color: #2559C3;
    font-family: Lato;
    font-size: 16px;
    font-weight: 700;
 }

 .total-points-text{
    color: #7B7B7B;
    font-family: Lato;
    font-size: 16px;
    font-weight: 700;
 }

 .badge-training-text{
    width: 80px;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    color: #7B7B7B;
 }

 .card-wrap{
    width: 48%;
    height: 50%;
    margin-bottom: 20px;
 }

 .main-card{
    border: 1px solid lightgrey;
    border-radius: 16px;
    max-width: 100%;
    height: 100%;
    box-shadow: none;
 }

 .card-content{
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
 }

 .card-title{
    padding-left: 5px;
    font-size: 22px;
    font-weight: 700;
 }

 .card-high-count-text-wrap{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background-color: blue;
    color: white;
    margin: 24px 0px;
 }

 .card-high-count-text{
    font-size: 24px;
    font-weight: 500;
 }

 .card-up-arrow{
    font-size: small;
    color: #20CB31;
 }

 .card-percent-text{
    color: #20CB31;
    font-size: 12px;
 }

 .card-update-date-text{
    font-size: 12px;
 }

 .
`;

const BadgeTrophyCardWrapper = styled.div`
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 42%;
    margin-left: 2%;
    @media (max-width: 1124px) {
        width: 100%;
        flex-wrap: wrap;
    }
`;
export {
  BadgeAndTrophiesWrapper,
  StyledPointsContainer,
  BorderLinearProgress,
  BadgeTrophyCardWrapper,
};
