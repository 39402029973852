import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Listkey } from './type';

const initialState: Listkey = {
  list: null,
};

const liveRankingSlice = createSlice({
  name: 'artifactData',
  initialState,
  reducers: {
    storeArtifactData: (state, action: PayloadAction<Listkey>) => {
      /* eslint-disable no-param-reassign */
      state.list = action.payload;
    },
  },
});

export const { storeArtifactData } = liveRankingSlice.actions;

export default liveRankingSlice.reducer;
