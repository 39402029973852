import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MenuItem, Select, styled } from '@mui/material';
import { getCurrentYear } from '../../../utils/utils';

interface AppraisalCycle {
  duration: string;
  label?: string;
}

const StyledMenuSelect = styled(Select)`
  &.menu-item-list {
    color: #848282;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
interface AppraisalsCycleMenuProps {
  onYearSelect: (year: any) => void;
  defaultSelectedYear: any;
}

const MenuAppraisalCycle: React.FC<AppraisalsCycleMenuProps> = ({ onYearSelect, defaultSelectedYear }) => {
  const defaultYear = defaultSelectedYear && defaultSelectedYear ? defaultSelectedYear : getCurrentYear().toString();
  const [selectedOption, setSelectedOption] = useState<string>(defaultYear);
  const [appraisalOptions, setAppraisalOptions] = useState([getCurrentYear()]);
  const joiningYear = useSelector(
    (state: any) => state.appraisalCycle.joiningYear,
  );

  const currentYear = selectedOption ? parseInt(selectedOption.toString(), 10) : getCurrentYear();
  const nextYear = parseInt(selectedOption.toString(), 10) + 1 || currentYear + 1;

  const [appraisalCycle, setAppraisalCycle] = useState<AppraisalCycle>({
    duration: `01 April, ${currentYear} to 31 March, ${nextYear}`,
  });

  const appraisalOptionsfun = (joiningVal:Date) => {
    const options = [];
    const startyear = joiningVal.getMonth() > 2 ? joiningVal.getFullYear() : joiningVal.getFullYear() - 1;
    for (let year = startyear; year <= getCurrentYear(); year += 1) {
      options.push(year);
    }
    return options;
  };

  useEffect(() => {
    const appLaunchDate = new Date('2023-04-01');
    const empJoining = new Date(joiningYear || appLaunchDate);
    const startingDate = appLaunchDate.getFullYear() >= empJoining.getFullYear() ? appLaunchDate : empJoining;
    setAppraisalOptions(appraisalOptionsfun(startingDate));
  }, [joiningYear]);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    const nextYearValue = parseInt(option, 10) + 1;
    setAppraisalCycle({
      duration: `01 April, ${option} to 31 March, ${nextYearValue}`,
    });

    // Call the function passed from the parent to update the selected year in the parent component
    onYearSelect(option);
  };

  return (
    <div>
      {appraisalCycle && (
        <StyledMenuSelect
          className="menu-item-list"
          value={selectedOption}
          sx={{
            boxShadow: 'none',
            marginRight: '10px',
            '.MuiOutlinedInput-notchedOutline': { border: 0 },
          }}
          placeholder="name"
        >
          {appraisalOptions.map((option) => (
            <MenuItem
              key={option}
              value={option}
              onClick={() => handleOptionSelect(option)}
            >
              Appraisal Cycle
              {' '}
              {option}
              -
              {`${option + 1}`.slice(-2)}
            </MenuItem>
          ))}
        </StyledMenuSelect>
      )}
    </div>
  );
};

export default MenuAppraisalCycle;
