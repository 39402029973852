/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable consistent-return */
import React, { useState, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  FormLabel, Stack, Box, Button, ListItem, ListItemText, ListItemAvatar, Tooltip, CircularProgress, Alert, Dialog,
} from '@mui/material';
import DOMPurify from 'dompurify';
import Typography from '@mui/material/Typography';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import dayjs from 'dayjs';
import axios from 'axios';
import {
  Dropdown, FormInput, RadioButtons, Loader,
}
  from '../../../components';
import { formatDate, getAuthHeader, isNil } from '../../../utils/utils';
import { CommHandler } from '../../../utils';
import { config } from '../../../config';
import { AddProductlevelFormWrapper } from './addProductlevelForm.styled';
import { ProjectData } from './type';
import { Editor } from '../../../components/CKEditor';
import ImagePreview from './ImagePreview';
import { constant } from '../../../libs/Constant';

const vectorImage = <img src="/Vector.png" alt="vector_img" className="va_m" />;
const AddProductLevelForm = (props: any) => {
  const {
    data = {}, index, onChange = () => { }, artifactType, setSubmitEnabled, setHasLineItem, validationErrors, selectedCatId, setValidationErrors, lineItem, setlineItem,
  } = props;
  const [files, setFiles] = useState<any[]>([]);
  // const [completionPercentage, setCompletionPercentage] = useState(0);
  const [isCurrentlyWorking, setisCurrentlyWorking] = useState<boolean>(true);
  const [subCategories, setSubCategories] = useState<[]>([]);
  const [tempPath, setTempPath] = useState<any[]>([]);
  const [subScore, setsubScore] = useState();
  const [lineItemScore, setlineItemScore] = useState('');
  const [isShow, setisShow] = React.useState(false);
  // const [hasFileUploaded, setHasFileUploaded] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [lineItemLabel, setLineItemLabel] = useState('');
  const [fromDate, setFromDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [, setOpenfromDate] = useState(false);
  const [, setOpenToDate] = useState(false);
  const [url, setUrl] = useState('');
  const [fileUrl, setfileUrl] = useState('');
  const [fileType, setfileType] = useState('');
  const [showBuArtifact, setshowBuArtifact] = useState(false);
  const [projectOptions, setProjectOptions] = useState([]);
  const [instruction, setInstructions] = useState('');
  const [fileDetails, setFileDetails] = useState([]);
  const [subcategorySelected, setSubcategorySelected] = useState(false);
  const [validationMessage, setValidationMessage] = useState<ProjectData>({
    artifact_category_id: '',
    artifact_sub_category_id: '',
    artifact_item_id: '',
    name: '',
    file_path: [''],
    user_id: '',
    approved_by: '',
    year: '',
    from_date: '',
    to_date: '',
    project_manager_id: '',
    project_id: '',
    project_manager: [],
    description: '',
    is_draft: false,
    project_working_status: false,
    achieved_points: 0,
    project_name: '',
    fileError: '',
    files: [],
    type: '',
    url: '',
  });
  const department = getAuthHeader['x-department'];
  const urlRegex = /^(https:\/\/)([\da-z.-]+)\.([a-z.]{2,})([/\w.-]*)*(#.*)?$/i;
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await CommHandler.request({
          url: `${config.adminApiURL}/artifact/category/${selectedCatId}`,
          method: 'GET',
          params: { status: 1 },
        });
        const reqData = await response?.data;
        return reqData;
      } catch (err) {
        return false;
      }
    }

    fetchData().then((getData) => {
      if (getData) {
        const subData = getData?.subCategory?.filter((item) => {
          if (item?.businessUnit?.length > 0) {
            return (
              item?.artifact_category_id === selectedCatId
              && item?.status === 1
              && (item?.businessUnit?.some((id) => id?.name === department))
            );
          }
          return (
            item?.artifact_category_id === selectedCatId && item?.status === 1
          );
        });
        setSubCategories(subData);
      }
    });
  }, [selectedCatId, department]);

  async function fetchProject() {
    try {
      const responseProject = await CommHandler.request({
        url: `${config.apiBaseUrl}/project`,
        method: 'GET',
      });
      const reqData = await responseProject?.data;
      setProjectOptions(reqData);
    } catch (err) {
      return false;
    }
  }

  useEffect(() => {
    fetchProject();
  }, []);
  const onProjectNameChange = (projectName: string, projectManager: string, event: any, value: any) => {
    const selectedProject = projectOptions?.find((project) => project?.projectId === value?.optionValue);
    const projectManagerDetails = selectedProject?.projectManager.map(({ display_name, email }: { display_name: string, email: string }) => ({
      name: display_name,
      email,
    }));
    if (value === null || value === undefined) {
      const updatedData = {
        ...data,
        project_name: '',
        [projectManager]: [],
      };
      onChange(updatedData);
      return;
    }
    const updatedData = {
      ...data,
      project_id: selectedProject?.projectId,
      project_name: value.label,
      [projectManager]: projectManagerDetails,
      selectedlineItem: lineItemLabel,
    };
    const newErrors = [...validationErrors];
    newErrors[index] = {
      ...newErrors[index],
      project_name: '',
      project_manager_id: '',
    };
    setValidationErrors(newErrors);
    onChange(updatedData);
    return {};
  };

  const projectOption = projectOptions?.map((projectName: any, indexId) => ({
    id: indexId + 1,
    label: projectName?.projectName,
    optionValue: projectName?.projectId,
  }));

  const updateItemField: any = (fieldName: string, updatedValue: string) => {
    const newErrors = [...validationErrors];

    newErrors[index] = {
      ...newErrors[index],
      [fieldName]: updatedValue,
    };
    setValidationErrors(newErrors);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleFileSelect = async (item: any, ind: any) => {
    try {
      const response = await CommHandler.get(`${config.apiBaseUrl}/uploadFiles/get-signed-url`, {
        params: { key: item.fileName },
      });
      const signedUrl = response;

      if (signedUrl) {
        const str = `${config.s3URL}${config.tempBucket}/${item.fileName}`;
        const result = str?.substring(str.length - 3);
        setfileUrl(str);
        setfileType(result);
        setshowBuArtifact(true);
      } else {
        console.error('Signed URL is undefined');
      }
    } catch (error) {
      console.error('Error handling file select:', error.message);
    }
  };

  const formContainerStyles = {
    marginTop: '20px',
  };

  const handleUpload = async (files) => {
    try {
      const response = await CommHandler.post(`${config.apiBaseUrl}/uploadFiles/signedUrl`, { files });
      const presignedUrls = response?.data;

      await Promise.all(presignedUrls.map(async ({ url, fileName }, index) => {
        const file = files[index]?.fileObject;
        // eslint-disable-next-line no-param-reassign
        files[index].fileName = fileName;
        await axios.put(url, file, {
          headers: {
            'Content-Type': file.type,
          },
        });
      }));
      const filePath = presignedUrls.map((item: any) => ({
        filename: item.fileName,
        // s3Url: item.s3Url,
      }));
      setFileDetails((prev) => [...prev, ...files]);
      setTempPath((existing: any) => [...existing, ...filePath]);
      const newData = {
        ...data,
        files: [...fileDetails, ...files],
        file_path: [...tempPath, ...filePath].map(({ filename }: { filename: string }) => filename),
      };
      onChange(newData);
      // setHasFileUploaded(true);
      setisLoading(false);
    } catch (e) {
      setisLoading(false);
      console.error(`Error while uploading file: ${e.message}`);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setValidationMessage((prevMessages: any) => ({
        ...prevMessages,
        fileError: '',
      }));
      setisLoading(true);
      updateItemField('files', '');
      const invalid = acceptedFiles.filter((file) => file.size > 5242880);
      const invalidFileTypes = acceptedFiles.filter((file) => !['image/png', 'image/jpeg', 'image.jpg', 'application/pdf'].includes(file.type));
      const checkForInvalidExtensions = acceptedFiles.filter((file) => {
        const fileNameParts = file.name.split('.');
        return fileNameParts.length > 2 && fileNameParts.every((part, ind) => ind !== fileNameParts.length - 1);
      });
      if (checkForInvalidExtensions.length > 0) {
        setValidationMessage((prevMessages: any) => ({
          ...prevMessages,
          fileError: 'File(s) with invalid extensions',
        }));
        setisLoading(false);
      } else if (invalid.length) {
        setValidationMessage((prevMessages: any) => ({
          ...prevMessages,
          fileError: 'File Size cannot be more than 5MB',
        }));
        setisLoading(false);
      } else if (files.length + acceptedFiles.length > 5) {
        setValidationMessage((prevMessages: any) => ({
          ...prevMessages,
          fileError: 'You cannot upload more than 5 Files',
        }));
        setisLoading(false);
      } else if (
        acceptedFiles.some((obj1: any) => files.some((obj2: any) => obj1.name === obj2.name))
      ) {
        setValidationMessage((prevMessages: any) => ({
          ...prevMessages,
          fileError: '1 or more files already exists',
        }));
        setisLoading(false);
      } else if (invalidFileTypes.length) {
        setValidationMessage((prevMessages: any) => ({
          ...prevMessages,
          fileError: 'Invalid file(s). File type can only be image or pdf',
        }));
        setisLoading(false);
        return false;
      } else {
        setValidationMessage((prevMessages: any) => ({
          ...prevMessages,
          fileError: '',
        }));
        const formData = new FormData();
        acceptedFiles.forEach((file: any) => formData.append('files', file));
        const allFile = acceptedFiles.map((file: any) => ({
          name: file.name,
          type: file.type,
          size: file.size,
          fileObject: file,
        }));
        setFiles((existing: any) => [...existing, ...allFile]);

        handleUpload(allFile);
      }
    },
  });
  // Handle Validation
  const handleValidation = (feild: string, value: string) => {
    if (value === null || value === undefined || value.trim() === '') {
      setValidationMessage((prevMessages) => ({
        ...prevMessages,
        [feild]: 'This field is required',
      }));
    } else if (feild === constant.DESCRIPTION) {
      if (value.length > constant.DESCRIPTION_LIMIT) {
        setValidationMessage((prevMessages) => ({
          ...prevMessages,
          [feild]: constant.MAX_CHAR_LIMIT,
        }));
      }
    } else if (feild === 'project_name') {
      setValidationMessage((prevMessage) => (
        {
          ...prevMessage,
          [feild]: '',
        }
      ));
    } else {
      setValidationMessage((prevMessages) => ({
        ...prevMessages,
        [feild]: '',
      }));
      updateItemField(feild, '');
    }
  };

  const onURLBlur = (value: string) => {
    if (!urlRegex.test(value)) {
      return setValidationMessage((prevMessages) => ({
        ...prevMessages,
        url: 'Invalid URL format',
      }));
    }
    setValidationMessage((prevMessages) => ({
      ...prevMessages,
      url: '',
    }));
  };
  // Handle input value
  const handleChange = (key: string, event: any) => {
    const { value } = event.target;
    const sanitizedInput = DOMPurify.sanitize(value);
    if (sanitizedInput !== undefined && sanitizedInput !== null && sanitizedInput !== '') {
      setSubmitEnabled(false);
      setValidationMessage((prevMessages) => ({
        ...prevMessages,
        [key]: '',
      }));
      updateItemField(key, '');
    } else {
      setSubmitEnabled(true);
    }
    if (key === constant.DESCRIPTION) {
      if (value.length > constant.DESCRIPTION_LIMIT) {
        setSubmitEnabled(true);
        setValidationMessage((prevMessages) => ({
          ...prevMessages,
          [key]: constant.MAX_CHAR_LIMIT,
        }));
      } else {
        // setValidationMessage((prevMessages) => ({
        //   ...prevMessages,
        //   url: '',
        // }));
        setSubmitEnabled(false);
      }
    }

    if (key === 'url') {
      setUrl(sanitizedInput);
      if (!urlRegex.test(sanitizedInput)) {
        setValidationMessage((prevMessages) => ({
          ...prevMessages,
          url: 'Invalid URL format',
        }));
      } else {
        setValidationMessage((prevMessages) => ({
          ...prevMessages,
          url: '',
        }));
      }
    }
    if (key === 'project_working_status') {
      setisCurrentlyWorking(sanitizedInput !== 'true');
    }
    const updatedData = {
      ...data, [key]: value,
    };
    onChange(updatedData);
  };
  const handleSubCategory = (key: string, event: any) => {
    const { value } = event.target;
    subCategories.map((itm: any) => {
      /* eslint no-underscore-dangle: 0 */
      if (value === itm.id) {
        if (itm.line_item.length > 0) {
          const options = itm.line_item?.map((item: any, indexId: any) => ({
            id: indexId + 1,
            label: item.name,
            optionValue: item.name,
            score: item.score,
          }));
          setSubcategorySelected(true);
          setlineItem(options);
          setHasLineItem(options && options.length > 0);
          setlineItemScore('');
          const updatedData = {
            ...data, [key]: value, achieved_points: '',
          };
          onChange(updatedData);
          // when its true check lineItem validation
        } else {
          setlineItem([]);
          setHasLineItem(false);
          setsubScore(itm.sub_category_score);
          setlineItemScore(itm.sub_category_score);
          setLineItemLabel('');
          const updatedData = {
            ...data, [key]: value, achieved_points: itm.sub_category_score, selectedlineItem: '',
          };
          onChange(updatedData);
        }
        if (itm.instructions) {
          setInstructions(itm.instructions);
        } else {
          setInstructions('');
        }
        setisShow(true);
        setSubcategorySelected(true);
        return false;
      }
      return {};
    });
  };

  const handleLineItem = (key: string, event: any) => {
    const { value } = event.target;
    const filtelineitem = lineItem.filter((element: { label: any; }) => element.label === value);
    setlineItemScore(filtelineitem[0]?.score);
    setLineItemLabel(value);
    if (artifactType === 'SQU') {
      const updatedData = {
        ...data, project_name: '', [key]: filtelineitem[0]?.score, selectedlineItem: filtelineitem[0]?.label,
      };
      onChange(updatedData);
    } else {
      const updatedData = {
        ...data, [key]: filtelineitem[0]?.score, selectedlineItem: filtelineitem[0]?.label,
      };
      onChange(updatedData);
    }
  };
  const handleEditorChange = (event: any, editor: any) => {
    handleChange(constant.DESCRIPTION, {
      target: {
        value: editor.getData(),
      },
    });
  };

  const handleEditorBlur = useCallback((event: any, editor: any) => {
    handleValidation(constant.DESCRIPTION, editor.getData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDateChange = (key: string, date: number | null) => {
    if (date !== null) {
      setValidationMessage((prevMessages) => ({
        ...prevMessages,
        [key]: '',
      }));
      onChange({ ...data, [key]: formatDate(date) });
      updateItemField(key, '');
    } else {
      setValidationMessage((prevMessages) => ({
        ...prevMessages,
        [key]: 'This field is required',
      }));
      onChange({ ...data, [key]: null });
    }
  };
  // useEffect(() => {
  //   let percentage = 0;
  //   const filledFields: any = Object.values(data).filter((value) => {
  //     if (typeof value === 'string') {
  //       return value.trim() !== '';
  //     }
  //     return false; // Skip non-string, non-boolean values.
  //   });
  //   const modifiedFilledFields = filledFields.filter((item) => item !== 'true' && item !== 'false');
  //   const isFileUploaded = hasFileUploaded ? 1 : 0;
  //   if (artifactType !== 'PRO') {
  //     percentage = Math.round(((modifiedFilledFields.length - 1 + isFileUploaded) / 6) * 100);
  //     setCompletionPercentage(percentage);
  //   } else {
  //     percentage = Math.round(((modifiedFilledFields.length - 1 + isFileUploaded) / 8) * 100);
  //     setCompletionPercentage(percentage);
  //   }
  // }, [data, artifactType, hasFileUploaded]);

  function handleSubmit(event: { preventDefault: () => void }) {
    event.preventDefault();
  }

  const fileInputReference = React.useRef<HTMLInputElement>(null);

  const handleClick = () => {
    setshowBuArtifact(false);
    if (fileInputReference.current) {
      fileInputReference.current.click();
    }
  };
  const removeElement = (i: any) => {
    const filteredFiles = files.filter((file: any) => file.fileName !== i);
    const filteredFilesData = data.files.filter((file: any) => file.fileName !== i);
    const filteredPAth = tempPath.filter((item: any) => item.filename !== i);
    setValidationMessage((prevMessages: any) => ({
      ...prevMessages,
      fileError: '',
    }));
    setFiles(filteredFiles);
    // setHasFileUploaded(!!filteredFiles.length);
    setTempPath(filteredPAth);
    onChange({
      ...data,
      files: filteredFilesData,
      file_path: filteredPAth.map((item: any) => item.filename),
    });
  };

  const options = subCategories?.map((subcategory: any, indexId) => ({
    id: indexId + 1,
    label: subcategory.name,
    optionValue: subcategory.id,
  }));
    // eslint-disable-next-line react/no-unstable-nested-components
  const LineItems = () => (lineItem.length ? (
    <>
      <div className="flex-column-container tooltipStyle">
        <FormLabel>
          Line Item*
          {' '}
          <Tooltip title="Please select a Line Item " arrow>
            {vectorImage}
          </Tooltip>
          {' '}
        </FormLabel>
        <Dropdown
          inputLabel="Select Line Item"
          options={lineItem || []}
          value={lineItemLabel}
          width="100%"
          onChange={(e) => handleLineItem('achieved_points', e)}
        />
        {!lineItemLabel && validationErrors[index]?.selectedlineItem && (
        <span style={{ color: 'red' }}>{validationErrors[index]?.selectedlineItem}</span>
        )}
      </div>
      {lineItemScore && (
        <div
          style={{
            marginLeft: '50px',
            display: 'flex',
            flexDirection: 'column',
            width: '12%',
          }}
        >
          <FormLabel>
            Points
            {' '}
            <Tooltip title="Points" arrow>
              {vectorImage}
            </Tooltip>
            {' '}
          </FormLabel>
          <ListItem
            sx={{
              width: '80px',
              height: '40px',
              backgroundColor: '#FDF5EB',
              borderRadius: '5px',
              padding: '8px 8px 8px 8px',
              fontStyle: 'Inter',
              fontWeight: '600',
              fontSize: '16px',
              lineHeight: '19.36px',
              color: '#EDA13B',
              gap: '8px',
              marginLeft: '-4',
            }}
          >
            <ListItemAvatar sx={{ minWidth: '0px !important' }} />
            <img
              src="/Page-1.png"
              width="24"
              height="24"
              alt=""
            />
            <ListItemText className="listIem-text" primary={lineItemScore} />
          </ListItem>
        </div>
      )}
    </>
  ) : (
    <div className="flex-column-container tooltipStyle" style={{ marginLeft: '100px' }}>
      <FormLabel>
        Points
        {' '}
        <Tooltip title="Points" arrow>
          {vectorImage}
        </Tooltip>
        {' '}
      </FormLabel>
      <ListItem
        sx={{
          width: '80px',
          height: '40px',
          backgroundColor: '#FDF5EB',
          borderRadius: '5px',
          padding: '8px 8px 8px 8px',
          fontStyle: 'Inter',
          fontWeight: '600',
          fontSize: '16px',
          lineHeight: '19.36px',
          color: '#EDA13B',
          gap: '8px',
          marginLeft: '0',
        }}
      >
        <ListItemAvatar sx={{ minWidth: '0px !important' }} />
        <img
          src="/Page-1.png"
          width="24"
          height="24"
          alt=""
        />
        <ListItemText className="listIem-text" primary={subScore} />
      </ListItem>
    </div>
  ));
  return (
    <AddProductlevelFormWrapper>
      {isLoading && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 9999, // Ensure it appears above other content
        }}
        >
          <CircularProgress size={50} />
        </div>
      )}
      <div className="add-artifact-wrapper">
        <div
          className="artifact-header-section"
          role="button"
        >
          <div className="header-left-section">
            <div className="form-title">
              Add Artifacts
            </div>
            {/* <div>
              <Chip label={`${completionPercentage}%Filled`} />
            </div> */}
          </div>
        </div>
        <div className="artifact-form-section">
          <Typography sx={formContainerStyles}>
            <form onSubmit={handleSubmit} style={{ width: '100%', padding: '20px' }} autoComplete="off">
              <Stack spacing={2} direction="row" sx={{ marginBottom: 2, width: '100%' }}>
                <div className="flex-column-container tooltipStyle" style={{ width: '100%' }}>
                  <FormLabel>
                    Artifact Name*
                    {' '}
                    <Tooltip title="Please enter Artifact Name " arrow>
                      {vectorImage}
                    </Tooltip>
                  </FormLabel>
                  <FormInput
                    inputId="basic-input"
                    required
                    inputBoxType="outlined"
                    type="input"
                    disabled={false}
                    defaultValue={data.name}
                    InputLabelShrink={false}
                    maxLength={100}
                    onValueChange={(e) => handleChange('name', e)}
                    onBlur={() => handleValidation('name', data.name)}
                  />
                  {validationErrors[index]?.name ? <span style={{ color: 'red' }}>{validationErrors[index]?.name}</span> : <span style={{ color: 'red' }}>{validationMessage.name}</span>}
                </div>
                {artifactType === 'PRO' && (
                <div className="flex-column-container tooltipStyle" style={{ width: '100%' }}>
                  <FormLabel>
                    Project Name*
                    {' '}
                    <Tooltip title="Please select a Project" arrow>
                      {vectorImage}
                    </Tooltip>
                    {' '}
                  </FormLabel>
                  <Autocomplete
                    disablePortal
                    id="project-name"
                    options={projectOption}
                    getOptionLabel={(option) => option.label}
                    onChange={(e, value) => onProjectNameChange('project_id', 'project_manager', e, value)}
                    renderInput={(params) => <TextField {...params} label="Project name" />}
                    renderOption={(prop, item) => (
                      <li {...prop} key={item.id}>
                        {item.label}
                      </li>
                    )}
                  />
                  {validationErrors[index]?.project_name ? <span style={{ color: 'red' }}>{validationErrors[index]?.project_name}</span> : <span style={{ color: 'red' }}>{validationMessage.project_name}</span>}
                </div>
                )}
              </Stack>
              <Stack spacing={2} direction="row" sx={{ marginBottom: 2, width: '100%' }}>
                {artifactType === 'PRO' && (
                <div className="flex-column-container">
                  <FormLabel>
                    Project Manager*
                    {' '}
                    <Tooltip title="Based on Project selected above" arrow>
                      {vectorImage}
                    </Tooltip>
                    {' '}
                  </FormLabel>
                  <FormInput
                    inputBoxType="outlined"
                    type="input"
                    disabled
                    defaultValue={data?.project_manager?.map((manager: any) => manager.name).join(', ')}
                    value={data?.project_manager?.map((manager: any) => manager.name).join(', ')}
                    maxLength={30}
                  />
                  {validationErrors[index]?.project_manager_id ? <span style={{ color: 'red' }}>{validationErrors[index]?.project_manager_id}</span> : <span style={{ color: 'red' }}>{validationMessage.project_manager_id}</span>}
                </div>
                )}
              </Stack>
              <Stack spacing={2} direction="row" sx={{ marginBottom: 2, width: '100%' }}>
                <div className="flex-column-container select-box-focused tooltipStyle">
                  <FormLabel>
                    Artifact Subcategory*
                    {' '}
                    <Tooltip title="Please select a Subcategory" arrow>
                      {vectorImage}
                    </Tooltip>
                    {' '}
                  </FormLabel>
                  <Dropdown
                    inputLabel="Select Artifact Subcategory"
                    options={options || []}
                    value={data.artifact_sub_category_id}
                    onChange={(e) => handleSubCategory('artifact_sub_category_id', e)}
                    width="100%"
                  />
                  {validationErrors[index]?.artifact_sub_category_id && !subcategorySelected ? (
                    <span style={{ color: 'red' }}>
                      {validationErrors[index]?.artifact_sub_category_id}
                    </span>
                  ) : <span style={{ color: 'red' }}>{validationMessage.artifact_sub_category_id}</span>}
                </div>
                {isShow && LineItems()}
              </Stack>
              <Stack spacing={2} direction="row" sx={{ marginBottom: 2, width: '100%' }}>
                {instruction && (
                <Alert sx={{ fontWeight: 700, marginTop: '24px', width: '100%' }} severity="info" className="alertBox">
                  <strong>Instructions</strong>
                  <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(instruction) }} />
                </Alert>
                )}
              </Stack>

              <Stack spacing={2} direction="row" sx={{ marginBottom: 2, width: '100%' }}>
                {artifactType === 'SQU' && (
                <div className="flex-column-container tooltipStyle">
                  <FormLabel>
                    URL
                    {' '}
                    <Tooltip title="Please enter a valid URL" arrow>
                      {vectorImage}
                    </Tooltip>
                    {' '}
                  </FormLabel>
                  <FormInput
                    inputId="url-input"
                    inputBoxType="outlined"
                    type="url"
                    disabled={false}
                    maxLength={300}
                    defaultValue={url}
                    value={url}
                    InputLabelShrink
                    onValueChange={(e) => handleChange('url', e)}
                    onBlur={() => onURLBlur(data?.url)}
                  />
                  {validationErrors[index]?.url ? <span style={{ color: 'red' }}>{validationErrors[index]?.url}</span> : <span style={{ color: 'red' }}>{validationMessage?.url}</span>}
                </div>
                )}
              </Stack>
              {artifactType === 'SQU' && lineItemLabel === 'Project POC' && (
              <div className="flex-column-container tooltipStyle">
                <FormLabel>
                  Project Name*
                  {' '}
                  <Tooltip title="Please select a Project Name" arrow>
                    {vectorImage}
                  </Tooltip>
                  {' '}
                </FormLabel>
                <Autocomplete
                  disablePortal
                  id="project-name"
                  options={projectOption}
                  getOptionLabel={(option) => option.label}
                  onChange={(e, value) => onProjectNameChange('project_id', 'project_manager', e, value)}
                  renderInput={(params) => <TextField {...params} label="Project name" />}
                  renderOption={(prop, item) => (
                    <li {...prop} key={item.id}>
                      {item.label}
                    </li>
                  )}
                />
                {validationErrors[index]?.project_name ? <span style={{ color: 'red' }}>{validationErrors[index]?.project_name}</span> : <span style={{ color: 'red' }}>{validationMessage.project_name}</span>}
              </div>
              )}

              {artifactType === 'PRO' && (
              <>
                <Stack className="form-field-stack-wrapper" spacing={2} direction="row" sx={{ marginBottom: 2, width: '100%' }}>
                  <div className="flex-column-container tooltipStyle">
                    <FormLabel>
                      Currently Working on this Project?*
                      {' '}
                      <Tooltip title="Please specify if you are currently working on this project or not" arrow>
                        {vectorImage}
                      </Tooltip>
                      {' '}
                    </FormLabel>
                    <RadioButtons
                      isRow
                      status={data.project_working_status}
                      onValueChange={(e) => handleChange('project_working_status', e)}
                      radioButtonLabels={[
                        {
                          label: 'Yes',
                          value: 'true',
                        },
                        {
                          label: 'No',
                          value: 'false',
                        },
                      ]}
                    />
                  </div>
                </Stack>
                <Stack className="form-field-stack-wrapper" spacing={2} direction="row" sx={{ marginBottom: 2, width: '100%' }}>
                  <Stack className="duration_block" spacing={2} direction="row" sx={{ marginBottom: 2, width: '100%' }}>
                    <div className="form-duration-wrapper">
                      <div className="flex-column-container tooltipStyle">
                        <FormLabel>
                          Your Duration*
                          {' '}
                          <Tooltip title="If currently working on this project, please select start date. If not, please select a duration" arrow>
                            {vectorImage}
                          </Tooltip>
                          {' '}
                        </FormLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <div onClick={() => setOpenfromDate((prev) => !prev)} onKeyPress={() => null} role="button" tabIndex={0} aria-label="Open Date Picker">
                            <DatePicker
                              sx={{ width: '100%' }}
                              label="From"
                              value={fromDate}
                              onChange={(value: any) => {
                                const selectedDate = value.toDate();
                                setFromDate(selectedDate);
                                handleDateChange('from_date', selectedDate);
                                setOpenfromDate(false);
                              }}
                              maxDate={dayjs(endDate)}
                              disableFuture
                              minDate={dayjs().subtract(1, 'year').startOf('year').add(2, 'months')}
                              // minDate={dayjs().subtract(1, 'year')} // Set minimum date to previous year
                            />
                          </div>
                        </LocalizationProvider>
                        {validationErrors[index]?.from_date ? <span style={{ color: 'red' }}>{validationErrors[index]?.from_date}</span> : <span style={{ color: 'red' }}>{validationMessage.from_date}</span>}
                      </div>
                      {isCurrentlyWorking && (
                      <div className="flex-column-container" style={{ marginTop: '28px', marginLeft: '15px' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <div onClick={() => setOpenToDate((prev) => !prev)} onKeyPress={() => null} role="button" tabIndex={0} aria-label="Open Date Picker">

                            <DatePicker
                              sx={{ width: '100%' }}
                              label="To"
                              value={endDate}
                              onChange={(value: any) => {
                                const selectedDate = value.toDate();
                                setEndDate(selectedDate);
                                handleDateChange('to_date', selectedDate);
                                setOpenToDate(false);
                              }}
                                    // open={openToDate}
                                    // onAccept={() => setOpenToDate(false)}
                                    // onClose={() => setOpenToDate(false)}
                              disableFuture
                              minDate={dayjs(fromDate)}
                              maxDate={dayjs()}
                              disabled={!fromDate}
                            />

                          </div>
                        </LocalizationProvider>
                        {validationErrors[index]?.to_date ? <span style={{ color: 'red' }}>{validationErrors[index]?.to_date}</span> : <span style={{ color: 'red' }}>{validationMessage.to_date}</span>}
                      </div>
                      )}
                    </div>
                  </Stack>
                </Stack>
              </>
              )}
              <div className="flex-row-container" style={{ marginTop: '15px' }}>
                <div className="flex-column-container tooltipStyle" style={{ width: '100%' }}>
                  <FormLabel>
                    Artifact Description*
                    {' '}
                    <Tooltip title="Please enter Artifact Description highlighting details about the Artifact" arrow>
                      {vectorImage}
                    </Tooltip>
                    {' '}
                  </FormLabel>
                  <div className="ck-editor">
                    <Editor content={data.description} onChange={handleEditorChange} onBlur={handleEditorBlur} styleSet={undefined} />
                  </div>
                  {validationErrors[index]?.description ? <span style={{ color: 'red' }}>{validationErrors[index]?.description}</span> : <span style={{ color: 'red' }}>{validationMessage.description}</span>}
                </div>
              </div>
              <div className="flex-row-container" style={{ marginTop: '15px' }}>
                <div className="flex-column-container tooltipStyle" style={{ width: '100%' }}>
                  <FormLabel>
                    Upload Document*
                    {' '}
                    <Tooltip title="Please upload a supporting document. Only images and PDF formats are allowed" arrow>
                      {vectorImage}
                    </Tooltip>
                    {' '}
                  </FormLabel>
                  {files.length > 0
                    && (
                    <Box
                      sx={{
                        width: '100%',
                        padding: '8px 16px',
                        backgroundColor: 'rgba(200, 200, 200, 0.05)',
                        border: '2px solid rgba(166, 167, 168, 0.30)',
                        borderRadius: '6px',
                        alignItems: 'left',
                        gap: '24px',
                        alignSelf: 'stretch',
                        flexDirection: 'row',
                        display: 'flex',
                        marginBottom: '2px',
                        flexWrap: 'wrap',
                      }}
                    >
                      {files?.map((file: any, ind: any) => {
                        const filtType = file.name.split('.');

                        if (isNil(file?.fileName)) {
                          return <Loader />;
                        }

                        return (
                          <div key={file?.fileName}>

                            <div className="preview-image-add">

                              {filtType[filtType.length - 1] === 'pdf'
                                ? <PictureAsPdfIcon style={{ height: '60px', width: '60px', color: '#5788d7' }} />
                                : (
                                  <img
                                    src={`${config.s3URL}${config.tempBucket}/${file?.fileName}`}
                                    alt="Attachment"
                                    style={{
                                      width: 'auto', height: '60px',
                                    }}
                                  />
                                )}
                              <VisibilityOutlinedIcon className="view-icon" sx={{ color: '#ffffff', cursor: 'pointer' }} onClick={() => handleFileSelect(file, ind)} />
                              <CloseIcon
                                className="close-icon"
                                onClick={() => removeElement(file?.fileName)}
                              />

                            </div>
                          </div>

                        );
                      })}
                    </Box>
                    )}
                  <Box {...getRootProps()} className="drag-drop-wrapper">
                    <CloudUploadOutlinedIcon fontSize="large" className="upload-files-icon" />
                    <h2 className="upload-files-text">Drag and Drop Certificate files</h2>
                    or
                    <input {...getInputProps()} accept="image/jpeg, image/png, application/pdf" />
                    {isLoading ? (
                      <Button variant="outlined" disabled={isLoading}>
                        <CircularProgress size={20} color="inherit" />
                      </Button>
                    ) : (
                      <Button variant="outlined" onClick={handleClick}>
                        Choose files
                      </Button>
                    )}
                  </Box>
                  {validationErrors[index]?.files ? <span style={{ color: 'red' }}>{validationErrors[index]?.files}</span> : <span style={{ color: 'red' }}>{validationMessage.fileError}</span>}
                </div>
              </div>
            </form>
          </Typography>
        </div>
      </div>
      {showBuArtifact && (
        <Dialog
          open={showBuArtifact}
          onClose={() => setshowBuArtifact(false)}
          PaperProps={{
            style: { width: '80%', maxWidth: 'none' },
          }}
        >
          <ImagePreview type={fileType} url={fileUrl} setshowBuArtifact={setshowBuArtifact} />
        </Dialog>
      )}
    </AddProductlevelFormWrapper>
  );
};

export default AddProductLevelForm;
