import styled from 'styled-components';
export const StyledRank = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: center;
    @media (max-width: 1150px) {
        justify-content: left;
    }
`;

export const StyledRankContainer = styled.div`
    &.rank-list ul {
        display: flex;
        max-width: 100%;
        padding: 0 30px;
    }
    &.rank-list li p {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        /* identical to box height */
        color: #1D1D1D;
    }
    &.rank-list li span {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #7B7B7B;
        width: 100px;
    }
`;
