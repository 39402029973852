import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface InitialState {
  open: string,
}

export type ActiveArtifact = string;

const initialState: InitialState = {
  open: '',
};

const ArtifactSlice = createSlice({
  name: 'artifact',
  initialState,
  reducers: {
    activeArtifact: (state: InitialState, action: PayloadAction<ActiveArtifact>) => {
      /* eslint-disable no-param-reassign */
      state.open = action.payload;
    },
  },
});

export const { activeArtifact } = ArtifactSlice.actions;

export default ArtifactSlice.reducer;
