import React from 'react';
import { IPDFViewerProps } from './type';

const PDFViewer = (props: IPDFViewerProps) => {
  const {
    url,
    title,
    width,
    height,
  } = props;
  return (
    <div>
      <iframe src={url} title={title} width={width} height={height} />
    </div>
  );
};

PDFViewer.defaultProps = {
  width: 1200,
  height: 800,
};
export default PDFViewer;
