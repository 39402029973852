/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */

import {
  Stack,
  // Modal,
  Button,
  Typography,
  Box,
  Card,
  CardHeader,
  CardContent,
  DialogContent,
  Grid,
  Dialog,
} from '@mui/material';

import DOMPurify from 'dompurify';
import Accordion from '@mui/material/Accordion';
import { format } from 'date-fns';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import styled from 'styled-components';
import { config } from '../../../config';
import { RootState } from '../../../store/type';
import ImagePreview from './ImagePreview';

interface FormData {
  [key: string]: string | number | boolean | null;
  artifact_sub_category_id: string;
}

const accordionBorderStyle = {
  border: '1px solid #ccc',
  marginBottom: '10px',
};

const PreviewPage = () => {
  const [subCategoryNames, setSubCategoryNames] = useState<{ [key: string]: string }>({});
  const location = useLocation();
  const formData = useMemo(() => (location.state?.formData as FormData[]) || [], [location.state]);
  const artifactName = location?.state?.artifactName;
  const lineItem = location?.state?.lineItem;
  const selectUser = (state: RootState) => state.employees.employee;
  const employee: any = useSelector(selectUser);
  const [fileUrl, setfileUrl] = useState('');
  const [fileType, setfileType] = useState('');
  const [showBuArtifact, setshowBuArtifact] = useState(false);

  useEffect(() => {
    fetch(`${config.adminApiURL}/artifact/subcategory?${{ status: 1 }}`)
      .then((response) => response.json())
      .then((data) => {
        const names: { [key: string]: string } = {};
        data.data.forEach((item: any) => {
          names[item._id] = item.name;
        });

        const subCategoryNamesObject: { [key: string]: string } = {};
        formData.forEach((val) => {
          const subCategoryId = val.artifact_sub_category_id;
          subCategoryNamesObject[subCategoryId] = names[subCategoryId] || subCategoryId;
        });

        setSubCategoryNames(subCategoryNamesObject);
      })
      .catch((error) => {
        console.error('Error fetching subCategory names:', error);
      });
  }, [formData]);

  const boldTypography = (label: any, value: any) => (
    <Typography variant="h6">
      <strong>
        {label}
        {' '}
        :
        {' '}
      </strong>
      {' '}
      {value}
    </Typography>
  );

  const getProjectManagerName = (projectId: string | null) => {
    if (projectId) {
      const projectManager = employee.find((emp: { _id: string; }) => emp._id === projectId);
      return projectManager ? `${projectManager.firstName} ${projectManager.lastName}` : '';
    }
    return '';
  };

  const goBack = () => {
    window?.history?.back();
  };

  const artifactActionStyles = {
    display: 'flex',
    alignItems: 'center',
    marginTop: '20px',
    gap: '10px',
  };

  const handleFileSelect = (item: any) => {
    if (item) {
      const type = item?.type?.split('/');
      const filePath = URL.createObjectURL(item);
      setfileUrl(filePath);
      setfileType(type[1]);
      setshowBuArtifact(true);
    }
  };

  const projectInfo = formData.map((data) => ({
    artifactName: data?.name ?? null,
    projectName: data.project_name,
    artifactSubCategory: subCategoryNames[data?.artifact_sub_category_id] || null,
    projectManager: getProjectManagerName(data?.project_manager_id as string | null) || null,
    workingStatus: data?.project_working_status ?? null,
    fromDate: data?.from_date ?? null,
    toDate: data?.to_date ?? null,
    description: data?.description ?? null,
    files: data?.files || [],
    url: data?.url || [],
    selectedlineItem: data?.selectedlineItem || [],
    points: data?.achieved_points,
  }));

  return (
    <>

      <Card variant="outlined">
        <Box>
          <CardHeader
            title={artifactName}
            sx={{ backgroundColor: 'rgba(37, 89, 195, 10%)' }}
          />
          <CardContent>
            <DialogContent>
              {projectInfo?.map((info, index) => (
                <Accordion
                  key={index}
                  defaultExpanded
                  sx={{
                    ...accordionBorderStyle,
                    margin: '0 10px',
                    marginTop: index > 0 ? '10px' : '0',
                  }}
                >
                  <AccordionSummary
                    sx={{ background: '#f7f7f7' }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel-${index}-content`}
                    id={`panel-${index}-header`}
                  >
                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                      Form
                      {' '}
                      {index + 1}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Stack spacing={2} direction="column" sx={{ marginBottom: 4 }}>
                      <Grid item xs={12}>
                        {boldTypography('Artifact Name', info?.artifactName)}
                      </Grid>
                      {artifactName === 'Project Level' && (
                        <Grid>
                          {boldTypography('Project Name', info?.projectName)}
                        </Grid>
                      )}
                      <Grid>
                        {boldTypography('Artifact Subcategory', info?.artifactSubCategory)}
                      </Grid>
                      {lineItem.length !== 0 && (
                        <Grid>
                          {boldTypography('Line Item', info?.selectedlineItem)}
                        </Grid>
                      )}
                      {artifactName === 'Project Level' && (
                        <>
                          <Grid>
                            {boldTypography('Project Manager', info?.projectManager || '')}
                          </Grid>
                          <Grid item xs={12}>
                            {boldTypography('Currently Working on this Project?', info?.workingStatus ? 'Yes' : 'No')}
                          </Grid>
                          <Typography variant="h6">
                            <Typography variant="h6" sx={{ fontWeight: 'bold', display: 'inline' }}>
                              Your Duration:
                              {' '}
                            </Typography>
                            {info?.fromDate && info?.toDate && typeof info?.fromDate === 'string' && typeof info?.toDate === 'string' && (
                              `${format(new Date(info?.fromDate), 'dd-MM-yyyy')} to ${format(new Date(info?.toDate), 'dd-MM-yyyy')}`
                            )}

                            {info?.fromDate && !info?.toDate && typeof info?.fromDate === 'string' && typeof info?.toDate === 'string' && (
                              `${format(new Date(info?.fromDate), 'dd-MM-yyyy')} to Present`
                            )}
                          </Typography>
                        </>
                      )}
                      {artifactName === 'SQUAD Level' && info?.projectName && (
                      <Grid>
                        {boldTypography('Project Name', info.projectName)}
                      </Grid>
                      )}

                      {artifactName === 'SQUAD Level' && (
                        <Grid>
                          {boldTypography('URL', info?.url)}
                        </Grid>
                      )}
                      <Grid>
                        {boldTypography('Points', info?.points)}
                      </Grid>
                      <Grid>
                        {boldTypography('Artifact Description', <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(info?.description) as string }} />)}
                      </Grid>
                      <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Selected Files:
                      </Typography>
                      <div
                        className="artifact-action"
                        style={{
                          ...artifactActionStyles,
                          display: 'flex',
                          gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
                          gap: '10px',
                          overflowX: 'auto',
                          flexDirection: 'row',
                        }}
                      >
                        {Array.isArray(info?.files) && info?.files.length > 0
                          && info?.files.map((file: any) => (
                            <div
                              className="viewArtifactBtns"
                              key={file?.name}
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                padding: '10px',
                              }}
                            >
                              <Typography variant="h6" style={{ marginRight: '15px' }}>
                                <strong>{file?.name}</strong>
                              </Typography>
                              {file.type === 'pdf' ? (
                                <Button onClick={() => handleFileSelect(file)}>View PDF</Button>
                              ) : (
                                <img
                                  src={file.url}
                                  alt={`Attachment ${index + 1}`}
                                  style={{
                                    maxWidth: '100%',
                                    maxHeight: '100px',
                                    marginTop: '5px',
                                    border: '1px solid #ddd',
                                  }}
                                />
                              )}
                              <VisibilityOutlinedIcon
                                sx={{
                                  color: 'rgba(37, 89, 195, 1)',
                                  cursor: 'pointer',
                                  marginTop: '5px',
                                  marginLeft: '10px',
                                  marginRight: '15px',
                                }}
                                onClick={() => handleFileSelect(file)}
                              />
                            </div>
                          ))}
                      </div>

                    </Stack>
                  </AccordionDetails>
                </Accordion>
              ))}
            </DialogContent>
          </CardContent>

          <Button
            onClick={goBack}
            sx={{
              marginTop: '5px', float: 'right', marginRight: '10px', marginBottom: '15px',
            }}
            color="primary"
          >
            Back
          </Button>
        </Box>
      </Card>
      {showBuArtifact && (
        <Dialog
          open={showBuArtifact}
          onClose={() => setshowBuArtifact(false)}
          PaperProps={{
            style: { width: '80%', maxWidth: 'none' },
          }}
        >
          <ImagePreview type={fileType} url={fileUrl} setshowBuArtifact={setshowBuArtifact} />
        </Dialog>
      )}
    </>
  );
};
export default PreviewPage;
