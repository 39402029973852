import React from 'react';
import { constant } from '../../libs/Constant';
import { StyledNotFoundContainer, StyledErrorCode } from './NotFound.styled';

const NotFound: React.FC = () => (
  <StyledNotFoundContainer>
    <StyledErrorCode>{constant.PAGE_NOT_FOUND_CODE}</StyledErrorCode>
    {' '}
&nbsp;| &nbsp;
    {constant.PAGE_NOT_FOUND_MESSAGE}
  </StyledNotFoundContainer>
);

export default NotFound;
