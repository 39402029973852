import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SelectedSubcategoryState {
  selectedSubcategory: any[];
}

const initialState: SelectedSubcategoryState = {
  selectedSubcategory: [],
};

const selectedSubcategorySlice = createSlice({
  name: 'selectedSubcategory',
  initialState,
  reducers: {
    setSelectedSubcategory: (state, action: PayloadAction<any>) => {
      // eslint-disable-next-line no-param-reassign
      state.selectedSubcategory = action.payload;
    },
  },
});

export const { setSelectedSubcategory } = selectedSubcategorySlice.actions;
export default selectedSubcategorySlice.reducer;
