import { ListItem } from '@mui/material';
import styled from 'styled-components';
export const UserProfileWrapper = styled.div`
  flex-grow: 1;
`;
export const UserProfileContainer = styled.div`
  &.user-profile-container {
    display: flex;
    gap: 16px;
    justify-content: unsafe;
    align-items: center;
    padding: 22px 16px;
  }
`;

export const CustomListItem = styled(ListItem)`
    width: auto,
`;

export const UserProfileContent = styled.div`
  &.user-profile-container-content {
    .user-content {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
  }
  &.user-profile-container-content span {
    display: inline-block;
  }
  &.user-profile-container-content p {
    margin: 2px auto;
  }
  &.user-profile-container-content .user-profile-name {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    display: inline-block;
    margin-right: 10px;
  }
  &.user-profile-container-content .user-profile-employee-id {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #7b7b7b;
    line-height: 19px;
    margin-left: 8px;
    // transform: scale;
    // transform: scale(0.9);
    display: inline-block;
  }
  &.user-profile-container-content .user-profile-email {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #1d1d1d;
  }
  &.user-profile-container-content .user-profile-designation {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #2559c3;
    margin-right: 10px;
  }
  &.user-profile-container-content .user-profile-number {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #7b7b7b;
  }
`;
