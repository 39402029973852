/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { User } from '../Dashboard/components/UserProfile/type';
import { InitialState } from './type';

const initialState: InitialState = {
  employee: {},
  error: {},
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    fetchUserFulFilled: (state, action: PayloadAction<User>) => {
      state.employee = action.payload;
    },
  },
});

export const { fetchUserFulFilled } = userSlice.actions;

export default userSlice.reducer;

