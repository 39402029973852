import React from 'react';
import { Link } from '@mui/material';
import { LinkProp } from './types';

const GenericLink = (props: LinkProp) => {
  const {
    customClasses = {},
    children,
  } = props;

  return (
    <Link
      classes={{ ...customClasses }}
      {...props}
    >
      {children}
    </Link>
  );
};

GenericLink.defaultProps = {
  href: 'https://example.com',
  children: 'New Link',
  color: 'primary',
  variant: 'inherit',
  underline: 'hover',
};

export default GenericLink;
