import React, { useState } from 'react';
// import styled from 'styled-components';
import {
  AppBar,
  Box,
  Typography,
  Avatar,
  MenuItem,
  Menu,
  Modal,
  Button,
  ListItemIcon,
  Grid,
  ClickAwayListener,
} from '@mui/material';
import { KeyboardArrowDown, Person, Logout } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import { Image, Loader } from '../components';
import { config } from '../config';
import SearchBar from '../modules/Profile/components/SearchBar';
import { auth } from '../firebase';
import { logout } from '../store/actions';
import AppraisalsCycleMenu from '../modules/Profile/components/AppraisalMenu';

interface ConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  onConfirmLogout: () => void;
}
const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ open, onClose, onConfirmLogout }) => (
  <Modal open={open} onClose={onClose} aria-labelledby="logout-confirmation-modal" aria-describedby="logout-confirmation-modal">
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 296,
        bgcolor: 'background.paper',
        borderRadius: 3,
        p: 4,
      }}
    >
      <Typography variant="h6" id="logout-confirmation-modal" component="div" align="center" fontWeight="600" color="#000000">
        Are you sure you want to logout?
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: 2,
          gap: '8px',
        }}
      >
        <Button
          variant="outlined"
          sx={{
            mr: 1,
            backgroundColor: 'white',
            textTransform: 'none',
            color: '#2559C3',
            borderColor: '#2559C3',
          }}
          onClick={onClose}
        >
          Go back
        </Button>
        <Button
          variant="contained"
          sx={{
            mr: 1,
            backgroundColor: '#2559C3',
            textTransform: 'none',
            color: '#fff',
          }}
          onClick={onConfirmLogout}
        >
          Logout
        </Button>
      </Box>
    </Box>
  </Modal>
);

const Header: React.FC = () => {
  const user = useSelector((state: any) => state.user.employee);
  const { firstName, lastName, profile } = user;
  const fullName = `${firstName}`;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const imageUrlPrefix = `${config.profileImageUrl}/200x200/`;
  const dispatch = useDispatch();
  const altText = firstName && lastName ? `${firstName?.charAt(0).toUpperCase()}${lastName?.charAt(0).toUpperCase()}` : null;
  const navigate = useNavigate();

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    handleClose();
    setShowConfirmation(true);
  };
  const handleConfirmLogout = () => {
    dispatch(logout());
    localStorage.clear();
    signOut(auth);
    setShowConfirmation(false);
  };
  const handleModalClose = () => {
    setShowConfirmation(false);
  };
  return (
    <AppBar
      position="fixed"
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: '#fff',
        padding: '10px',
        boxShadow: 'none',
        borderBottom: '1px solid #EEEEEE',
      }}
      className="headerContainer"
    >
      <Grid className="logo" item xs={6}>
        <Box
          style={{ width: 'auto', display: 'inline-block' }}
          onClick={() => {
            navigate('/');
          }}
        >
          <Image src="/image 5.png" />
        </Box>
      </Grid>
      <Grid className="userDetails" container item xs={6} md={6}>
        <Grid container item xs={6} md={8} justifyContent="end" className="searchBar">
          <Box>
            <AppraisalsCycleMenu />
          </Box>
          <Box>
            <SearchBar />
          </Box>
        </Grid>
        <Grid container item xs={6} md={4} justifyContent="center" className="userInfo">
          <ClickAwayListener onClickAway={handleClose}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={handleClick}
            >
              <Grid className="userPic">
                <Avatar alt={profile?.imagePath ? '' : altText} src={`${imageUrlPrefix}${profile?.imagePath}`} />
              </Grid>
              <Grid paddingLeft={1} className="userName">
                {fullName && profile?.department ? (
                  <>
                    <Typography
                      style={{
                        color: '#00000',
                        fontWeight: 900,
                        lineHeight: '19px',
                        fontFamily: 'Lato',
                      }}
                    >
                      {fullName}
                    </Typography>
                    <Typography style={{ color: '#2559C3' }}>{profile?.department}</Typography>
                  </>
                ) : (
                  <Loader />
                )}
              </Grid>
              <KeyboardArrowDown style={{ color: '#2559C3' }} />
            </Box>
          </ClickAwayListener>
          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            <MenuItem component={Link} to="/profile" onClick={handleClose}>
              <ListItemIcon style={{ minWidth: 30 }}>
                <Person fontSize="small" />
              </ListItemIcon>
              My Profile
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <ListItemIcon style={{ minWidth: 30 }}>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
          <ConfirmationModal open={showConfirmation} onClose={handleModalClose} onConfirmLogout={handleConfirmLogout} />
        </Grid>
      </Grid>
    </AppBar>
  );
};
export default Header;
