import React, { useState } from 'react';
import ImageViewer from 'react-simple-image-viewer';
import { Viewer, Worker } from '@react-pdf-viewer/core';
// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import CloseIcon from '@mui/icons-material/Close';
import { ViewImage } from './imagePreview.styled';

// Create new plugin instance

const ImagePreview = (data: any) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const { type, url, setshowBuArtifact } = data;
  const images = [];
  if (type !== 'pdf') {
    images.push(url);
  }
  const [isViewerOpen, setIsViewerOpen] = useState(true);
  const closeImageViewer = () => {
    setIsViewerOpen(false);
    setshowBuArtifact(false);
  };

  return (
    type !== 'pdf' ? (
      <ViewImage className="image-preview">
        <CloseIcon onClick={closeImageViewer} />
        {isViewerOpen && (
          <ImageViewer
            src={images}
            currentIndex={0}
            disableScroll={false}
            closeOnClickOutside
            onClose={closeImageViewer}
            backgroundStyle={{ backgroundColor: 'rgb(231 229 229 / 0.5%)' }}
            closeComponent={null}
          />
        )}
      </ViewImage>
    ) : ((
      <div>
        {/* <Grid container justifyContent="flex-end">
            <Button style={{ color: 'black' }} onClick={toggleViewer}><CloseIcon /></Button>
          </Grid> */}
        <CloseIcon onClick={closeImageViewer} style={{ position: 'absolute', right: '6px', top: '5px' }} />
        {(
          <div style={{ padding: '30px' }}>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
              <Viewer
                fileUrl={url}
                plugins={[
                  // Register plugins
                  defaultLayoutPluginInstance as any,
                ]}
              />
              {' '}
            </Worker>
          </div>
        )}
      </div>
    )
    )
  );
};
export default ImagePreview;
