import * as React from 'react';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ISubProgressBarsProps } from './types';

const CircularProgressWithLabel = (props: CircularProgressProps | ISubProgressBarsProps) => {
  const {
    styleObject = {},
    value,
  } = props;
  const {
    superPosition,
    superDisplay,
    subTop,
    subLeft = 0,
    subBottom = 0,
    subRight = 0,
    subPosition = 'absolute',
    subDisplay = 'flex',
    subAlignItems = 'center',
    subJustifyContent = 'center',
    color = 'text.secondary',
  } = styleObject;

  return (
    <Box sx={{ position: superPosition || 'relative', display: superDisplay || 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: subTop || 0,
          left: subLeft || 0,
          bottom: subBottom || 0,
          right: subRight || 0,
          position: subPosition || 'absolute',
          display: subDisplay || 'flex',
          alignItems: subAlignItems || 'center',
          justifyContent: subJustifyContent || 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color={color || 'text.secondary'}
        >
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

export default CircularProgressWithLabel;
