import styled from 'styled-components';
const AddProductlevelFormWrapper = styled.div`
.header-left-section{
    display: flex;
    align-items: center;
    gap: 12px;
}
.artifact-header-section{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    padding: 24px 12px;
    background: #f7f7f7
}
.form-title{
    font-size: 20px;
    font-weight: 700;
}
.form-duration-wrapper{
    width: 50%;
    display: flex;
    justify-content: space-between;
}
.form-field-stack-wrapper{
    align-items: center;
}
.artifact-Level-section{
    background: #EDF2FD;
    padding: 24px 20px;
}
.level-section{
    padding: 20px;
}
.title{
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    font-weight: 600;
}
.level{
    background: white;
    border-radius: 5px;
    border: 1px solid #848282;
    padding: 14px 16px;
    width: 275px;
    margin-top: 8px;
    font-size: 16px;
    font-weight: 700;
}
.add-product-level-artifact-container {
    display: flex;
    align-items: center;
    height: 123px;
    background: #EDF2FD;
  }
  
  .add-product-level-artifact-label {
    display: flex;
    flex-direction: column;
    margin-left: 28px;
    margin-top: 15px;
  }
  
  .add-product-level-artifact-btn {
    margin-top: 12px;
  }
  
  .add-product-level-artifact-form-row {
    display: flex;
    justify-content: space-between;
    border: 2px solid #D3D3D3;
    border-radius: 5px;
    padding: 24px 8px;
  }
  .flex-row-container {
    display: flex;
  }
  .flex-column-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    word-break: break-all;
    .css-1x3orcg-MuiInputBase-root-MuiOutlinedInput-root{
      height:49px;
    }

    .css-1s1j41f-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
      height:49px;
    }

    .css-1swsamx-MuiAutocomplete-root .MuiOutlinedInput-root{
      height: 49px;
    }
  }
  
  .margin-top-20 {
    margin-top: 200px;
  }
  .textarea-autosize {
    width: 100%;
    border-radius: 6px;
    border: 1px solid gray;
  }

  .add-artifact-wrapper label {
    margin-bottom: 8px;
    color: #000;
  }
  .add-artifact-wrapper {
    border: 1px solid #D3D3D3;
    margin-bottom: 12px
  }
  .add-artifact-wrapper textarea {
    padding: 1.25rem;
  }

  .css-zrr0it-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    background: white;
    padding: 2px 5px;
  }

  .va_m{
    vertical-align: -2px;
  }
  .artifact-form-section form fieldset{
    border-color: #848282;   
  }

  .dialog-content-product button[type='button'].MuiButton-outlinedPrimary,.dialog-content-product button[type='button'].MuiButton-containedPrimary{
    padding: 8px 20px;
    font-size: 14px;
  }
  .dialog-content-product button[type='button'].MuiButton-outlinedPrimary:not([disabled]){
    border-color: #2559C3;
    color: #2559C3;  
  }
  .add-artifact-wrapper .form-duration-wrapper,.add-artifact-wrapper .form-duration-wrapper .MuiFormControl-root{
    flex: 0 0 100%;
    width: 100%;
  }
  .add-artifact-wrapper .form-duration-wrapper .flex-column-container,.duration_block{
    flex:0 0 calc(50% - 10px); 
  }
  .level-section .flex-column-container:last-child .MuiButton-containedPrimary{ 
    color: #ffffff;          
  }
  .MuiBox-root.css-uczorc{
    height: auto;
  }
  .upload_box>.MuiBox-root{
    height: auto;
  }

  .artifact-details-form{
    width: 100%;
  }

  .drag-drop-wrapper{
    width: 100%;
    height: 242px;
    padding: 24px;
    background-color: rgba(37, 89, 195, 0.05);
    border: 2px dashed rgba(37, 89, 195, 0.30);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    align-self: stretch;
  }

  .upload-files-text{
    font-family: Lato;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    margin: 0 !important;
    align-items: center;
  }
  
  .upload-files-icon{
    width: 64px;
    height: 64px;
    padding-top:10px;
  }
  .ck-editor__editable_inline {
    min-height: 150px;
  }
  
`;
export { AddProductlevelFormWrapper };
