import React, {
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
} from 'react';
import {
  TextField,
  InputAdornment,
  Paper,
  List,
  ListItem,
  Popper,
  ListItemText,
  Grid,
  CircularProgress,
  ListItemButton,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import { config } from '../../../config';
import { CustomeAvatar } from '../../../components';
import { CommHandler } from '../../../utils';

const SearchBar = () => {
  const [search, setSearch] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [employee, setEmployee] = useState([]);
  const [loader, setLoader] = useState(false);

  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const navigate = useNavigate();
  const startSearchLimit = 3;

  const imageUrlPrefix = `${config.profileImageUrl}/200x200/`;

  const handleInputChange = (e: any) => {
    const newSearch = e.target.value;
    setSearch(newSearch);
    if (newSearch.length >= startSearchLimit) {
      setIsOpen(true);
      setLoader(true);
    } else {
      setIsOpen(false);
      setLoader(false);
    }
  };

  const closeList = () => {
    setIsOpen(false);
    setLoader(false);
  };

  const handleProfile = (item: any) => {
    navigate('/profile', { state: item.user_id });
    setIsOpen(false);
    setLoader(false);
    setSearch('');
  };

  const renderUserList = () => {
    if (loader) {
      return (
        <div style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        >
          <CircularProgress size={20} color="inherit" />
        </div>
      );
    }

    if (employee.length === 0) {
      return <ListItem>No user found</ListItem>;
    }

    return employee.map((item: any) => (
      <ListItem
        key={item.employeeID}
        onClick={() => handleProfile(item)}
        style={{
          marginRight: '5px',
          fontSize: '21px',
          cursor: 'pointer',
          transition: 'background-color 0.3s',
          padding: '0 5px',
          backgroundColor: '#f8f8f8',
        }}
      >
        <ListItemButton sx={{ padding: 1, margin: 0 }}>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
          }}
          >
            <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
              <Grid mr={1}>
                <CustomeAvatar imageUrl={`${imageUrlPrefix}${item?.imagePath}`} customesx={{ width: 56, height: 56 }} />
              </Grid>
            </div>
            <div>
              <ListItemText primary={`${item.fullName}`} />
              <ListItemText primary={`${item.designation}`} sx={{ fontSize: '8px', color: 'grey' }} />
              <ListItemText primary={`${item.employeeID}`} sx={{ fontSize: '8px', color: 'grey' }} />
              <ListItemText primary={`${item.department}`} sx={{ fontSize: '8px', color: 'grey' }} />
            </div>
          </div>
        </ListItemButton>
      </ListItem>
    ));
  };

  useEffect(() => {
    let timer: any;
    if (search.length >= startSearchLimit) {
      setLoader(true);
      // Add setTimout for debouncing
      timer = setTimeout(() => {
        CommHandler.request({
          url: `${config.apiBaseUrl}/users`,
          method: 'GET',
          params: { search: search.trim(), isActive: true },
        }).then((response) => {
          setLoader(false);
          setEmployee(response.data);
        }).catch((error) => {
          setLoader(false);
          setEmployee([]);
          console.log(error);
        });
      }, 200);
    }

    // Add an event listener to the document to handle outside clicks
    document.addEventListener('click', closeList);

    // Remove the event listener when the component unmounts
    return () => {
      clearTimeout(timer);
      document.removeEventListener('click', closeList);
    };
  }, [search]);

  useLayoutEffect(() => {
    // This is what makes element visible
    // elmRefs[].current.scrollIntoView()
  }, []);

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevent the default form submission behavior
  };

  return (
    <div ref={searchInputRef} style={{ position: 'relative' }}>
      <form autoComplete="off" onSubmit={handleFormSubmit}>
        <div>
          <TextField
            id="search-bar"
            sx={{ width: '300px', marginTop: '5px' }}
            onChange={handleInputChange}
            variant="outlined"
            placeholder="Search..."
            className="search-field"
            size="small"
            value={search}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon style={{ fill: 'rgb(37, 89, 195)' }} />
                </InputAdornment>
              ),
            }}
          />
        </div>
      </form>

      <Popper
        className="pop-out"
        style={{
          marginTop: '50px', height: 'auto', maxHeight: '400px', width: '300px', overflowY: 'scroll', zIndex: 9999, cursor: 'pointer',
        }}
        open={isOpen}
        anchorEl={searchInputRef.current}
      >
        <Paper>
          <List>
            {renderUserList()}
          </List>
        </Paper>
      </Popper>
    </div>
  );
};

export default SearchBar;
