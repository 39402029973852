import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface InitialState {
  list: any,
}

export interface Listkey {
  [index: number]: any;
}

const initialState: InitialState = {
  list: null,
};

const employeesArtifactSlice = createSlice({
  name: 'employeesArtifact',
  initialState,
  reducers: {
    storeEmployeeArtifactData: (state, action: PayloadAction<Listkey>) => {
      /* eslint-disable no-param-reassign */
      state.list = action.payload;
    },
  },
});

export const { storeEmployeeArtifactData } = employeesArtifactSlice.actions;

export default employeesArtifactSlice.reducer;
