import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { ICalendarProps } from './types';

const Calendar = (props: ICalendarProps) => {
  const { selectedDate, onDateChange, label } = props;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker label={label} value={selectedDate} onChange={onDateChange} />
    </LocalizationProvider>
  );
};

Calendar.defaultProps = {
  selectedDate: new Date(),
  label: 'Select Date',
};

export default Calendar;
