/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Box } from '@mui/system';
import {
  Typography, Grid, Tooltip, CircularProgress,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CommHandler } from '../../../utils';
import { config } from '../../../config';
import { BorderLinearProgress } from '../../Dashboard/components/BadgesAndTrophies/BadgesAndTrophies.styled';
import { truncateText } from '../../Artifact/components/helper';
import { getCurrentYear } from '../../../utils/utils';

interface CategoryProps {
  categoryName: string;
  name: any;
  minimumScore: string;
  label: string;
  totalPoints: number;
  categoryPoints: number;
}

const ProgressReport = ({ categoryDetails }) => {
  const [progressData, setProgressData] = useState<any[]>([]);
  const [check, setCheck] = useState(false);
  const loggedInUserId = localStorage.getItem('user_id');
  const [loading, setLoading] = useState<boolean>(true);
  const { userId } = useParams();
  const currentYear = (
    useSelector((state: any) => state.appraisalCycle.selectedYear)
       || getCurrentYear()
  )?.toString();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await CommHandler.request({
          url: `${config.apiBaseUrl}/artifactPoints/score?userId=${
            userId || loggedInUserId
          }`,
          method: 'GET',
          params: {
            year: currentYear,
            status: 1,
          },
        });
        if (response?.status === 404) {
          setCheck(true);
        }
        if (response?.data) {
          setProgressData(response?.data);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error('Error fetching rank:', error);
      } finally {
        // setLoading(false);
      }
    };
    fetchData();
  }, [loggedInUserId, userId, currentYear]);

  const category = (records: CategoryProps) => {
    const truncatedLabel = truncateText(
      check ? records?.name : records?.categoryName,
      8,
    );

    const percentage = (records?.categoryPoints > Number(records?.minimumScore)
      ? Number(records?.minimumScore)
      : (records?.categoryPoints ?? 0) / Number(records.minimumScore)) * 100 || 0;
    const checkPercentage = percentage > 100 ? 100 : Math.floor(percentage);
    return (
      <Box
        className="badge-strip-wrap"
        key={check ? records?.name : records?.label}
        sx={{
          display: 'flex',
          marginBottom: '5px',
          width: '100%',
          justifyContent: 'space-evenly',
          alignItems: 'center',
        }}
      >
        <Tooltip
          title={check ? records?.name : records?.categoryName}
          arrow
        >
          <Box className="avatar-wrap">
            <Typography
              className="badge-icon-title"
              variant="body2"
              sx={{ marginRight: '12px', maxWidth: '90px' }}
            >
              {truncatedLabel}
            </Typography>
          </Box>
        </Tooltip>

        <Grid
          item
          xs
          className="borderLinearProgressWrapper"
        >
          <Grid
            container
            justifyContent="center"
          >
            <BorderLinearProgress
              variant="determinate"
              value={check ? 0 : checkPercentage}
              className="badge-progress-bar"
            />
          </Grid>
        </Grid>
        <Grid
          item
          style={{ minWidth: '135px', textAlign: 'left' }}
        >
          <Typography
            className="actual-points-text"
            variant="caption"
            style={{ margin: '0 4px' }}
          >
            {`${
              check
                ? 0
                : records?.categoryPoints
                                      > Number(records?.minimumScore)
                  ? Number(records?.minimumScore)
                  : records?.categoryPoints
            } / ${check ? 0 : records?.minimumScore} PTS `}
          </Typography>
          <Typography
            className="percentage-text"
            variant="caption"
            color="#7B7B7B"
          >
            {`${check ? 0 : checkPercentage} %`}
          </Typography>
        </Grid>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '24px',
        maxHeight: '450px',
        minWidth: '450px',
      }}
    >
      {loading ? (
        <CircularProgress
          style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', margin: 'auto',
          }}
          color="primary"
        />
      ) : (
        <>
          {(check ? categoryDetails || [] : progressData || []).map(
            (catname) => category(catname),
          )}
        </>
      )}
    </Box>
  );
};
export default ProgressReport;
