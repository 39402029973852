import React, { useEffect, useState } from 'react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { EditorProps, EditorState } from './types';

const Editor = (props: EditorProps): JSX.Element => {
  const {
    content, styleSet, onChange, onBlur,
  } = props;
  const [state, setState] = useState<EditorState>({
    documents: [content],
    documentID: 0,
    editor: null,
  });

  const customEditor = ClassicEditor as {
    create: any;
  };
    // Custom configuration to enhance security
  const editorConfig = {
    // Content filtering to prevent JavaScript injection
    contentFilter: {
      scan: {
        // Disable specific elements and attributes prone to injection
        script: false, // Disallow <script> elements
        iframe: false, // Disallow <iframe> elements
        comments: false, // Disallow HTML comments
        styles: false, // Disallow inline styles
        attributes: ['on*', 'style'], // Disallow certain attributes
      },
      // Define elements and attributes allowed in the editor
      allowedContent: {
        // Specify the allowed elements and attributes
        elements: {
          // Example: Allow <p>, <strong>, <em>, <a>, <ul>, <ol>, <li>
          p: true,
          strong: true,
          em: true,
          a: true,
          ul: true,
          ol: true,
          li: true,
        },
        // Specify the allowed attributes for certain elements
        attributes: {
          a: ['href', 'target'],
        },
      },
    },
    // Toolbar items and other configurations as needed
    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        'link',
        'bulletedList',
        'numberedList',
        '|',
        'undo',
        'redo',
      ],
    },
    // Other CKEditor configurations as required
    removePlugins: ['EasyImage', 'ImageUpload', 'MediaEmbed', 'Table'], // Remove specified plugins
  };

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      documents: [content],
    }));
  }, [content]);

  return (
    <div style={styleSet}>
      <CKEditor
        config={editorConfig}
        editor={customEditor}
        id={state?.documentID}
        data={state?.documents[state?.documentID] ?? ''}
        watchdogConfig={{ crashNumberLimit: 10 }}
        onReady={(editor: any) => {
          setState((prevState) => ({ ...prevState, editor }));
        }}
        onBlur={onBlur}
        onChange={onChange}
      />
    </div>
  );
};
export default Editor;
