import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userReducer from '../modules/Login/userSlice';
import KRAReducer from '../modules/Profile/KRASlice';
import countWithLengthReducer from '../modules/Profile/categoryWithLength';
import liveRankingReducer from '../modules/Dashboard/components/LiveRanking/liveRankingSlice';
import EmployeeReducer from '../layouts/AllUserSlice';
import appraisalCycleReducer from '../modules/Profile/components/AppraisalCycleSlice';
import employeesArtifactReducer from '../modules/BUArtifact/employeesArtifactSlice';
import artifactReducer from '../modules/Artifact/ArtifactSlice';
import selectedStatusReducer from '../modules/Profile/selectedStatusSlice';
import selectedSubcategoryReducer from '../modules/Profile/selectedSubcategorySlice';
import selectedTabReducer from '../modules/Profile/selectedTabSlice';
import { LOGOUT_ACTION } from './actions';

const persistConfig = {
  key: 'root',
  storage,
};

const appReducer = combineReducers({
  user: userReducer,
  KRA: KRAReducer,
  liveRanking: liveRankingReducer,
  employees: EmployeeReducer,
  artifact: artifactReducer,
  employeesArtifact: employeesArtifactReducer,
  appraisalCycle: appraisalCycleReducer,
  categoryData: countWithLengthReducer,
  selectedStatus: selectedStatusReducer,
  selectedSubcategory: selectedSubcategoryReducer,
  selectedTab: selectedTabReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === LOGOUT_ACTION) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: true,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: customizedMiddleware,
});

export const persistedStore = persistStore(store);
