import styled from 'styled-components';

const KeyResponsibilityWrapper = styled.div`
    width: 100%;

    .key-responsibility-paper-wrap {
        background: white;
        width: 100%;
        border-radius: 10px;
        padding: 12px;
        margin-bottom: 50px;
        border: 1px solid #e7e7e7;
        box-shadow: none;
    }

    .key-container-wrap {
        padding-left: 15px;
        padding-bottom: 15px;
        flex-direction: column;
    }

    .key-title-wrap {
        margin-top: 10px;
        padding-left: 15px;
        margin-bottom: 20px;
    }

    .key-title-text {
        font-weight: bold;
        font-size: 22px;
    }

    .key-icon-text-wrapper {
        display: flex;
        align-items: center;
    }

    .key-icon-wrap {
        width: 44.5px;
        height: 44.5px;
        background-color: #lightgray;
    }

    .key-icon {
        font-size: 24px;
        color: white;
    }

    .key-data-text {
        font-family: Lato;
        font-weight: 600;
        font-size: 16px;
        color: #7b7b7b;
        text-align: justify;
        word-break: break-word;
    }

    .key-no-data-text {
        padding-top: 16px;
        margin-left: 50px;
        font-size: 20px;
    }

    .key-response-wrapper {
        padding-bottom: 12px;
    }
`;
export { KeyResponsibilityWrapper };
