import React from 'react';
import { CircularProgress, Stack } from '@mui/material';

// import LoaderWrapper from './LoaderWrapper';
import { LoaderProps } from './types';

const Loader = (props: LoaderProps) => {
  const {
    color,
    ...progressDefaultProps
  } = props;

  return (
    <Stack display="flex" justifyContent="center" alignItems="center">
      <CircularProgress {...progressDefaultProps} />
    </Stack>
  );
};

export default Loader;
