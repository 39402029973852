import styled from 'styled-components';
const ViewImage = styled.div`
  img {
    background: #ffffff;
  }
  .MuiSvgIcon-root {
    position: absolute;
    color: #000;
    font-size: 40px;
    top: 25px;
    right: 48px;
    z-index: 122;
    cursor: pointer;
  }
`;
export { ViewImage };
