import React from 'react';
import {
  Badge as UIBadge,
} from '@mui/material';
import { BadgeProp } from './types';

const Badge = (props: BadgeProp) => {
  const {
    customClasses = {},
    vertical,
    horizontal,
    children,
    sx,
  } = props;
  return (
    <UIBadge
      classes={{ ...customClasses }}
      anchorOrigin={{
        vertical,
        horizontal,
      }}
      sx={sx}
      {...props}
    >
      {children}
    </UIBadge>
  );
};

Badge.defaultProps = {
  horizontal: 'right',
  vertical: 'top',
  badgeContent: 2,
  max: 10,
  children: 'New Badge',
  overlap: 'rectangular',
  color: 'primary',
  variant: 'standard',
};

export default Badge;
