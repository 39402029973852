import React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';

import { IErrorProps } from './types';

const Error = (props: IErrorProps) => {
  const {
    customClasses = {},
    width,
    heading,
    message,
    messageInBold,
    alertColor,
    variant,
  } = props;

  return (
    <Stack
      sx={{ width: `${width > 100 ? 100 : width}%` }}
      classes={{ ...customClasses }}
    >
      <Alert variant={variant} severity={alertColor}>
        <AlertTitle>{heading}</AlertTitle>
        {message}
        <strong>{messageInBold}</strong>
      </Alert>
    </Stack>
  );
};

Error.defaultProps = {
  width: '30',
  heading: 'Error',
  message: 'This is an error alert — ',
  messageInBold: 'check it out!',
  alertColor: 'error',
};

export default Error;
