/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import DialogContent from '@mui/material/DialogContent';
import { useSelector } from 'react-redux';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import {
  Dialog, Button, Accordion, AccordionDetails, Typography, Backdrop, CircularProgress,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  ArtifactAttachmentGrid,
  ArtifactDetailGrid, ArtifactNameGrid, CategoryHeader, SubCategoryGrid,
} from './ViewAllArtifact.styled';
import { config } from '../../../config';
import { CommHandler } from '../../../utils';
import ImagePreview from './ImagePreview';

const ViewAllArtifact = () => {
  const { categoryDataWithCount: categoryData } = useSelector((state: any) => state?.categoryData);
  const [fileUrl, setfileUrl] = useState('');
  const [fileType, setfileType] = useState('');
  const [showBuArtifact, setshowBuArtifact] = useState(false);
  const [showAllAttachments, setShowAllAttachments] = useState({});
  const [loading, setLoading] = useState(false);

  const boldTypography = (label, value) => (
    <>
      <strong>
        {label}
        &nbsp;
        :
        &nbsp;
      </strong>
      <span>
        <span>
          {value}
        </span>
      </span>
    </>
  );

  const handleURLSelect = async (item) => {
    try {
      setLoading(true);
      const response = await CommHandler.get(`${config.apiBaseUrl}/uploadFiles/get-signed-url`, {
        params: { key: item },
      });
      const signedUrl = response;
      if (signedUrl) {
        const str = `${config.s3URL}${config.permanentBucket}/${item}`;
        const result = str?.substring(str.length - 3);
        setfileUrl(str);
        setfileType(result);
        setshowBuArtifact(true);
      } else {
        console.error('Signed URL is undefined');
      }
    } catch (error) {
      console.error('Error handling file select:', error.message);
    } finally {
      setLoading(false);
    }
  };

  const toggleAttachments = (index) => {
    setShowAllAttachments((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <DialogContent>
      <h1 style={{ textAlign: 'center' }}>ARTIFACT REPORT</h1>
      {categoryData?.map((x) => (
        x.totalSubcategoryLength > 0 ? (
          <Accordion key={x.categoryName}>
            <CategoryHeader expandIcon={<ExpandMoreIcon />}>
              <Typography style={{ fontSize: 17 }}>
                {boldTypography('Category Name', x?.categoryName)}
              </Typography>
            </CategoryHeader>
            <AccordionDetails>
              {x.categoryData?.map((y) => (
                <Accordion key={y.subCategoryName}>
                  <SubCategoryGrid expandIcon={<ExpandMoreIcon />}>
                    <Typography style={{ fontSize: 16 }}>
                      {boldTypography('Artifact Subcategory ', y?.subCategoryName)}
                    </Typography>
                  </SubCategoryGrid>
                  <AccordionDetails>
                    {y.subCategoryData?.map((z, artIndex) => (
                      <Accordion key={z.name}>
                        <ArtifactNameGrid expandIcon={<ExpandMoreIcon />}>
                          <Typography style={{ fontSize: 16 }}>
                            {boldTypography('Artifact Name', z?.name)}
                          </Typography>
                        </ArtifactNameGrid>
                        <Grid style={{ backgroundColor: 'rgba(0, 0, 0, 0.06)' }}>
                          <AccordionDetails>
                            <ArtifactDetailGrid>
                              {boldTypography('Points', z?.achieved_points)}
                            </ArtifactDetailGrid>
                            {z?.selectedlineItem && (
                              <ArtifactDetailGrid>
                                {boldTypography('Line Item', z.selectedlineItem)}
                              </ArtifactDetailGrid>
                            )}
                            <ArtifactDetailGrid>
                              {boldTypography(z.approved === 'rejected' ? 'Rejected By' : 'Approved By', z?.approved_by)}
                            </ArtifactDetailGrid>
                            <ArtifactDetailGrid>
                              {boldTypography('Approver List', z?.approverList?.map((approver) => approver?.name).join(', '))}
                            </ArtifactDetailGrid>
                            {x.categoryName === 'Squad' && z?.project_name && (
                              <ArtifactDetailGrid>
                                {boldTypography('Project Name', z?.project_name)}
                              </ArtifactDetailGrid>
                            )}
                            {x.categoryName === 'Squad' && (
                              <ArtifactDetailGrid>
                                {boldTypography('URL', <a href={z?.url}>{z?.url}</a>)}
                              </ArtifactDetailGrid>
                            )}
                            {x.categoryName === 'Project' && (
                              <>
                                <ArtifactDetailGrid>
                                  {boldTypography('Project Name', z?.project_name)}
                                </ArtifactDetailGrid>
                                <ArtifactDetailGrid>
                                  {boldTypography('Project Manager', z?.project_manager?.map((id) => id.name).join(', '))}
                                </ArtifactDetailGrid>
                                <ArtifactDetailGrid>
                                  {boldTypography('Currently Working on this Project', z?.project_working_status ? ' Yes' : ' No')}
                                </ArtifactDetailGrid>
                                <ArtifactDetailGrid>
                                  {boldTypography('Start Date', z?.from_date)}
                                </ArtifactDetailGrid>
                                {z.project_working_status === false && (
                                  <ArtifactDetailGrid>
                                    {boldTypography('End Date', z?.to_date)}
                                  </ArtifactDetailGrid>
                                )}
                              </>
                            )}
                            <ArtifactDetailGrid>
                              {boldTypography('Description', z?.description.slice(3, -4))}
                            </ArtifactDetailGrid>
                            <ArtifactAttachmentGrid>
                              {boldTypography('Attachments', '')}
                              <Grid style={{ display: 'flex' }}>
                                {z?.file_path?.slice(0, showAllAttachments[artIndex] ? z.file_path.length : 1).map((file) => {
                                  const fileTyp = file.split('.');
                                  return (
                                    <div className="viewArtifactBtns" key={file}>
                                      <div>
                                        <div className="preview-image-add">
                                          {fileTyp.includes('pdf') ? (
                                            <PictureAsPdfIcon
                                              style={{
                                                height: '70px',
                                                width: 'auto',
                                                color: '#5788d7',
                                                margin: '3px',
                                              }}
                                            />
                                          ) : (
                                            <img
                                              src={`${config.s3URL}${config.permanentBucket}/${file}`}
                                              alt="Attachment"
                                              style={{
                                                width: 'auto',
                                                height: '70px',
                                                margin: '4px',
                                              }}
                                            />
                                          )}
                                          <VisibilityOutlinedIcon className="view-icon" sx={{ color: '#ffffff', cursor: 'pointer' }} onClick={() => handleURLSelect(file)} />
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                                {z?.file_path?.length > 1 && (
                                  <Button onClick={() => toggleAttachments(artIndex)}>
                                    {showAllAttachments[artIndex] ? 'Show Less' : `+${z.file_path.length - 1} more`}
                                  </Button>
                                )}
                              </Grid>
                            </ArtifactAttachmentGrid>
                          </AccordionDetails>
                        </Grid>
                      </Accordion>
                    ))}
                  </AccordionDetails>
                </Accordion>
              ))}
            </AccordionDetails>
          </Accordion>
        ) : null
      ))}
      <Dialog
        open={showBuArtifact}
        onClose={() => setshowBuArtifact(false)}
        PaperProps={{
          style: { width: '80%', maxWidth: 'none' },
        }}
      >
        <ImagePreview type={fileType} url={fileUrl} setshowBuArtifact={setshowBuArtifact} />
      </Dialog>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </DialogContent>

  );
};
export default ViewAllArtifact;
