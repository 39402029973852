import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CommHandler } from '../utils';
import { config } from '../config';
import { fetchEmployees } from '../layouts/AllUserSlice';

const useFetchAllEmployeeArtifact = () => {
  const dispatch = useDispatch();
  const list = useSelector((state: any) => state.employees.list);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await CommHandler.request({
          url: `${config.apiBaseUrl}/users`,
          method: 'GET',
          params: { isActive: true },
        });
        dispatch(fetchEmployees(response.data));
      } catch (error) {
        console.error('Error fetching rank:', error);
      }
    };

    if (!list.length) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useFetchAllEmployeeArtifact;
