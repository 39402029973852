import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  categoryDataWithCount: [],
};

const categoryDataWithCount = createSlice({
  name: 'categoryDataWithCount',
  initialState,
  reducers: {
    fetchCategoryData: (state, action:PayloadAction<any>) => {
      // eslint-disable-next-line no-param-reassign
      state.categoryDataWithCount = action.payload;
    },
  },
});

export const { fetchCategoryData } = categoryDataWithCount.actions;
export default categoryDataWithCount.reducer;
