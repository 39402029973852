import { createSlice, PayloadAction } from '@reduxjs/toolkit';
export interface User {
  employeeID: string;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
}
interface InitialState {
  list: User[];
}
const initialState: InitialState = {
  list: [],
};

const EmployeeSlice = createSlice({
  name: 'Employee',
  initialState,
  reducers: {
    fetchEmployees: (state, action: PayloadAction<User[]>) => {
      // eslint-disable-next-line no-param-reassign
      state.list = action.payload;
    },
  },
});
export const { fetchEmployees } = EmployeeSlice.actions;
export default EmployeeSlice.reducer;
