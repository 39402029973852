/* eslint-disable no-prototype-builtins */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-shadow */

import React, { useEffect, useState } from 'react';
import {
  FormLabel, Stack, Typography, Tooltip, Button, Box, CircularProgress, CardContent, ListItem, ListItemAvatar, ListItemText, Alert, Dialog, TextField,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useNavigate, useParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import InputLabel from '@mui/material/InputLabel';
import axios from 'axios';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import { AddProductlevelFormWrapper } from './addProductlevelForm.styled';
import {
  FormInput, Dropdown, RadioButtons, Editor, Loader,
} from '../../../components';
import {
  formatDate, isNil, getAuthHeader,
} from '../../../utils/utils';
import { ProjectData } from './type';
import { CommHandler } from '../../../utils';
import { config } from '../../../config';
import ImagePreview from './ImagePreview';
import { cardContentStyle } from '../Artifact.styled';
import { MessageAlert } from '../../../components/MessageAlert';
import { ToastProps } from '../../../components/Toast/types';
import { validateFields } from './validate';
import MenuAppraisalCycle from '../../Profile/components/MenuAppraisalCycle';
const vectorImage = <img src="/Vector.png" alt="vector_img" className="va_m" />;

const EditArtifactForm = () => {
  const projectLevelDataInterface: ProjectData = {
    artifact_category_id: '',
    artifact_sub_category_id: '',
    artifact_item_id: '',
    name: '',
    file_path: [],
    user_id: '',
    approved_by: null,
    year: '',
    from_date: '',
    to_date: '',
    project_manager_id: null,
    description: '',
    is_draft: false,
    project_id: '',
    project_manager: [],
    project_working_status: false,
    achieved_points: 0,
    project_name: '',
    files: [],
    type: '',
    selectedlineItem: '',
    signedUrls: [],
    url: '',
  };

  const [data, setData] = useState<ProjectData>(projectLevelDataInterface);
  const [isShow, setisShow] = React.useState(false);
  const [lineItemLabel, setLineItemLabel] = useState('');
  const [validationMessage, setValidationMessage] = useState<ProjectData>(
    projectLevelDataInterface,
  );
  const [subCategoryList, setSubCategoryList] = useState<any>([]);
  const [files, setFiles] = useState<any[]>([]);
  const [categoryName, setCategoryName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [subScore, setsubScore] = useState();
  const [lineItemScore, setlineItemScore] = useState(null);
  const [lineItem, setlineItem] = useState<any[]>([]);
  const [instructions, setInstructions] = useState('');
  const [subCategories, setSubCategories] = useState<[]>([]);
  const [fileUrl, setfileUrl] = useState('');
  const [fileType, setfileType] = useState('');
  const [showBuArtifact, setshowBuArtifact] = useState(false);
  const [showAlertMessage, setShowAlertMessage] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertHeader, setAlertHeader] = useState('');
  const [alertType, setAlertType] = useState<'success' | 'info' | 'warning'>('success');
  const [projectOptions, setProjectOptions] = useState([]);
  const [, setOpenfromDate] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [, setOpenToDate] = useState(false);
  const [projectId, setProjectId] = useState('');
  const [fileDetails, setFileDetails] = useState([]);
  const [hasLineItemError, setHasLineItemError] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const navigate = useNavigate();
  const [, setToastMsg] = useState<ToastProps>({
    open: false,
    autoHideDuration: 9000,
    message: '',
    vertical: 'top',
    horizontal: 'center',
    severity: '',
  });

  const { id: artifactFormId, categoryId } = useParams();

  // prepare project manager name list

  const formContainerStyles = {
    display: 'flex',
    alignItems: 'center',
    background: '',
    marginTop: '30px',
    marginLeft: '0',
  };

  const [selectedYear, setSelectedYear] = useState<string | null>();
  const handleYearSelect = (year) => {
    setSelectedYear(year);
  };

  const department = getAuthHeader['x-department'];
  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      CommHandler.request({
        url: `${config.apiBaseUrl}/artifact/getArtifact`,
        method: 'POST',
        data: { id: artifactFormId },
      })
        .then(async (response: any) => {
          const {
            __v: v,
            _id: d,
            createdAt,
            ...filteredData
          } = response ? response.data[0] : [];
          setSelectedYear(filteredData?.year?.toString());
          setData(filteredData);
          setProjectId(filteredData.project_id);
          setLineItemLabel(response?.data?.selectedlineItem);
          setlineItemScore(response?.data?.achieved_points);
          setFromDate(dayjs(response?.data?.from_date));
          setEndDate(dayjs(response?.data?.to_date));
          const [categoryListResponse] = await Promise.all([
            CommHandler.request({
              url: `${config.adminApiURL}/artifact/category`,
              method: 'GET',
            }),
          ]);
          const catName: any = categoryListResponse.data?.find(
            (cat: any) => cat.id === response.data[0]?.artifact_category_id,
          );

          setCategoryName(catName?.code);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchData();
  }, [artifactFormId]);

  useEffect(() => {
    async function fetchSubCategoryBasedOnCategoryId() {
      try {
        // Check if data and data.artifact_category_id are truthy
        if (data?.artifact_category_id) {
          const subCategoriesResponse = await CommHandler.request({
            url: `${config.adminApiURL}/artifact/category/${data.artifact_category_id}`,
            method: 'GET',
          });

          const reqData = await subCategoriesResponse?.data?.subCategory?.map((subCategory: any, indexId: any) => ({
            id: indexId + 1,
            label: subCategory.name,
            optionValue: subCategory.id,
          }));
          setSubCategoryList(reqData);
        }
      } catch (err) {
        // Handle error if needed
        console.error(err);
      }
    }

    // Only fetch data if data.artifact_category_id is present
    if (data?.artifact_category_id) {
      fetchSubCategoryBasedOnCategoryId();
    }
  }, [data.artifact_category_id]);
  // handle validation
  const handleValidation = (feild: string, value: string) => {
    if (value === null || value.trim() === '') {
      setValidationMessage((prevMessages) => ({
        ...prevMessages,
        [feild]: 'This field is required',
      }));
    } else {
      setValidationMessage((prevMessages) => ({
        ...prevMessages,
        [feild]: '',
      }));
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await CommHandler.request({
          url: `${config.adminApiURL}/artifact/subcategory`,
          method: 'GET',
          params: { status: 1 },
        });
        const reqData = await response?.data;
        const subData = reqData.filter((item) => {
          if (item?.businessUnit?.length > 0) {
            return (
              item?.artifact_category_id === categoryId
              && (item?.businessUnit?.some((id) => id?.name === department))
            );
          }
          return (
            item?.artifact_category_id === categoryId
          );
        });
        setSubCategories(subData);
      } catch (err) {
        setSubCategories([]); // Update with default value if fetch fails
      }
    }
    fetchData();
  }, [data.artifact_sub_category_id, categoryId, department]);
  const options = subCategories?.map((subcategory: any, indexId) => ({
    id: indexId + 1,
    label: subcategory.name,
    optionValue: subcategory._id,
  }));

  useEffect(() => {
    setInstructions('');
    subCategories.map((itm: any) => {
      if (data.artifact_sub_category_id === itm?._id) {
        setisShow(true);

        if (itm.line_item?.length !== 0) {
          const options = itm.line_item?.map(
            (item: any, indexId: any) => ({
              id: indexId + 1,
              label: item.name,
              optionValue: item.name,
              score: item.score,
            }),
          );
          setlineItem(options);
          // setIsAchievedPointAdded(true);
        } else {
          setsubScore(itm.sub_category_score);
        }
        if (itm.instructions) {
          // const strippedInstructions = itm.instructions.replace(
          //   /<[^>]*>/g,
          //   '',
          // );
          setInstructions(itm.instructions);
        } else {
          setInstructions('');
        }
        return false;
      }
      return {};
    });
  }, [subCategories, data.artifact_sub_category_id]);

  const handleURLSelect = (filename: any, type: string = 'PERMANENT') => {
    const bucket = type === 'PERMANENT' ? config.permanentBucket : config.tempBucket;
    const str = `${config.s3URL}${bucket}/${filename}`;
    const result = str?.substring(str.length - 3);
    setfileUrl(str);
    setfileType(result);
    setshowBuArtifact(true);
  };

  // Handle input value
  const handleChange = (key: string, value: any) => {
    if (value !== undefined && value !== null && value !== '') {
      setValidationMessage((prevMessages) => ({
        ...prevMessages,
        [key]: '',
      }));
    }

    if (key === 'achieved_points') {
      const filtelineitem = lineItem.filter(
        (element) => element.label === value,
      );
      setlineItemScore(filtelineitem[0]?.score);
      setLineItemLabel(value);
      if (categoryName === 'SQU') {
        setData((prevData: any) => ({
          ...prevData,
          selectedlineItem: value,
          project_name: '',
          achieved_points: filtelineitem[0]?.score,

        }));
      } else {
        setData((prevData: any) => ({
          ...prevData,
          selectedlineItem: value,
          achieved_points: filtelineitem[0]?.score,
        }));
      }
      setHasLineItemError(false);
    }
    if (key === 'artifact_sub_category_id') {
      setInstructions('');
      const subData = subCategories.filter(
        (item: any) => item._id === value,
      );
      if (subData[0] && (subData[0] as { line_item: any }).line_item?.length > 0) {
        setlineItemScore(null);
        setisShow(true);
        setData((prevData: any) => ({
          ...prevData,
          selectedlineItem: '',
          achieved_points: '',
        }));
        const options = subData[0] && (subData[0] as { line_item: any }).line_item?.map((item: any, indexId: any) => ({
          id: indexId + 1,
          label: item.name,
          optionValue: item.name,
          score: item.score,
        }));
        setlineItem(options);
        setHasLineItemError(true);
      } else {
        setlineItem([]);
        setsubScore(subData[0] && (subData[0] as { sub_category_score: any }).sub_category_score);
        setData((prevData: any) => ({
          ...prevData,
          selectedlineItem: '',
          achieved_points: subData[0] && (subData[0] as { sub_category_score: any }).sub_category_score,
        }));
        setHasLineItemError(false);
        setValidationMessage((prevMessages: any) => ({
          ...prevMessages,
          selectedlineItem: '',
        }));
      }
      if (subData[0] && (subData[0] as { instructions: any }).instructions) {
        setInstructions(subData[0] && (subData[0] as { instructions: any }).instructions);
      }
    }
    if (key === 'project_working_status') {
      if (value === 'true') {
        setData((prevData: any) => ({
          ...prevData,
          to_date: '',
        }));
      }
    }
    if (key !== 'achieved_points') {
      setData((prevData: any) => ({
        ...prevData,
        [key]: value,
      }));
    }
  };
  const handleDateChange = (key: string, date: number) => {
    setValidationMessage((prevMessages) => ({
      ...prevMessages,
      [key]: '',
    }));

    setData((prevData: any) => ({
      ...prevData,
      [key]: formatDate(date),
    }));
  };
  const fileInputReference = React.useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (fileInputReference.current) {
      fileInputReference.current.click();
    }
  };
  const handleUpload = async (files) => {
    try {
      const response = await CommHandler.post(`${config.apiBaseUrl}/uploadFiles/signedUrl`, { files });
      const presignedUrls = response?.data;

      await Promise.all(presignedUrls.map(async ({ url, fileName }, index) => {
        const file = files[index]?.fileObject;
        // eslint-disable-next-line no-param-reassign
        files[index].fileName = fileName;
        await axios.put(url, file, {
          headers: {
            'Content-Type': file.type,
          },
        });
      }));
      const filePath = presignedUrls.map((item: any) => (
        item.fileName
      ));
      setFileDetails((prev) => [...prev, ...filePath]);
      return files;
    } catch (e) {
      console.error(`Error while uploading file: ${e.message}`);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles: any) => {
      setValidationMessage((prevMessages: any) => ({
        ...prevMessages,
        fileError: '',
      }));
      setIsFileLoading(true);
      const invalid = acceptedFiles.filter(
        (file: any) => file.size > 5242880,
      );
      const invalidFileTypes = acceptedFiles.filter(
        (file: any) => ![
          'image/png',
          'image/jpeg',
          'image.jpg',
          'application/pdf',
        ].includes(file.type),
      );
      const checkForInvalidExtensions = acceptedFiles.filter(
        (file) => file.name.split('.')?.length > 2,
      );
      if (checkForInvalidExtensions?.length > 0) {
        setValidationMessage((prevMessages: any) => ({
          ...prevMessages,
          fileError: 'File(s) with invalid extensions',
        }));
        setIsFileLoading(false);
      } else if (invalid?.length) {
        setValidationMessage((prevMessages: any) => ({
          ...prevMessages,
          fileError: 'File Size cannot be more than 5MB',
        }));
        setIsFileLoading(false);
      } else if ((data.file_path.length + files.length + acceptedFiles.length) > 5) {
        setValidationMessage((prevMessages: any) => ({
          ...prevMessages,
          fileError: 'You cannot upload more than 5 Files',
        }));
        setIsFileLoading(false);
      } else if (
        acceptedFiles.some((obj1: any) => data.files.some((obj2: any) => obj1.name === obj2.name))
      ) {
        setValidationMessage((prevMessages: any) => ({
          ...prevMessages,
          fileError: '1 or more files already exists',
        }));
        setIsFileLoading(false);
      } else if (invalidFileTypes?.length) {
        setValidationMessage((prevMessages: any) => ({
          ...prevMessages,
          fileError:
            'Invalid file(s). File type can only be image or pdf',
        }));
        setIsFileLoading(false);
      } else {
        setValidationMessage((prevMessages: any) => ({
          ...prevMessages,
          fileError: '',
        }));
        const allFile = acceptedFiles.map((file: any) => ({
          name: file.name,
          type: file.type,
          size: file.size,
          fileObject: file,
        }));
        const existfile = [...files];
        setFiles((existing: any) => [...existing, ...allFile]);
        const newfile = await handleUpload(allFile);
        const fileList = data?.files?.concat(newfile);
        setFiles([...existfile, ...newfile]);
        setData((prevData: any) => ({
          ...prevData,
          files: fileList,
        }));
        setIsFileLoading(false);
      }
    },
  });
  const removeElement = (i: any) => {
    const filteredFiles = data?.file_path?.filter((file: any) => file !== i);
    const filterFileList = data?.files?.filter((file: any) => file?.fileName !== i);
    setValidationMessage((prevMessages: any) => ({
      ...prevMessages,
      fileError: '',
    }));
    setData((prevData: any) => ({
      ...prevData,
      file_path: filteredFiles,
      files: filterFileList,
      signedUrls: filteredFiles,
    }));
  };
  const LineItems = () => (lineItem?.length ? (
    <>
      <div className="flex-column-container">
        <FormLabel sx={{ marginBottom: '8px', color: 'black' }}>
          Line Item*
          {' '}
          <Tooltip title="Please select a Line Item" arrow>
            {vectorImage}
          </Tooltip>
          {' '}
        </FormLabel>
        <Dropdown
          inputLabel="Select Line Item"
          options={lineItem || []}
          value={data?.selectedlineItem}
          width="100%"
          onChange={(e) => handleChange('achieved_points', e.target.value)}
        />
        {validationMessage.selectedlineItem && hasLineItemError && (
          <span style={{ color: 'red' }}>
            {validationMessage.selectedlineItem}
          </span>
        )}
      </div>
      {lineItemScore && (
        <div
          style={{
            marginLeft: '50px',
            display: 'flex',
            flexDirection: 'column',
            width: '12%',
          }}
        >
          <FormLabel className="form-label">
            Points
            {' '}
            <Tooltip title="Points" arrow>
              {vectorImage}
            </Tooltip>
            {' '}
          </FormLabel>
          <ListItem
            sx={{
              width: '80px',
              height: '40px',
              backgroundColor: '#FDF5EB',
              borderRadius: '5px',
              padding: '8px 8px 8px 8px',
              fontStyle: 'Inter',
              fontWeight: '600',
              fontSize: '16px',
              lineHeight: '19.36px',
              color: '#EDA13B',
              gap: '8px',
              marginLeft: '-4',
            }}
          >
            <ListItemAvatar sx={{ minWidth: '0px !important' }} />
            <img
              src="/Page-1.png"
              width="24"
              height="24"
              alt=""
            />
            <ListItemText className="listIem-text" primary={lineItemScore} />
          </ListItem>
        </div>
      )}
    </>
  ) : (
    <div className="flex-column-container">
      <FormLabel sx={{ marginBottom: '8px', color: 'black' }}>
        Points
        {' '}
        <Tooltip title="Points" arrow>
          {vectorImage}
        </Tooltip>
        {' '}
      </FormLabel>
      <ListItem
        sx={{
          width: '80px',
          height: '40px',
          backgroundColor: '#FDF5EB',
          borderRadius: '5px',
          padding: '8px 8px 8px 8px',
          fontStyle: 'Inter',
          fontWeight: '600',
          fontSize: '16px',
          lineHeight: '19.36px',
          color: '#EDA13B',
          gap: '8px',
          marginLeft: '0',
        }}
      >
        <ListItemAvatar sx={{ minWidth: '0px !important' }} />
        <img
          src="/Page-1.png"
          width="24"
          height="24"
          alt=""
        />
        <ListItemText className="listIem-text" primary={subScore} />
      </ListItem>

    </div>
  ));

  const removeFileElement = (i: any) => {
    const filteredFiles = files.filter((file: any) => file?.fileName !== i);
    setFiles(filteredFiles);
    const filteredFileDetails = fileDetails.filter((file: any) => file !== i);
    setFileDetails(filteredFileDetails);
    const filterFileList = data?.files?.filter((file: any) => file?.fileName !== i);

    setData((prevData: any) => ({
      ...prevData,
      files: filterFileList,
    }));
    setValidationMessage((prevMessages: any) => ({
      ...prevMessages,
      fileError: '',
    }));
  };

  const handleCloseAlert = () => {
    if (alertType === 'success') {
      navigate('/artifact-detail', { state: { tab: categoryId } });
    }
  };

  const handleShowAlert = (
    header: string,
    message: string,
    type: 'success' | 'info' | 'warning',
  ) => {
    setAlertType(type);
    setAlertHeader(header);
    setAlertMessage(message);
    setShowAlertMessage(true);
  };

  // const onDismiss = () => {
  //   setToastMsg({
  //     open: false,
  //     message: '',
  //     horizontal: 'center',
  //     vertical: 'top',
  //     severity: '',
  //   });
  // };
  // common function to hit api in draft and in submit action
  const addArtifactFormData = async (updatedFormData) => {
    await CommHandler.request({
      url: `${config.apiBaseUrl}/artifact/updateArtifact`,
      method: 'POST',
      data: { id: artifactFormId, data: updatedFormData },
    })
      .then((res) => {
        if (res.status === 200) {
          setSubmitLoading(false);
          setIsLoading(false);
          handleShowAlert(
            'Artifact Edit',
            'Artifact  Updated successfully!',
            'success',
          );
        }
      })
      .catch((err) => {
        setToastMsg({
          open: true,
          message: err.message,
          vertical: 'top',
          horizontal: 'center',
          severity: 'error',
        });
        setIsLoading(false);
      });
  };
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    let hasErrors = false;
    if (hasLineItemError) {
      setValidationMessage((prevMessages: any) => ({
        ...prevMessages,
        selectedlineItem: 'Please select line item',
      }));
      return;
    }
    if (categoryName === 'PRO') {
      if (String(data?.project_working_status) === 'false' && !data.to_date) {
        setValidationMessage((prevMessages: any) => ({
          ...prevMessages,
          to_date: 'This field is required',
        }));
        hasErrors = true;
      }
      if (data?.project_name === '') {
        setValidationMessage((prevMessages: any) => ({
          ...prevMessages,
          project_name: 'This field is required',
        }));
        hasErrors = true;
      }
      if (data?.from_date === '') {
        setValidationMessage((prevMessages: any) => ({
          ...prevMessages,
          from_date: 'This field is required',
        }));
        hasErrors = true;
      }
    }
    if (data?.description === '') {
      setValidationMessage((prevMessages: any) => ({
        ...prevMessages,
        description: 'This field is required',
      }));
      hasErrors = true;
    }
    const fileData = data?.file_path.concat(files);
    if (fileData?.length === 0) {
      setValidationMessage((prevMessages: any) => ({
        ...prevMessages,
        file_path: 'This field is required',
      }));
      hasErrors = true;
    }
    if (categoryName === 'SQU') {
      if (data.selectedlineItem === 'Project POC' && data.project_name === '') {
        setValidationMessage((prevMessages: any) => ({
          ...prevMessages,
          project_name: 'This field is required',
        }));
        hasErrors = true;
      }
      if (data.url === '') {
        setValidationMessage((prevMessages: any) => ({
          ...prevMessages,
          url: 'This field is required',
        }));
        hasErrors = true;
      }
    }
    if (hasErrors) {
      return;
    }
    setSubmitLoading(true);
    const { files: filteredFiles, signedUrls, ...filteredData } = data;
    if (fileDetails?.length > 0) {
      try {
        await CommHandler.post(
          `${config.apiBaseUrl}/uploadFiles/moveFiles`,
          { files: fileDetails },
        );

        filteredData.file_path = [
          ...data.file_path,
          ...fileDetails,
        ];
      } catch (error) {
        console.error('Error uploading file:', error);
        throw error;
      }
    }
    const updatedFormData = {
      ...data,
      ...filteredData,
      year: selectedYear,
      approved: filteredData.approved === 'draft' ? 'pending' : filteredData.approved,
    };
    const isFieldsValid = validateFields({
      artifactFormData: [{ ...data, ...filteredData }],
      isEditable: true,
      artifactTypeName: categoryName,
    });
    // await addArtifactFormData(updatedFormData);
    if (!isFieldsValid?.length) {
      await addArtifactFormData(updatedFormData);
    } else {
      setToastMsg({
        open: true, message: 'Please fill all required fields', vertical: 'top', horizontal: 'center', severity: 'error',
      });
      setSubmitLoading(false);
      setIsLoading(false);
    }
    // addArtifactFormData(updatedFormData);
  };
  // handle draft
  const handleDraft = async () => {
    const { files: filteredFiles, signedUrls, ...filteredData } = data;
    if (fileDetails?.length > 0) {
      setIsLoading(true);
      try {
        await CommHandler.post(
          `${config.apiBaseUrl}/uploadFiles/moveFiles`,
          { files: fileDetails },
        );

        filteredData.file_path = [
          ...data.file_path,
          ...fileDetails,
        ];
      } catch (error) {
        console.error('Error uploading file:', error);
        throw error;
      }
    }
    await addArtifactFormData(filteredData);
  };

  async function fetchProject() {
    try {
      const responseProject = await CommHandler.request({
        url: `${config.apiBaseUrl}/project`,
        method: 'GET',
      });
      const reqData = await responseProject?.data;
      return reqData;
    } catch (err) {
      return false;
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const project = await fetchProject();
        setProjectOptions(project);
      } catch (err) {
        console.error('Error fetching project:', err);
      }
    };

    fetchData();
  }, []);
  const onProjectNameChange = (projectName: string, projectManager: string, event: any) => {
    const { value } = event.target;
    const selectedProject = projectOptions?.find((project) => project?.projectId === value);
    const projectManagerDetails = selectedProject?.projectManager.map(({ display_name, email }: { display_name: string, email: string }) => ({
      name: display_name,
      email,
    }));
    const updatedData = {
      ...data,
      project_id: selectedProject?.projectId,
      project_name: selectedProject.projectName,
      [projectManager]: projectManagerDetails,
      selectedlineItem: lineItemLabel,
      // achieved_points: subScore,
    };
    setProjectId(value);
    setData({ ...updatedData });
  };
  const handleEditorChange = (e: any, editor: any) => {
    const editorDescription = editor?.getData();
    handleChange('description', editorDescription);
  };
  const projectOption = projectOptions && projectOptions?.map((projectName: any, indexId) => ({
    id: indexId + 1,
    label: projectName?.projectName,
    value: projectName?.id,
  }));
  const urlRegex = /^(https:\/\/)([\da-z.-]+)\.([a-z.]{2,})([/\w.-]*)*(#.*)?$/i;

  const onURLBlur = (value: string) => {
    if (!urlRegex.test(value)) {
      return setValidationMessage((prevMessages) => ({
        ...prevMessages,
        url: 'Invalid URL format',
      }));
    }
    setValidationMessage((prevMessages) => ({
      ...prevMessages,
      url: '',
    }));
  };

  const onClose = () => {
    navigate(-1);
  };

  const handleCancelConfirmation = () => {
    setShowConfirmationDialog(false);
  };

  const handleConfirmCancelConfirmation = () => {
    setShowConfirmationDialog(false);
    onClose();
  };

  const arrayWithSingleObject = [data];
  const handClickBack = () => {
    // Check if any of the form fields are filled
    const hasUnsavedChanges = arrayWithSingleObject?.some((formData) => Object.keys(formData).some(
      (key) => formData[key] !== projectLevelDataInterface[key]
          && formData[key] !== ''
          && formData[key] !== null
          && formData[key].length > 0, // Check for non-empty arrays
    ));

    if (hasUnsavedChanges) {
      setShowConfirmationDialog(true);
    } else {
      onClose();
    }
  };
  return (
    <>
      <div className="edit-artifcate-page">
        <CardContent style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
          <div onClick={handClickBack} onKeyPress={(e: { key: string }) => e.key === 'Enter' && handClickBack()} role="button" tabIndex={0}>
            <img src="/leftArrow.png" alt="left-arrow" width="16px" height="16px" style={{ marginRight: '12px', marginTop: '5px' }} />
          </div>
          {' '}
          <h2 style={cardContentStyle}>Edit Artifact</h2>
        </CardContent>
        {/* {toastmsg && (
          <Toast
            open={toastmsg.open}
            autoHideDuration={toastmsg.autoHideDuration}
            message={toastmsg.message}
            vertical={toastmsg.vertical}
            horizontal={toastmsg.horizontal}
            onClose={onDismiss}
          >
            <Alert
              onClose={onDismiss}
              severity={mapSeverityToAlertColor(
                toastmsg.severity,
              )}
              sx={{ width: '100%' }}
            >
              {toastmsg.message}
              !
            </Alert>
          </Toast>
        )} */}
        <AddProductlevelFormWrapper>
          <div className="edit_artifact-wrapper">
            <div className="artifact-header-section">
              <div className="header-left-section" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="form-title">
                  Edit Artifact
                </div>
              </div>
              <div>
                {
                  !selectedYear ? (
                    <CircularProgress
                      size={20}
                      color="inherit"
                    />

                  ) : (
                    <MenuAppraisalCycle defaultSelectedYear={selectedYear} onYearSelect={handleYearSelect} />
                  )
                }
              </div>
            </div>
            <div className="artifact-form-section">
              <Typography sx={formContainerStyles}>
                <form
                  autoComplete="off"
                  onSubmit={handleSubmit}
                  style={{
                    width: '100%',
                    padding: '20px',
                  }}
                >
                  <Stack
                    spacing={2}
                    direction="row"
                    sx={{
                      marginBottom: 4,
                      width: '100%',
                    }}
                  >
                    <div
                      className="flex-column-container"
                      style={{ width: '100%' }}
                    >
                      <FormLabel className="form-label">
                        Artifact Name*
                        {' '}
                        <Tooltip
                          title="Please enter Artifact Name  "
                          arrow
                        >
                          {vectorImage}
                        </Tooltip>
                      </FormLabel>
                      <FormInput
                        inputId="basic-input"
                        required
                        inputBoxType="outlined"
                        type="input"
                        disabled={false}
                        defaultValue={data.name}
                        InputLabelShrink={false}
                        maxLength={100}
                        onValueChange={(e) => handleChange(
                          'name',
                          e.target.value,
                        )}
                        onBlur={() => handleValidation(
                          'name',
                          data?.name,
                        )}
                      />
                      {validationMessage && (
                        <span
                          style={{ color: 'red' }}
                        >
                          {validationMessage.name}
                        </span>
                      )}
                    </div>
                    {(categoryName === 'PRO') && (
                      <div className="flex-column-container" style={{ width: '100%' }}>
                        <FormLabel className="form-label">
                          Project Name*
                          {' '}
                          <Tooltip
                            title="Please select a Project"
                            arrow
                          >
                            {vectorImage}
                          </Tooltip>
                          {' '}
                        </FormLabel>
                        {projectOption?.length > 0 && (
                          <FormControl fullWidth>
                            <Autocomplete
                              options={projectOptions}
                              getOptionLabel={(option) => option.projectName || ''}
                              value={projectOptions.find((option) => option.projectId === projectId) || null}
                              onChange={(_, newValue) => {
                                if (newValue) {
                                  onProjectNameChange('project_id', 'project_manager', { target: { value: newValue?.projectId } });
                                }
                              }}
                              renderInput={(params) => (
                                <TextField required {...params} variant="outlined" fullWidth />
                              )}
                            />
                          </FormControl>
                        )}
                        {validationMessage && (
                        <span
                          style={{ color: 'red' }}
                        >
                          {
                            validationMessage.project_name
                          }
                        </span>
                        )}
                      </div>
                    )}
                  </Stack>
                  {categoryName === 'PRO' && (
                    <Stack className="form-field-stack-wrapper" spacing={2} direction="row" sx={{ marginBottom: 2 }}>
                      <div className="flex-column-container">
                        <FormLabel className="form-label" style={{ marginBottom: '8px' }}>
                          Project Manager*
                          {' '}
                          <Tooltip title="Based on Project selected above" arrow>
                            {vectorImage}
                          </Tooltip>
                          {' '}
                        </FormLabel>
                        <FormInput
                          inputBoxType="outlined"
                          type="input"
                          disabled
                          required
                          defaultValue={data?.project_manager?.map((manager: any) => manager.name).join(', ')}
                          value={data?.project_manager?.map((manager: any) => manager.name).join(', ')}
                          maxLength={30}
                        />
                      </div>
                    </Stack>
                  )}
                  <Stack
                    spacing={2}
                    direction="row"
                    sx={{ marginBottom: 4 }}
                  >
                    <div className="flex-column-container">
                      <FormLabel
                        className="form-label"
                        style={{
                          marginBottom: '8px',
                        }}
                      >
                        Artifact Subcategory*
                        {' '}
                        <Tooltip
                          title="Please select a Subcategory "
                          arrow
                        >
                          {vectorImage}
                        </Tooltip>
                        {' '}
                      </FormLabel>
                      {subCategoryList?.length
                        > 0 && (
                          <Dropdown
                            inputLabel="Select Artifact Subcategory"
                            options={
                              options
                              || []
                            }
                            value={
                              data?.artifact_sub_category_id
                            }
                            onChange={(e: any) => handleChange(
                              'artifact_sub_category_id',
                              e.target.value,
                            )}
                            onBlur={() => handleValidation(
                              'artifact_sub_category_id',
                              data.artifact_sub_category_id,
                            )}
                            width="100%"
                          />
                      )}
                      {validationMessage && (
                        <span
                          style={{ color: 'red' }}
                        >
                          {
                            validationMessage.artifact_sub_category_id
                          }
                        </span>
                      )}
                    </div>
                    {isShow && LineItems()}
                  </Stack>
                  <Stack
                    className="form-field-stack-wrapper"
                    spacing={2}
                    direction="row"
                    sx={{ marginBottom: 2 }}
                  >
                    {instructions && (
                      <Alert sx={{ fontWeight: 700, marginTop: '24px', width: '100%' }} severity="info" className="alertBox">
                        <strong>Instructions</strong>
                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(instructions) }} />
                      </Alert>
                    )}
                  </Stack>

                  {categoryName === 'PRO' && (
                    <>
                      <Stack className="form-field-stack-wrapper" spacing={2} direction="row" sx={{ marginBottom: 2 }}>
                        <div className="flex-column-container">
                          <FormLabel className="form-label">
                            Currently Working on this Project?*
                            {' '}
                            <Tooltip title="Please specify if you are currently working on this project or not" arrow>
                              {vectorImage}
                            </Tooltip>
                            {' '}
                          </FormLabel>
                          <RadioButtons
                            isRow
                            status={data?.project_working_status}
                            onValueChange={(e) => handleChange('project_working_status', e.target.value)}
                            radioButtonLabels={[
                              {
                                label: 'Yes',
                                value: 'true',
                              },
                              {
                                label: 'No',
                                value: 'false',
                              },
                            ]}
                          />
                        </div>
                      </Stack>
                      <Stack className="form-field-stack-wrapper" spacing={2} direction="row" sx={{ marginBottom: 2, width: '100%' }}>
                        <Stack className="duration_block" spacing={2} direction="row" sx={{ marginBottom: 2, width: '100%' }}>
                          <div className="form-duration-wrapper">
                            <div className="flex-column-container">
                              <FormLabel className="form-label">
                                Your Duration*
                                {' '}
                                <Tooltip title="If currently working on this project, please select start date. If not, please select a duration" arrow>
                                  {vectorImage}
                                </Tooltip>
                                {' '}
                              </FormLabel>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <div onClick={() => setOpenfromDate(true)} onKeyPress={() => null} role="button" tabIndex={0} aria-label="Open Date Picker">
                                  <DatePicker
                                    sx={{ width: '100%' }}
                                    label="From"
                                    value={dayjs(data.from_date)}
                                    onChange={(value: any) => {
                                      setFromDate(value);
                                      handleDateChange('from_date', value);
                                      setOpenfromDate(false);
                                    }}
                                    // open={openfromDate}
                                    // onAccept={() => setOpenfromDate(false)}
                                    // onClose={() => setOpenfromDate(false)}
                                    maxDate={endDate}
                                    disableFuture
                                  />
                                </div>
                              </LocalizationProvider>
                              {validationMessage && <span style={{ color: 'red' }}>{validationMessage.from_date}</span>}
                            </div>
                            {String(data?.project_working_status) === 'false' && (
                              <div className="flex-column-container" style={{ marginTop: '26px', marginLeft: '15px' }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <div onClick={() => setOpenToDate(true)} onKeyPress={() => null} role="button" tabIndex={0} aria-label="Open Date Picker">

                                    <DatePicker
                                      sx={{ width: '100%' }}
                                      value={dayjs(data?.to_date)}
                                      label="To"
                                      onChange={(value: any) => {
                                        setEndDate(value);
                                        handleDateChange('to_date', value);
                                        setOpenToDate(false);
                                      }}
                                      // open={openToDate}
                                      // onAccept={() => setOpenToDate(false)}
                                      // onClose={() => setOpenToDate(false)}
                                      disableFuture
                                      minDate={fromDate}
                                    />

                                  </div>
                                </LocalizationProvider>
                                {validationMessage && <span style={{ color: 'red' }}>{validationMessage.to_date}</span>}
                              </div>
                            )}
                          </div>
                        </Stack>
                      </Stack>
                    </>
                  )}
                  <Stack spacing={2} direction="row" sx={{ marginBottom: 2, width: '100%' }}>
                    {categoryName === 'SQU' && (
                      <div className="flex-column-container">
                        <FormLabel className="form-label">
                          URL
                          {' '}
                          <Tooltip title="Please enter a valid URL" arrow>
                            {vectorImage}
                          </Tooltip>
                          {' '}
                        </FormLabel>
                        <FormInput
                          inputId="url-input"
                          inputBoxType="outlined"
                          type="url"
                          disabled={false}
                          maxLength={300}
                          defaultValue={data.url}
                          value={data.url}
                          InputLabelShrink
                          onValueChange={(e) => handleChange('url', e.target.value)}
                          onBlur={() => onURLBlur(data?.url)}
                        />
                        {validationMessage && <span style={{ color: 'red' }}>{validationMessage.url}</span>}
                      </div>
                    )}
                  </Stack>
                  {(categoryName === 'SQU' && lineItemLabel === 'Project POC') && (
                    <div className="flex-column-container" style={{ width: '100%' }}>
                      <FormLabel className="form-label">
                        Project Name*
                        {' '}
                        <Tooltip title="Please select a Project Name" arrow>
                          {vectorImage}
                        </Tooltip>
                        {' '}
                      </FormLabel>
                      {' '}
                      {projectOption?.length > 0 && (
                        <FormControl fullWidth>
                          <InputLabel id="project-select-label">Select Project</InputLabel>
                          <Select
                            labelId="project-select-label"
                            value={projectId}
                            label="Select Project"
                            onChange={(e) => onProjectNameChange('project_id', 'project_manager', e)}
                            onBlur={() => handleValidation('project_id', data.project_id)}
                          >
                            {projectOptions?.map((projectName) => (
                              <MenuItem value={projectName.projectId}>{projectName.projectName}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                      {validationMessage && <span style={{ color: 'red' }}>{validationMessage.project_name}</span>}
                    </div>
                  )}
                  <div className="flex-row-container" style={{ marginTop: '15px' }}>
                    <div className="flex-column-container">
                      <FormLabel
                        className="form-label"
                        style={{
                          marginBottom: '4px',
                        }}
                      >
                        Artifact Description*
                        {' '}
                        <Tooltip
                          title="Please enter Artifact Description highlighting details about the Artifact"
                          arrow
                        >
                          {vectorImage}
                        </Tooltip>
                        {' '}
                      </FormLabel>
                      <div className="ck-editor">
                        <Editor
                          content={
                            data?.description
                          }
                          onChange={
                            handleEditorChange
                          }
                          styleSet={undefined}
                          onBlur={() => handleValidation('description', data.description)}
                        />
                      </div>
                      {validationMessage && (
                        <span
                          style={{ color: 'red' }}
                        >
                          {
                            validationMessage?.description
                          }
                        </span>
                      )}
                    </div>
                  </div>
                  <div
                    className="flex-row-container"
                    style={{ marginTop: '15px' }}
                  >
                    <div
                      className="flex-column-container"
                      style={{ width: '100%' }}
                    >
                      <FormLabel
                        className="form-label"
                        style={{
                          marginBottom: '4px',
                        }}
                      >
                        Upload Document*
                        {' '}
                        <Tooltip
                          title="Please upload a supporting document. Only images and PDF formats are allowed"
                          arrow
                        >
                          {vectorImage}
                        </Tooltip>
                        {' '}
                      </FormLabel>
                      {(data.file_path?.length > 0 || files?.length > 0)
                        && (
                          <Box
                            sx={{
                              width: '100%',
                              padding: '8px 16px',
                              backgroundColor: 'rgba(200, 200, 200, 0.05)',
                              border: '2px solid rgba(166, 167, 168, 0.30)',
                              borderRadius: '6px',
                              display: 'flex',
                              alignItems: 'left',
                              gap: '24px',
                              alignSelf: 'stretch',
                              flexDirection: 'row',
                              marginBottom: '2px',
                              flexWrap: 'wrap',
                            }}
                          >
                            {data.file_path
                              && data.file_path.map(
                                (file: any) => {
                                  const filtType = file.split('.');

                                  return (
                                    <div key={file}>
                                      <div className="preview-image-add">
                                        {filtType[1] === 'pdf'
                                          ? <PictureAsPdfIcon style={{ height: '60px', width: '60px', color: '#5788d7' }} />
                                          : (
                                            <img
                                              src={`${config.s3URL}${config.permanentBucket}/${file}`}
                                              alt="Attachment"
                                              style={{
                                                width: 'auto', height: '60px',
                                              }}
                                            />
                                          )}
                                        <VisibilityOutlinedIcon className="view-icon" sx={{ color: '#ffffff', cursor: 'pointer' }} onClick={() => handleURLSelect(file, 'PERMANENT')} />
                                        <CloseIcon
                                          className="close-icon"
                                          onClick={() => removeElement(file)}
                                        />

                                      </div>
                                    </div>

                                  );
                                },
                              )}
                            {files.map(
                              (file: any) => {
                                const filtType = file.name.split('.');
                                if (isNil(file?.fileName)) {
                                  return <Loader />;
                                }

                                return (

                                  <div key={file?.fileName}>
                                    <div className="preview-image-add">
                                      {filtType[filtType.length - 1] === 'pdf'
                                        ? <PictureAsPdfIcon style={{ height: '60px', width: '60px', color: '#5788d7' }} />
                                        : (
                                          <img
                                            src={`${config.s3URL}${config.tempBucket}/${file?.fileName}`}
                                            alt="Attachment"
                                            style={{
                                              width: 'auto', height: '60px',
                                            }}
                                          />
                                        )}
                                      <VisibilityOutlinedIcon className="view-icon" sx={{ color: '#ffffff', cursor: 'pointer' }} onClick={() => handleURLSelect(file?.fileName, 'TEMP')} />
                                      <CloseIcon
                                        className="close-icon"
                                        onClick={() => removeFileElement(file?.fileName)}
                                      />

                                    </div>
                                  </div>

                                );
                              },
                            )}
                          </Box>
                        )}
                      <Box {...getRootProps()} className="drag-drop-wrapper">
                        <CloudUploadOutlinedIcon fontSize="large" className="upload-files-icon" />
                        <h2 className="upload-files-text">Drag and Drop Certificate files</h2>
                        or
                        {' '}
                        <input
                          {...getInputProps()}
                          accept="image/jpeg, image/png, application/pdf"
                        />
                        {isLoading ? (
                          <Button
                            variant="outlined"
                            disabled={
                              isFileLoading
                            }
                          >
                            <CircularProgress
                              size={20}
                              color="inherit"
                            />
                          </Button>
                        ) : (
                          <Button
                            variant="outlined"
                            onClick={
                              handleClick
                            }
                          >
                            Choose Files
                          </Button>
                        )}
                      </Box>
                      {(validationMessage.fileError || validationMessage.file_path) && (
                        <span
                          style={{ color: 'red' }}
                        >
                          {
                            validationMessage.fileError || validationMessage.file_path
                          }
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="footer-buttons">
                    {data?.approved === 'draft' && (
                      <Button
                        style={{ marginTop: '10px', marginRight: '12px' }}
                        className="secondary-btn"
                        onClick={() => handleDraft()}
                      >
                        Save as draft
                      </Button>
                    )}

                    <Button
                      className="blue-btn"
                      style={{
                        marginTop: '10px',
                      }}
                      // disabled={submitLoading || !!isFieldsValid?.length}
                      variant="contained"
                      type="submit"
                      startIcon={submitLoading ? (
                        <CircularProgress
                          size={15}
                          color="inherit"
                        />
                      ) : (
                        <img
                          src="/submit1.png"
                          alt="submit button"
                          width="15px"
                          height="15px"
                        />
                      )}
                    >
                      Submit
                    </Button>
                  </div>
                </form>
              </Typography>
            </div>
          </div>
          {showBuArtifact && (
            <Dialog
              open={showBuArtifact}
              onClose={() => setshowBuArtifact(false)}
              PaperProps={{
                style: { width: '80%', maxWidth: 'none' },
              }}
            >
              <ImagePreview type={fileType} url={fileUrl} setshowBuArtifact={setshowBuArtifact} />
            </Dialog>
          )}
          <Dialog className="btn_style_prt" open={showConfirmationDialog}>
            <DialogTitle>Are you sure?</DialogTitle>
            <DialogContent>
              <DialogContentText>
                You have unsaved changes. Are you sure you want to cancel?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancelConfirmation}>No</Button>
              <Button onClick={handleConfirmCancelConfirmation}>Yes</Button>
            </DialogActions>
          </Dialog>
          <MessageAlert
            open={showAlertMessage}
            onClose={handleCloseAlert}
            header={alertHeader || 'Alert'}
            message={alertMessage || 'An error occurred.'}
            alertType={alertType}
          />
        </AddProductlevelFormWrapper>
      </div>
    </>
  );
};
export default EditArtifactForm;
