/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Typography from '../../components/Typography/Typography';
import { ArtifactWrapper } from './BUArtifact.styled';
import { ListTable } from '../../components';
import { CommHandler } from '../../utils';
import isEmpty from '../../utils/utils';
import { config } from '../../config';

export default function Artifact() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const loggedUser = useSelector((state: any) => state?.user?.employee);

  const handleActionClick = (action: any, item: any) => {
    const userId = item?.user_id;
    if (action.label === 'View') {
      navigate(`/user-artifact-detail/${userId}`);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await CommHandler.request({
          url: `${config.apiBaseUrl}/artifact/report`,
          method: 'GET',
        });
        if (response?.data?.length) {
          const filteredData = response?.data.filter((item) => item?.email !== loggedUser?.email);
          setData(filteredData);
        }
      } catch (error) {
        console.error('Error fetching rank:', error);
      }
    };

    if (isEmpty(data)) {
      fetchData();
    }
  }, [data]);

  const col = [
    {
      header: 'Employee Name',
      accessor: 'fullName',
    },
    {
      header: 'Employee Email',
      accessor: 'email',
    },
    {
      header: 'Business Unit',
      accessor: 'department',
    },
  ];

  const report = [
    {
      icon: <VisibilityIcon />,
      label: 'View',
      onClick: () => { },
      color: 'primary-icon',
    },
  ];

  return (
    <ArtifactWrapper>
      <div className="artifact-btn-space">
        <div>
          <Typography variant="h1" gutterBottom>
            All Employees Artifacts
          </Typography>
        </div>
      </div>
      <ListTable
        tabValue="All"
        columns={col}
        data={data?.sort((a, b) => a?.fullName.localeCompare(b?.fullName)) || []}
        actions={report}
        onActionClick={(action, item) => {
          handleActionClick(action, item);
        }}
        isStatus={false}
        DropdownFilter="department"
      />
    </ArtifactWrapper>
  );
}
