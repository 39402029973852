import styled from 'styled-components';

const FormWrapper = styled.div`
.artifact-Level-section{
    background: #EDF2FD;
    padding: 24px 50px;
    width:100%;
}
.level-section{
    padding: 22px 45px;
}
.level-section + div:empty {
  border: none;
}
.title{
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    font-weight: 600;
}
.level{
    background: white;
    border-radius: 5px;
    border: 1px solid #848282;
    padding: 14px 16px;
    width: 275px;
    margin-top: 8px;
    font-size: 16px;
    font-weight: 700;
}

.add-product-level-artifact-container {
    display: flex;
    align-items: center;
    height: 123px;
    background: #EDF2FD;
  }
  
  .add-product-level-artifact-label {
    display: flex;
    flex-direction: column;
    margin-left: 28px;
    margin-top: 15px;
  }
  
  .add-product-level-artifact-btn {
    margin-top: 20px;
    text-align: end;
  }
  
  .add-product-level-artifact-form-row {
    display: flex;
    justify-content: space-between;
    border: 2px solid #D3D3D3;
    border-radius: 5px;
    padding: 24px 8px;
  }

  .flex-row-container {
    display: flex;
    justify-content: space-between;
  }

  .flex-column-container {
    display: flex;
    flex-direction: column;
  }
  
  .form-btn-grp {
    display: flex;
    gap : 20px;
  }
  
  .margin-top-20 {
    margin-top: 200px;
  }

  .textarea-autosize {
    width: 100%;
    border-radius: 6px;
    border: 1px solid gray;
  }

  .artifact-form-section .textarea-autosize:focus-visible {
    outline-color: #0989e3;  
  }

  .addArtifact-form-btn {
    color: #ffffff !important;
  }
`;

export { FormWrapper };
