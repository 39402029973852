import * as React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { IInputProps } from './types';

const FormInput = (props: IInputProps) => {
  const {
    inputId = '',
    inputBoxType = 'outlined',
    inputFieldHeading = '',
    type = 'input',
    label = '',
    placeholder = '',
    defaultValue = '',
    required = false,
    disabled = false,
    InputIsReadOnly = false,
    InputLabelShrink = true,
    helperText = '',
    error = false,
    multiline = false,
    rows = 4,
    maxRows = 4,
    inputAdornmentPosition = 'start',
    inputAdornmentText = '',
    showEyeButton = false,
    onValueChange,
    width,
    onBlur,
    maxLength,
  } = props;

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const InputAdornmentPasswordVisibility = React.useCallback(() => (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={handleClickShowPassword}
        onMouseDown={handleMouseDownPassword}
        edge="end"
      >
        {showPassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  ), [showPassword]);

  return (
    <>
      {inputFieldHeading
      && <p>{inputFieldHeading}</p>}
      <TextField
        sx={{ width }}
        required={required}
        id={inputId}
        label={label}
        placeholder={placeholder}
        variant={inputBoxType}
        disabled={disabled}
        value={defaultValue || ''}
        helperText={helperText}
        type={type}
        error={error}
        multiline={multiline}
        rows={rows}
        maxRows={maxRows}
        inputProps={{ maxLength }}
        InputProps={{
          readOnly: InputIsReadOnly,
          startAdornment: <InputAdornment position={inputAdornmentPosition}>{inputAdornmentText}</InputAdornment>,
          endAdornment: showEyeButton ? <InputAdornmentPasswordVisibility /> : '',
        }}
        InputLabelProps={{
          shrink: InputLabelShrink,
        }}
        onChange={onValueChange}
        onBlur={onBlur}
      />
    </>
  );
};

export default FormInput;
