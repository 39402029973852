/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-constructed-context-values */
import React, {
  createContext,
  useContext,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';
// import { useLocation } from 'react-router-dom';
type ArtifactsContextType = {
  artifactName: string;
  setArtifactName: Dispatch<SetStateAction<string>>;
  selectedCatId: string;
  setSelectedCatId: Dispatch<SetStateAction<string>>;
};
const ArtifactsContext = createContext<ArtifactsContextType | undefined>(
  undefined,
);
type ArtifactsContextProviderProps = {
  children: ReactNode;
};
const ArtifactsContextProvider: React.FC<ArtifactsContextProviderProps> = ({
  children,
}) => {
  const [artifactName, setArtifactName] = React.useState<string>('');
  const [selectedCatId, setSelectedCatId] = React.useState<string>('');
  const value: ArtifactsContextType = {
    artifactName,
    setArtifactName,
    selectedCatId,
    setSelectedCatId,
  };
  return (
    <ArtifactsContext.Provider value={value}>
      {children}
    </ArtifactsContext.Provider>
  );
};
const useArtifactsContext = () => {
  const context = useContext(ArtifactsContext);
  if (!context) {
    throw new Error(
      'useArtifactsContext must be used within an ArtifactsContextProvider',
    );
  }
  return context;
};
export { ArtifactsContextProvider, useArtifactsContext };
