import React from 'react';
import CircularProgressWithLabel from './CircularProgressWithLabel';
import LinearProgressWithLabel from './LinearProgressWithLabel';
import { IProgressBarsProps } from './types';
import { limitValueInRange } from '../../utils/utils';

const ProgressBars = (props: IProgressBarsProps) => {
  const {
    isLinear = false,
    styleObject,
    progress = 0,
  } = props;

  const progressValue = limitValueInRange(progress);

  return (
    isLinear
      ? <LinearProgressWithLabel styleObject={styleObject} value={progressValue} />
      : <CircularProgressWithLabel styleObject={styleObject} value={progressValue} />
  );
};

ProgressBars.defaultProps = {
  progress: 30,
  isLinear: true,
};

export default ProgressBars;
