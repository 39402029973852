import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { IDropdown } from './types';

const Dropdown = (props: IDropdown) => {
  const {
    options, inputLabel, width = 200, onChange, value, onBlur, disabled = false,
  } = props;
  // Ensure value is defined or provide a default value
  // const [age, setAge] = React.useState(defaultVal);
  const [isHovered, setIsHovered] = React.useState(false);
  const selectRef = React.useRef<HTMLDivElement>(null);
  // const handleChange = (event: SelectChangeEvent) => {
  //   setAge(event.target.value as string);
  // };
  // const handleHover = () => {
  //   setIsHovered(true);
  // };
  // const handleMouseLeave = (event: React.MouseEvent<HTMLDivElement>) => {
  //   // Check if the mouse is leaving the select element
  //   if (event.relatedTarget instanceof HTMLElement && selectRef.current && !selectRef.current.contains(event.relatedTarget)) {
  //     setIsHovered(false);
  //   }
  // };

  return (
    <Box>
      <FormControl sx={{ width }} disabled={disabled}>
        <InputLabel id="demo-simple-select-label">{inputLabel}</InputLabel>
        <Select
          ref={selectRef}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          onChange={onChange}
          open={isHovered}
          onClose={() => setIsHovered(false)}
          onOpen={() => setIsHovered(true)}
          onBlur={onBlur}
          sx={{ width: '100%' }}
        >
          {
            options && options.length
              ? options.map((option) => (
                <MenuItem key={option.id} value={option.optionValue}>
                  {option.label}
                </MenuItem>
              ))
              : <div className="noDataFound">No data found</div>
          }
        </Select>
      </FormControl>
    </Box>
  );
};

Dropdown.defaultProps = {
  options: [
    { id: 1, label: 'Twenty', optionValue: 20 },
    { id: 3, label: 'Thirty', optionValue: 30 },
  ],
  inputLabel: 'Age',
} as Partial<IDropdown>;

export default Dropdown;
